import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AchievementsCarousel from "../home/AchievementsCarousel";
import CommonCarousel from "../home/CommonCarousel";

import {
  getAchievementsAction,
  getPickWhereLeftAction,
  readUserFavAction,
} from "../../store/Home/HomeAction";
import { testimonialReadAction } from "../../store/Testimonial/TestimonialAction";

const Profile = ({ setEditProfile, profileValues }) => {
  const dispatch = useDispatch();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const { isMobile } = useSelector((state) => state.common);

  const [videoTab, setVideoTab] = useState(0);
  const [testimonialTab, setTestimonialTab] = useState(0);
  const [curriculumTab, setCurriculumTab] = useState(0);
  const [watchedVideos, setWatchedVideos] = useState([]);
  const [watchedCurriculums, setWatchedCurriculums] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [favVideos, setFavVideos] = useState([]);
  const [favCurriculums, setFavCurriculums] = useState([]);
  const [favTestimonials, setFavTestimonials] = useState([]);
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    dispatch(
      getPickWhereLeftAction({
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data[0]?.pickuplefts;

            const watchedVideosList = [];
            const watchedCurriculumsList = [];

            data?.map((item) => {
              if (item?.videoDetails?.length > 0) {
                const currentData = item?.videoDetails[0];
                watchedVideosList.push({
                  _id: currentData?._id,
                  percentage: item?.percentagewatched,
                  image: currentData?.tumbnailurls?.filter((i) => i?.type_of_url === "1")[0]
                    ?.url_link,
                  title: currentData?.video_title,
                  subtitle: currentData?.video_title,
                  type: "video",
                });
              }
              if (item?.curriculumDetails?.length > 0) {
                const currentData = item?.curriculumDetails[0];
                watchedCurriculumsList.push({
                  _id: currentData?._id,
                  percentage: item?.percentagewatched,
                  image: currentData?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                  title: currentData?.curriculum_name,
                  subtitle: currentData?.curriculum_description,
                  type: "curriculum",
                });
              }
            });

            setWatchedVideos(watchedVideosList);
            setWatchedCurriculums(watchedCurriculumsList);
          }
        },
      })
    );

    dispatch(
      readUserFavAction({
        callback: (res) => {
          const data = res?.data[0]?.favourites;

          const favVideosList = [];
          const favCategoriesList = [];
          const favCurriculumsList = [];
          const favTestimoniesList = [];

          data?.map((item) => {
            if (item?.videoDetails?.length > 0) {
              const currentData = item?.videoDetails[0];
              favVideosList.push({
                _id: currentData?._id,
                percentage: item?.percentagewatched,
                image: currentData?.tumbnailurls?.filter((i) => i?.type_of_url === "1")[0]
                  ?.url_link,
                title: currentData?.video_title,
                subtitle: currentData?.video_title,
                type: "video",
              });
            }
            if (item?.curriculumDetails?.length > 0) {
              const currentData = item?.curriculumDetails[0];
              favCurriculumsList.push({
                _id: currentData?._id,
                percentage: item?.percentagewatched,
                image: currentData?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                title: currentData?.curriculum_name,
                subtitle: currentData?.curriculum_name,
                type: "curriculum",
              });
            }
            if (item?.testimonyDetails?.length > 0) {
              const currentData = item?.testimonyDetails[0];
              favTestimoniesList.push({
                _id: currentData?._id,
                image: currentData?.testomonial_rec_tumbnailUrl,
                title: currentData?.testimonial_name,
                subtitle: currentData?.testimonial_description,
                type: "testimonial",
              });
            }
            if (item?.categoryDetails?.length > 0) {
              const currentData = item?.categoryDetails[0];
              favCategoriesList.push({
                _id: currentData?._id,
                image: currentData?.icon_images?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                title: currentData?.category_name,
                subtitle: currentData?.category_name,
                type: "category",
              });
            }
            setFavVideos(favVideosList);
            setFavCurriculums(favCurriculumsList);
            setFavTestimonials(favTestimoniesList);
            console.log("favTestimoniesList", favTestimoniesList);
          });
        },
      })
    );

    dispatch(
      getAchievementsAction({
        callback: (res) => {
          const data = res?.data;
          data.map((item) => {
            setAchievements((prev) => [
              ...prev,
              {
                _id: item?._id,
                title: item?.curriculum_name,
                image: item?.bg_Images?.filter((item) => item.type_of_url === "1")[0]?.url_link,
              },
            ]);
          });
        },
      })
    );

    dispatch(
      testimonialReadAction({
        callback: (res) => {
          setTestimonials(res?.data);
        },
      })
    );
  }, []);

  const restructureData = (data) => {
    return data?.map((item) => ({
      _id: item._id,
      image: item?.testomonial_rec_tumbnailUrl,
      title: item?.testimonial_name,
      subtitle: item?.subtitle,
    }));
  };

  console.log("currentLanguageTitles", currentLanguageTitles);

  return (
    <div className="w-full flex flex-col gap-5 pl-4 pr-4 md:pr-10 overflow-auto pb-8">
      <p className="text-white text-4xl font-semibold mb-4 flex justify-between items-center">
        {currentLanguageTitles?.[0]?.my_profile?.my_profile_lbl ?? "My Profile"}
      </p>
      <div className="w-full rounded-2xl bg-blackishV2 ">
        <div className="h-[18vh] bg-gradient-to-t from-yellowish/50 to-yellowish/90 rounded-t-2xl relative">
          <div
            style={{ backgroundImage: `url(${profileValues?.profilePicUrl})` }}
            className="absolute w-40 md:w-28 h-40 md:h-28 bg-black rounded-full left-[50%] md:left-[2%] -translate-x-1/2 md:-translate-x-0 -bottom-[20px] z-10 bg-cover bg-top"
          ></div>
        </div>
        <div className="p-6 flex justify-between flex-col md:flex-row">
          <div className="flex flex-col">
            <p className="text-white text-xl md:text-2xl font-semibold flex items-center gap-3">
              {profileValues?.firstName} {profileValues?.lastName}{" "}
              <div
                className={` ${
                  profileValues?.active === 1
                    ? "bg-[#23E33E] text-[#23E33E]"
                    : "bg-[#FF544D] text-[#FF544D] "
                }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10 text-sm h-fit rounded-lg`}
              >
                {profileValues?.active === 1 ? "Active" : "In-Active"}
              </div>
            </p>
            {profileValues?.twgId && (
              <p className="text-white  text-sm md:text-lg font-light mb-2">
                {"User ID: " + profileValues?.twgId}
              </p>
            )}
            <p className="text-white opacity-60 text-sm">{profileValues?.email}</p>
            <p className="text-white opacity-60 font-thin">{profileValues?.country}</p>
          </div>
          <button
            className="px-8 py-3 mt-4 md:mt-0 h-fit border-2 border-yellowish text-yellowish font-medium bg-blackishV2/80 rounded-xl text-sm shadow-lg"
            onClick={() => setEditProfile((prev) => !prev)}
          >
            {currentLanguageTitles?.[0]?.my_profile?.edit_profile_lbl ?? "Edit Profile"}
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 w-full">
        <div className="w-full flex flex-col gap-5">
          {profileValues?.aboutMe && (
            <div className="p-5 w-full rounded-2xl bg-blackishV2 overflow-hidden">
              <p className="text-white text-xl font-semibold mb-4">
                {currentLanguageTitles?.[0]?.my_profile?.about_me_lbl ?? "About"}
              </p>
              <p className="text-white opacity-60 font-thin">{profileValues?.aboutMe}</p>
            </div>
          )}
          <div className="w-full rounded-2xl bg-blackishV2">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setVideoTab(0)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  videoTab === 0 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.recently_watched_videos_lbl ??
                  "Recently Watched Videos"}
              </button>
              <button
                onClick={() => setVideoTab(1)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  videoTab === 1 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.favourite_videos_lbl ?? "Favourite Videos"}
              </button>
            </div>
            <div className="p-4">
              {videoTab === 0 ? (
                <>
                  <CommonCarousel
                    data={watchedVideos}
                    perViewImages={isMobile ? 1 : 4}
                    imageType={"landscape"}
                    showTitle={false}
                    seeAll={false}
                    showProgress={true}
                    dynamiclinkTo={true}
                  />
                  {watchedVideos.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Videos Available"}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <CommonCarousel
                    data={favVideos}
                    perViewImages={isMobile ? 1 : 4}
                    imageType={"landscape"}
                    showTitle={false}
                    seeAll={false}
                    showProgress={false}
                    dynamiclinkTo={true}
                    showItemTitle={true}
                  />
                  {favVideos.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Videos Available"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-full rounded-2xl bg-blackishV2">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setTestimonialTab(0)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  testimonialTab === 0 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.my_testimonials_lbl ?? "My Testimonials"}
              </button>
              <button
                onClick={() => setTestimonialTab(1)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  testimonialTab === 1 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.favourite_testimonials_lbl ??
                  "Favourite Testimonials"}
              </button>
            </div>
            <div className="p-4">
              {testimonialTab === 0 ? (
                <>
                  <CommonCarousel
                    showTitle={false}
                    data={restructureData(testimonials)}
                    seeAll={false}
                    dataTitle={false}
                    perViewImages={isMobile ? 1 : 4}
                    type="testimonial"
                    linkTo="testimonial"
                    showItemTitle={true}
                  />
                  {testimonials.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Testimonies Available"}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <CommonCarousel
                    showTitle={false}
                    data={favTestimonials}
                    seeAll={false}
                    dataTitle={false}
                    perViewImages={isMobile ? 1 : 4}
                    dynamiclinkTo={true}
                    showItemTitle={true}
                  />
                  {favTestimonials.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Testimonies Available"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-full rounded-2xl bg-blackishV2">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setCurriculumTab(0)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  curriculumTab === 0 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.curriculum_lbl ?? "In Progress"}
              </button>
              <button
                onClick={() => setCurriculumTab(1)}
                className={`p-5 px-7 text-white font-semibold text-sm md:text-base ${
                  curriculumTab === 1 ? "border-b-[3px] border-yellowish" : "text-opacity-50"
                } `}
              >
                {currentLanguageTitles?.[0]?.my_profile?.favourite_curriculum_lbl ??
                  "Favourite Curriculums"}
              </button>
            </div>
            <div className="p-4">
              {curriculumTab === 0 ? (
                <>
                  <CommonCarousel
                    data={watchedCurriculums}
                    perViewImages={isMobile ? 1 : 4}
                    imageType={"landscape"}
                    showTitle={false}
                    seeAll={false}
                    dynamiclinkTo={true}
                    showItemTitle={true}
                  />
                  {watchedCurriculums.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Curriculums Available"}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <CommonCarousel
                    data={favCurriculums}
                    perViewImages={isMobile ? 1 : 4}
                    imageType={"landscape"}
                    showTitle={false}
                    seeAll={false}
                    dynamiclinkTo={true}
                    showItemTitle={true}
                  />
                  {favCurriculums.length === 0 && (
                    <p className="text-white text-opacity-70 w-dull text-center pb-12">
                      {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                        "No Curriculums Available"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <button
                onClick={() => setDownloadTab(0)}
                className={`p-5 px-7 text-white font-semibold  ${
                  downloadTab === 0
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Downloaded Videos
              </button>
              <button
                onClick={() => setDownloadTab(1)}
                className={`p-5 px-7 text-white font-semibold  ${
                  downloadTab === 1
                    ? "border-b-[3px] border-yellowish"
                    : "text-opacity-50"
                } `}
              >
                Favourite Videos
              </button>
            </div>
            <div className="h-[25vh]"></div>
          </div> */}
          <div className="w-full rounded-2xl bg-blackishV2 overflow-hidden">
            <div className="flex border-b-[1px] border-white border-opacity-20">
              <div className={`p-5 px-7 text-white font-semibold text-sm md:text-base`}>
                {currentLanguageTitles?.[0]?.my_profile?.certificates_lbl ??
                  "Achievements Certificate"}
              </div>
            </div>
            <div className="p-4">
              <AchievementsCarousel
                data={achievements}
                perViewImages={isMobile ? 1 : 4}
                imageType={"landscape"}
                showTitle={false}
                seeAll={false}
                user={profileValues}
              />
            </div>
            {achievements.length === 0 && (
              <p className="text-white text-opacity-70 w-dull text-center pb-12">
                {currentLanguageTitles?.[0]?.my_profile?.no_data_found_lbl ??
                  "No Certificates Available"}
              </p>
            )}
          </div>
        </div>
        {/* <div className="w-full md:w-[40%] flex flex-col gap-5">
          <div className="p-5 px-6 w-full rounded-2xl bg-blackishV2 overflow-hidden flex gap-1 flex-col">
            <p className="text-white text-xl font-semibold mb-4">
              Recent Activity
            </p>
            {arr.slice(0, moreActivities).map((item) => (
              <div className="flex gap-3 bg-blackish rounded-xl p-2">
                <div className="h-14 w-14 bg-black rounded-full"></div>
                <div className="flex flex-col text-base">
                  <p className="text-white font-semibold">Gods Prayer</p>
                  <p className="text-white opacity-70 font-thin">
                    Today with God
                  </p>
                </div>
              </div>
            ))}

            {arr?.length > 5 && (
              <div className="flex justify-end mt-4">
                <button
                  className="text-white opacity-50 text-sm"
                  onClick={() =>
                    setMoreActivities(
                      moreActivities === arr?.length ? 5 : arr?.length
                    )
                  }
                >
                  {moreActivities === arr?.length ? "See less" : "See all"}
                </button>
              </div>
            )}
          </div>
          <div className="p-5 px-6 w-full rounded-2xl bg-blackishV2 overflow-hidden flex gap-1 flex-col">
            <p className="text-white text-xl font-semibold mb-4">
              Recent Activity
            </p>
            {arr2?.slice(0, moreGivings).map((item) => (
              <div className="flex justify-between bg-blackish rounded-xl p-5 py-6">
                <p className="text-white font-semibold">Gods Prayer</p>
                <p className="text-white opacity-70 font-thin">
                  Today with God
                </p>
              </div>
            ))}

            {arr2?.length > 5 && (
              <div className="flex justify-end mt-4">
                <button
                  className="text-white opacity-50 text-sm"
                  onClick={() =>
                    setMoreGivings(
                      moreGivings === arr2?.length ? 5 : arr2?.length
                    )
                  }
                >
                  {moreGivings === arr2?.length ? "See less" : "See all"}
                </button>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
