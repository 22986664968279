import React from 'react'
import TextInputField from '../../components/common/TextInputField'


const OtpForm = ({ setCurrentForm }) => {
    const handleChange = () => {
        console.log("Changed")
    }
    return (
        <form onSubmit={''} className='py-10 px-12 bg-black bg-opacity-50 rounded-3xl w-[400px]'>
            <p className='mb-10 text-4xl font-bold text-white'>Enter Otp</p>
            <div className='flex flex-col gap-6'>
                <TextInputField
                    htmlFor={"emailOrPhone"}
                    name={"emailOrPhone"}
                    labelText={"Enter Otp"}
                    placeholder={"Email or Phone"}
                    handleInputChange={handleChange}
                    validateFunction={() => true}
                />
            </div>
            <div className='mt-4 flex flex-col gap-3'>
                <button type='submit' className='p-3 rounded-xl text-[15px] font-semibold bg-yellow-400'>
                    Confirm
                </button>
                <button
                    className='p-3 rounded-xl text-[15px] font-semibold border border-yellow-400 text-yellow-400'
                    onClick={() => { setCurrentForm("login") }}
                >
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default OtpForm