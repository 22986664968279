import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest } from "redux-saga/effects";
import * as api from "./LoginAndRegisterAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* postSignUpRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.postSignUp, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* postLoginActionSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.postLogin, payload);
        action.payload.callback(response);
    }
    catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* postEmailVerficationSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.postEmailVerfication, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* postResendVerificationSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.postResendVerification, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}


export default function* LoginAndRegisterWatcherSaga() {
    yield takeLatest(
        actionTypes.SIGN_UP_REQUEST,
        postSignUpRequestSaga
    );
    yield takeLatest(
        actionTypes.LOGIN_REQUEST,
        postLoginActionSaga,
    );
    yield takeLatest(
        actionTypes.EMAIL_VERIFICATION_REQUEST,
        postEmailVerficationSaga
    );
    yield takeLatest(
        actionTypes.RESEND_VERIFICATION_REQUEST,
        postResendVerificationSaga
    );
}
