import Axios from "../../utils/axios";

export const readCurriculum = (payload) => {
    const URL = "/curriculum/read";
    return Axios.post(URL, payload).then((res) => res.data);
};

export const readHomeCurriculum = (payload) => {
    const URL = "/curriculum/readhome";
    return Axios.post(URL, payload).then((res) => res.data);
};