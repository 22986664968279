import React from "react";
import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import Group from "../../assets/icons/Group.svg";
import Group1 from "../../assets/icons/Group-1.svg";
import Group2 from "../../assets/icons/Group-2.svg";
import FooterBar from "../common/FooterBar";
import { useNavigate } from "react-router-dom";
import HomeNeedPrayer from "../home/NeedPrayer/HomeNeedPrayer";

const GivingTwo = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full md:flex-row flex-col overflow-hidden">
      <Navbar active={"Givings"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          title1={"Givings"}
          active={"Givings"}
          back={true}
          backLink={"/givings"}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
          <div className="w-full p-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="flex flex-col justify-center w-full h-fit lg:h-[70vh] bg-[#EFBC51] rounded-xl p-6">
              <div className="h-[40%] lg:h-[60%] flex justify-center items-end mb-6 lg:mb-0">
                <img className="h-[20vh]" src={Group} alt="group" />
              </div>
              <div className="h-[40%] flex justify-center items-end">
                <button className="justify-self-end w-full p-4 border border-black rounded-lg font-medium">
                  Give Now
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-center w-full h-fit lg:h-[70vh] bg-[#EFBC51] rounded-xl p-6">
              <div className="h-[40%] lg:h-[60%] flex justify-center items-end mb-6 lg:mb-0">
                <img className="h-[20vh]" src={Group1} />
              </div>
              <div className="h-[40%] flex justify-center items-end">
                <button className="justify-self-end w-full p-4 border border-black rounded-lg font-medium">
                  Set up Recurring
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-center w-full h-fit lg:h-[70vh] bg-[#EFBC51] rounded-xl p-6">
              <div className="h-[40%] lg:h-[60%] flex justify-center items-end mb-6 lg:mb-0">
                <img className="h-[20vh]" src={Group2} />
              </div>
              <div className="h-[40%] flex justify-center items-end">
                <button
                  onClick={() => navigate("/givings/past-list")}
                  className="justify-self-end w-full p-4 border border-black rounded-lg font-medium"
                >
                  Your past givings
                </button>
              </div>
            </div>
          </div>
          <div className="px-5 lg:px-0">
            <HomeNeedPrayer />
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default GivingTwo;
