import { useSelector } from "react-redux";

const LoadingSlides = ({ className = null }) => {
  const { isMobile } = useSelector((state) => state.common);
  return (
    <div className={`mt-3 w-full ${className ?? ""}`}>
      <div className="flex justify-between items-center px-1">
        <div className="w-[10%] h-5 bg-zinc-600 rounded animate-pulse"></div>
        <div className="w-[5%] h-5 bg-zinc-600 rounded animate-pulse"></div>
      </div>
      <div className={`grid grid-cols-${isMobile ? "2" : "4"} gap-4 w-full mt-4`}>
        <div className="aspect-video rounded-lg bg-zinc-600 animate-pulse"></div>
        <div className="aspect-video rounded-lg bg-zinc-600 animate-pulse"></div>
        {!isMobile && (
          <>
            <div className="aspect-video rounded-lg bg-zinc-600 animate-pulse"></div>
            <div className="aspect-video rounded-lg bg-zinc-600 animate-pulse"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingSlides;
