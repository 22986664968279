import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import Play from "../../assets/icons/play.svg";
import FooterBar from "../common/FooterBar";
import ReactModal from "react-modal";
import { customStyles } from "../../constants/NavbarConstants";
import StoryPreview from "./StoryPreview";
import { useDispatch } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import { useLocation, useParams } from "react-router-dom";
import StoriesReact from "./StoriesReact";
import { Circle } from "rc-progress";
import { SiGooglemessages } from "react-icons/si";
import Stories from "stories-react";
import ReactStories from "./ReactStories";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";

const LessonDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { index } = useParams();
  const { isAuthenticated } = useAuth0();
  const fromLink = location?.state?.fromLink;
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("Curriculum Deatils");
  const [currentSlides, setCurrentSlides] = useState([]);
  const [openCreateStoryModal, setOpenCreateStoryModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [curriculumData, setCurriculumData] = useState();
  const [lessonData, setLessonData] = useState();

  console.log(lessonData, "lessonData");

  const handleTab1 = () => {
    setActiveTab("Curriculum Deatils");
  };

  const initialData = [
    {
      type: "0",
      url: "https://twg-assets-dev.s3.us-east-1.amazonaws.com/1701348385094.jpeg",
      duration: 10000,
      startTime: 0,
    },
    {
      type: "1",
      url: "https://twg-assets-dev.s3.amazonaws.com/Matthew+3+%7C+Jesus+is+Baptized+by+John+%7C+The+Bible.mp4",
      duration: 50000,
      startTime: 0,
    },
    {
      type: "2",
      url: "",
      duration: 20000,
      startTime: 0,
      color: "red",
      text: "Hello There",
    },
  ];

  const [storyData, setStoryData] = useState(initialData);

  const handleStory = (slides) => {
    if (currentSlides.length === 0) {
      slides?.map((slide) => {
        const urlType = slide?.slide_type[0]?.type_of_slide;

        setCurrentSlides((prev) => [
          ...prev,
          {
            type: urlType,
            url: slide?.slide_type[0]?.bg_asset_url[1]?.url_link,
            duration: urlType === "0" ? 5000 : urlType === "1" ? 8000 : 5000,
            startTime: 0,
            text: "After enduring four hundred years of prophetic silence, God’s people must have wondered whether or not He had deserted them.  After centuries of regular communication from God, the people found themselves without a genuine prophet or spokesman for God. However, the ministries of John and Jesus reminded God’s people that He had not forgotten them. God’s silence during that period was merely a precursor to pulling the linchpin of His redemptive",
            color: slide?.slide_type[0]?.slide_bg_color,
          },
        ]);
      });
    }
  };

  console.log(currentSlides, "currentSlides");

  const closeModal = () => {
    setOpenCreateStoryModal((preState) => !preState);
  };

  useEffect(() => {
    dispatch(
      curriculumReadAction({
        apiPayloadRequest: { _id: id },
        callback: (res) => {
          if (res?.type === 1) {
            const curriculum = res?.data[0];
            const lesson = curriculum?.lessons[index];
            setCurriculumData(curriculum);
            setLessonData(lesson);
          }
        },
      })
    );
  }, []);

  // useEffect(()=> {
  //   if (!isAuthenticated) {
  //     dispatch(setLoginHardBlocker(true));
  //     return;
  //   } else{
  //     dispatch(setLoginHardBlocker(false));
  //     return;
  //   }
  // }, [isAuthenticated]);

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Curriculum"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          back={true}
          backLink={fromLink ? fromLink : "/curriculum"}
          title1={activeTab}
          active={activeTab}
          handleTab1={handleTab1}
          absolute={true}
          enableGoToHome={true}
        />
        <div className="flex flex-col bg-black overflow-y-auto w-full relative">
          <div className="absolute top-0  w-full h-[20vh] bg-gradient-to-b from-black from-[20%] "></div>
          <div className="w-full ">
            <div
              className="h-[95vh] bg-no-repeat bg-cover pt-[5%]"
              style={{
                backgroundImage: `url('${lessonData?.lesson_tumbnail_urls[2]?.url_link}')`,
              }}
            >
              <div className="img-cover-gradient w-full h-full flex justify-start items-start">
                <div className="h-[82%] w-[60%] flex justify-end">
                  <div className="h-full w-[90%] text-white flex items-center">
                    <div>
                      <p className="text-4xl font-black mb-4">
                        {lessonData?.lesson_title}
                      </p>
                      <p className="text-white text-opacity-50 pb-3">
                        Featuring{" "}
                        <span className="text-yellow-500">JOBY MARTIN</span>
                      </p>
                      <p className="text-white text-opacity-50">8 Lessons</p>
                      <p className="text-white text-opacity-30 text-xs pb-3">
                        Avg Session - 17 Min
                      </p>
                      <p className="text-white text-sm text-opacity-50 pb-2">
                        {lessonData?.lesson_description}
                      </p>
                      <div className="flex gap-1">
                        <button className="text-xs text-yellow-500 font-semibold py-1 px-6 rounded-full border border-white border-opacity-30">
                          Bible Study
                        </button>
                        <button className="text-xs text-yellow-500 font-semibold py-1 px-6 rounded-full border border-white border-opacity-30">
                          God
                        </button>
                        <button className="text-xs text-yellow-500 font-semibold py-1 px-6 rounded-full border border-white border-opacity-30">
                          Miracles
                        </button>
                        <button className="text-xs text-yellow-500 font-semibold py-1 px-6 rounded-full border border-white border-opacity-30">
                          Discipleship
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[-10%] px-[5%] flex justify-center">
              <div className="w-full flex gap-4">
                <div className=" w-[10%] flex items-end justify-end py-[10%] mb-10">
                  <div className="border-l-[3px] border-dashed border-yellow-500 w-[50%] h-full"></div>
                </div>
                <div className="flex flex-col w-full gap-10">
                  {lessonData?.slides &&
                    lessonData?.slides?.map((slide, index) => (
                      <div className="relative flex w-full gap-8 pb-10 border-b border-gray-400">
                        <div
                          className="relative aspect-[16/10] w-[35%] p-4 cursor-pointer"
                          onClick={() => {
                            // setCurrentSlides([]);
                            setOpenCreateStoryModal((preState) => !preState);
                            handleStory(lessonData?.slides);
                            setCurrentIndex(index);
                          }}
                        >
                          {slide?.slide_type[0]?.type_of_slide === "0" ? (
                            <img
                              src={
                                slide?.slide_type[0]?.bg_asset_url[1]?.url_link
                              }
                              className="w-full h-full rounded-xl overflow-hidden object-cover"
                            />
                          ) : slide?.slide_type[0]?.type_of_slide === "1" ? (
                            <>
                              <img
                                src={
                                  lessonData?.lesson_tumbnail_urls[2]?.url_link
                                }
                                className="w-full h-full rounded-xl overflow-hidden object-cover"
                              />
                              <img
                                src={Play}
                                className="absolute h-[25%] top-[50%] left-[50%] right-auto translate-x-[-50%] translate-y-[-50%] cursor-pointer"
                              />
                            </>
                          ) : (
                            <div className="w-full h-full flex justify-center items-center">
                              <SiGooglemessages size={150} color="#eab308" />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col justify-center text-white w-[65%] gap-2">
                          <p className="font-bold text-4xl">Introduction</p>
                          <div className="flex justify-between items-center max-w-[70%]">
                            <p className="text-sm">{slide.slide_title}</p>
                            <p className="text-[8px] border p-[2px] px-2 rounded">
                              2 min
                            </p>
                          </div>
                          <div className="max-w-[75%]">
                            <p className="text-sm text-opacity-40 text-white">
                              {slide.slide_description.length > 300
                                ? slide.slide_description.substring(0, 300) +
                                  " ...more"
                                : slide.slide_description}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center bg-yellowish absolute aspect-square w-[6%] rounded-full top-[32%] left-[-9.2%]">
                          <Circle
                            percent={
                              (currentSlides[index]?.startTime /
                                currentSlides[index]?.duration) *
                              100
                                ? (currentSlides[index]?.startTime /
                                    currentSlides[index]?.duration) *
                                  100
                                : 0
                            }
                            strokeWidth={15}
                            strokeColor="#EFBC51"
                            trailColor="gray"
                            trailWidth={15}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={openCreateStoryModal}
        onRequestClose={() => console.log("Not Closed")}
        style={customStyles}
      >
        <div className="relative h-full w-full bg-black p-3">
          <div className="h-full w-full rounded-md overflow-hidden">
            <ReactStories
              storyData={currentSlides}
              setStoryData={setCurrentSlides}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              closeModal={closeModal}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default LessonDetails;
