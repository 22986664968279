import React, { useState } from "react";
import Certi from "../../assets/images/certificate.png";
import ReactModal from "react-modal";
import award from "../../assets/images/certificate.png";
import { MdCancel } from "react-icons/md";

const Certificate = ({ data, setShowModal }) => {
  return (
    <div
      className="w-full flex flex-col gap-2 justify-center items-center rounded-xl bg-blackish bg-opacity-60 py-[5%] cursor-pointer"
      onClick={() => setShowModal(true)}
    >
      <img src={Certi} className="w-5/12" alt={data.title + " certificate"} />
      <p className="text-lg text-white">{data.title}</p>
    </div>
  );
};

export default Certificate;
