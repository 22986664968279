import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

import Like from "../../assets/icons/NavIcons/hoverfav.svg";

const SmallCarousel = ({
  aspectRatio,
  showLikes,
  data,
  dataTitle,
  perViewImages,
  showProgress,
  title,
  seeAll,
  showTitle = false,
  type = "",
}) => {
  console.log("seeAll", seeAll);
  console.log("test data", data);
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);

  const navigate = useNavigate();

  const handleNavigate = (item, i) => {
    if (type === "video") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    }
  };

  if (showTitle) {
    return (
      <div className={"mt-3"}>
        <div className="px-3 flex justify-between items-end">
          <p className=" pt-6 text-lg font-semibold text-white">{title}</p>
          {seeAll && (
            <p
              className="px-3 text-xs text-yellow-500 cursor-pointer"
              onClick={() => navigate("/seeAll")}
            >
              See All
            </p>
          )}
        </div>

        <Carousel
          className="my-4 p-1"
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          autoPlay={false}
          infiniteLoop
          swipeable={true}
        >
          {data?.slice(0, numberOfLists).map((_, index) => (
            <div
              key={index}
              className={dataTitle ? "px-2 pb-8 flex gap-4 w-[100%]" : "px-2 flex gap-4 w-[100%]"}
            >
              {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
                <div
                  className="relative cursor-pointer"
                  onClick={() => handleNavigate(item, i)}
                  // onClick={() => navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } })}
                >
                  <img
                    key={i}
                    src={
                      item?.testomonial_rec_tumbnailUrl
                        ? item?.testomonial_rec_tumbnailUrl
                        : item.image
                    }
                    alt="slide thumbnail"
                    className={`aspect-[${aspectRatio}] rounded-xl h-[31vh] object-cover object-top`}
                  />
                  {dataTitle && (
                    <p className="absolute text-white text-opacity-50 text-sm bottom-[-30px]">
                      {item?.testimonial_name ? item?.testimonial_name : item.title}
                    </p>
                  )}
                  {showProgress && (
                    <div className="absolute text-sm bottom-0 rounded-b-lg flex flex-col justify-center items-center h-[60%] w-[100%] bg-gradient-to-b from-[#0000] to-[#000]">
                      <p className="text-sm text-white font-semibold text-center">Book of John</p>
                      <p className=" text-[12px] text-white opacity-50 text-center">
                        Completed (8/8)
                      </p>
                      <div className="w-full mt-3 px-5">
                        <div className="h-[2px] w-full bg-gray-500 rounded">
                          <div className="h-[3px] w-1/2 bg-yellow-500 rounded"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showLikes && (
                    <img className="absolute h-5 top-3 left-[42%]" src={Like} alt="likes icon" />
                  )}
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    );
  }

  return (
    <div className={"mt-3"}>
      <div className="px-3 flex justify-between items-end">
        <p className=" pt-6 text-lg font-semibold text-white">{title}</p>
        {seeAll && (
          <p
            className="px-3 text-xs text-yellow-500 cursor-pointer"
            onClick={() => navigate("/seeAll")}
          >
            See All
          </p>
        )}
      </div>

      <Carousel
        className="my-4 p-1"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data.slice(0, numberOfLists).map((_, index) => (
          <div
            key={index}
            className={dataTitle ? "px-2 pb-8 flex gap-4 w-[100%]" : "px-2 flex gap-4 w-[100%]"}
          >
            {data.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <div
                className="relative cursor-pointer"
                onClick={() => navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } })}
              >
                <img
                  key={i}
                  src={item.url}
                  alt="slide thumbnail"
                  className={`aspect-[${aspectRatio}] rounded-xl h-[31vh] `}
                />
                {dataTitle && (
                  <p className="absolute text-white text-opacity-50 text-sm bottom-[-30px]">
                    {item.title}
                  </p>
                )}
                {showProgress && (
                  <div className="absolute text-sm bottom-0 rounded-b-lg flex flex-col justify-center items-center h-[60%] w-[100%] bg-gradient-to-b from-[#0000] to-[#000]">
                    <p className="text-sm text-white font-semibold text-center">Book of John</p>
                    <p className=" text-[12px] text-white opacity-50 text-center">
                      Completed (8/8)
                    </p>
                    <div className="w-full mt-3 px-5">
                      <div className="h-[2px] w-full bg-gray-500 rounded">
                        <div className="h-[3px] w-1/2 bg-yellow-500 rounded"></div>
                      </div>
                    </div>
                  </div>
                )}
                {showLikes && (
                  <img className="absolute h-5 top-3 left-[42%]" src={Like} alt="likes icon" />
                )}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SmallCarousel;
