import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { BsPlayCircleFill } from "react-icons/bs";
import TestimonialVideoModel from "./TestimonialVideoModel";
import { useSelector } from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { useAuth0 } from "@auth0/auth0-react";

const HomeTestimony = ({
  testimonials,
  perViewImages = 4,
  title = "Testimony Videos",
  showBookmark = false,
  handleFavourite = () => {},
  autoplay = false,
  type = "testimonial",
}) => {
  const { isMobile } = useSelector((state) => state.common);
  const { isAuthenticated } = useAuth0();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const totalCount = testimonials?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);

  console.log("selectedItemIndex", selectedItemIndex);

  const handleNavigate = (data) => {
    const itemIndex = testimonials?.findIndex((d) => d?._id === data?._id);
    setSelectedItemIndex(itemIndex);
    setShowModal((prev) => !prev);
  };

  console.log("testimonials", testimonials);

  useEffect(() => {
    if (autoplay) {
      setInterval(() => {
        setActiveIndex((prev) => {
          if (!isNaN(prev) && prev === perViewImages - 1) return 0;
          else if (!isNaN(prev)) return prev + 1;
          return 0;
        });
      }, 4000);
    }
  }, []);

  return (
    <div className="p-6 flex flex-col justify-center items-center w-full">
      <p className="text-xl text-white font-semibold w-full mb-4 mt-4">{title}</p>
      <Carousel
        className="p-1 w-full"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={autoplay}
        interval={perViewImages * 1000 * 4}
        infiniteLoop
        swipeable={true}
      >
        {testimonials?.slice(0, numberOfLists)?.map((_, index) => (
          <div className="flex w-full gap-3 md:gap-[1%]" key={"home-testimony" + index}>
            {testimonials
              ?.slice(index * perViewImages, (index + 1) * perViewImages)
              .map((item, i) => (
                <div
                  key={"home-testimony-item" + i}
                  onMouseOver={() => setActiveIndex(i)}
                  style={{
                    backgroundImage: `url('${item?.image}')`,
                    width:
                      i === activeIndex
                        ? isMobile
                          ? "50%"
                          : `${Math.round((2 * 100) / (perViewImages + 1))}%`
                        : isMobile
                        ? "50%"
                        : `${Math.round((1 * 100) / (perViewImages + 1))}%`,
                  }}
                  className={`${
                    i === activeIndex ? `aspect-[16/9]` : `aspect-[9/16]`
                  } bg-zinc-700 rounded-lg overflow-hidden bg-cover bg-top bg-no-repeat transition-all duration-700`}
                >
                  {i === activeIndex ? (
                    <div className="flex flex-col justify-between items-center w-full h-full">
                      <div className="flex justify-between w-full h-[35%] p-[5%] bg-gradient-to-b from-black from-[2%] relative">
                        {type === "testimonial" && (
                          <div className="flex w-full gap-2 items-center h-[25%]">
                            <div
                              style={{
                                backgroundImage: `url('${item?.profilePic}')`,
                              }}
                              className="h-full rounded-full aspect-square bg-yellowish bg-cover bg-no-repeat bg-center"
                            ></div>
                            <p className=" text-white w-full text-start">
                              {item?.userName ? item?.userName : "No User Name"}
                            </p>
                          </div>
                        )}
                        {isAuthenticated && showBookmark && (
                          <>
                            {item?.isLikedByCurrentUser ? (
                              <FaHeart
                                className="absolute top-6 md:bottom-12 right-4 md:right-6 text-3xl text-yellowish cursor-pointer"
                                onClick={() => {
                                  if (handleFavourite) handleFavourite(item);
                                }}
                              />
                            ) : (
                              <FaRegHeart
                                className="absolute top-6 md:bottom-12 right-4 md:right-6 text-3xl text-yellowish cursor-pointer"
                                onClick={() => {
                                  if (handleFavourite) handleFavourite(item);
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <BsPlayCircleFill
                        onClick={() => handleNavigate(item)}
                        color="#EFBC51"
                        className="w-[15%] h-auto cursor-pointer"
                      />
                      <div className="flex flex-col justify-end items-start w-full h-[35%] p-[5%] bg-gradient-to-t from-black from-[2%]">
                        <p className="text-white text-sm md:text-xl font-bold md:mb-2">
                          {" "}
                          {item?.title?.length <= (isMobile ? 10 : 30)
                            ? item?.title
                            : item?.title?.substring(0, isMobile ? 10 : 30) + ".."}
                        </p>
                        <p className="text-white text-base text-opacity-60 text-start">
                          {item?.subtitle?.length <= (isMobile ? 20 : 180)
                            ? item?.subtitle
                            : item?.subtitle?.substring(0, isMobile ? 20 : 178) + ".."}
                        </p>
                      </div>
                    </div>
                  ) : type === "curriculum" ? (
                    <div className="w-full h-full flex flex-col justify-end items-center">
                      <p className="text-white text-sm md:text-xl font-bold md:mb-2 overflow-hidden max-w-full overflow-ellipsis">
                        {item?.title?.length <= (isMobile ? 10 : 35)
                          ? item?.title
                          : item?.title?.substring(0, isMobile ? 10 : 35) + ".."}
                      </p>
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        ))}
      </Carousel>
      <TestimonialVideoModel
        isOpen={showModal}
        onRequestClose={() => setShowModal((prev) => !prev)}
        allTestimonials={testimonials}
        selectedItemIndex={selectedItemIndex}
        key={selectedItemIndex}
      />
    </div>
  );
};

export default HomeTestimony;
