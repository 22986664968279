import React, { useEffect, useRef, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaCaretDown, FaCaretUp, FaPlay } from "react-icons/fa";
import Avatar from "../../assets/icons/Avatar.svg";
import ReactModal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel } from "swiper/modules";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setLoginHardBlocker, userFavAction, userUnFavAction } from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";
import { FaHeart } from "react-icons/fa6";
import { getFormattedLikes } from "../../utils/Utils";

const TestimonialVideoModel = ({ isOpen, onRequestClose, allTestimonials, selectedItemIndex }) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001, // Adjust this value as needed
    },
  };

  const dispatch = useDispatch();
  const videoRef = useRef();
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  const [testimonials, setTestimonials] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isDescriptionExpended, setIsDescriptionExpended] = useState(false);
  const { isAuthenticated } = useAuth0();

  const handleSlideChange = (swiper) => {
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
    setIsDescriptionExpended(false);
  };

  useEffect(() => {
    const data = structuredClone(allTestimonials);
    const testimonialPosition = selectedItemIndex;
    const testimonialData = data[testimonialPosition];
    data.splice(testimonialPosition, 1);
    const updatedData = [testimonialData, ...data];
    setTestimonials(updatedData);
  }, [allTestimonials, selectedItemIndex]);

  const handleBookmark = (item) => {
    if (isAuthenticated) {
      const payload = { type: "4", testimonyObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: false } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: true } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <ReactModal
      style={customStyles}
      className={
        "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <button
        className="absolute left-4 md:left-10 top-4 z-50"
        onClick={() => {
          onRequestClose();
        }}
      >
        <BsArrowLeft size={30} color="white" />
      </button>
      <div className="h-[90vh] w-full flex justify-center items-center cursor-pointer relative">
        <Swiper
          onInit={(ev) => setSwiper(ev)}
          grabCursor={true}
          direction="vertical"
          centeredSlides={true}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          keyboard={{
            enabled: true, // Enable keyboard control
            onlyInViewport: false, // Allow keyboard control outside of Swiper container
          }}
          freeMode={true}
          mousewheel={{ enabled: true, forceToAxis: true }}
          className="h-screen custom-swiper !pt-6"
          modules={[Keyboard, Mousewheel]}
        >
          {testimonials?.map((row, index) => (
            <SwiperSlide key={index} className="h-full">
              <div className="flex justify-center h-[92%] px-5 md:px-0">
                <div
                  className="h-full aspect-[9/16] bg-blackish bg-cover bg-center bg-no-repeat rounded-2xl relative cursor-pointer overflow-hidden"
                  style={{
                    backgroundImage: `url('${!isPlaying && !isVideoPlayed ? row?.image : ""}')`,
                  }}
                  onClick={() => {
                    setIsVideoPlayed(true);
                    if (isPlaying) {
                      videoRef.current.pause();
                    } else {
                      videoRef.current.play();
                    }
                    setIsPlaying(!isPlaying);
                  }}
                >
                  {activeIndex === index && (
                    <>
                      <video
                        className={`h-full w-full object-contain overflow-hidden rounded-xl ${
                          isVideoPlayed ? "block" : "hidden"
                        }`}
                        ref={videoRef}
                        loop={true}
                        src={row?.video}
                        onClick={() => videoRef.current.pause()}
                        onLoadedMetadata={(event) => {
                          console.log("event target2", event?.target);
                          if (event?.target) {
                            try {
                              event?.target
                                ?.play()
                                ?.then(() => {
                                  setIsVideoPlayed(true);
                                  if (!isPlaying) setIsPlaying(!isPlaying);
                                })
                                .catch((error) => console.log("Video Play Error", error));
                            } catch (error) {
                              console.log("Video Play Error", error);
                            }
                          }
                        }}
                      />
                      {!isPlaying && (
                        <>
                          <div
                            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[90%] z-10 opacity-20 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-5"
                            onClick={() => videoRef.current.play()}
                          >
                            <FaPlay size={20} color="white" className="translate-x-[10%]" />
                          </div>
                        </>
                      )}
                      <div
                        className={`${
                          showAnimation ? "btn-slide3" : ""
                        } absolute bottom-0 w-full py-3 bg-gradient-to-t from-black to-black/0 px-5`}
                      >
                        <div className="flex items-center gap-3 text-white font-medium">
                          <img
                            src={row?.profilePic ?? Avatar}
                            alt="user avatar"
                            className="!h-[30px] !w-[30px] object-cover object-top rounded-full overflow-hidden"
                          />
                          <h4>{row?.userName}</h4>
                        </div>
                        <p className="text-white text-sm font-bold drop-shadow-xl mb-1">
                          {row?.title}
                        </p>
                        <p className="text-white text-xs drop-shadow-xl text-opacity-80">
                          {isDescriptionExpended ? (
                            row?.subtitle
                          ) : (
                            <>
                              {row?.subtitle?.substring(0, 208) + " .."}{" "}
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsDescriptionExpended(true);
                                }}
                                className="text-yellowish"
                              >
                                more
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                  {isAuthenticated && (
                    <div className="absolute -right-10 translate-x-1/2 bottom-6 flex flex-col items-center justify-center gap-1">
                      <button
                        className={`flex items-center justify-center p-4 ${
                          row?.isLikedByCurrentUser ? "bg-white" : "bg-white/10"
                        } rounded-full`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBookmark(row);
                        }}
                      >
                        <FaHeart
                          className={`text-xl ${
                            row?.isLikedByCurrentUser ? "text-black" : "text-white"
                          }`}
                        />
                      </button>
                      <span className="text-white text-sm">
                        {row?.totalikes ? getFormattedLikes(row?.totalikes) : "Favourite"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="hidden md:block absolute top-10 right-10 z-10 opacity-60 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-2"
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <FaCaretUp size={50} color="white" className="-translate-y-[10%]" />
        </div>
        <div
          className="hidden md:block absolute bottom-10 right-10 z-10 opacity-60 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-2"
          onClick={() => {
            swiper.slideNext();
          }}
        >
          <FaCaretDown size={50} color="white" />
        </div>
      </div>
    </ReactModal>
  );
};

export default TestimonialVideoModel;
