import React, { useState } from 'react'
import styled from 'styled-components';

const SliderComp = ({
    value,
    handleChange,
    min = 0,
    max = 100,
    step = 1,
    small = false,
    vertical = false
}) => {

    // console.log(min, max, value, "Range")

    return (
        <Container className={`${vertical ? 'rotate-270 p-4 bg-black' : ''}`}>
            <input
                className={`cursor-pointer accent-yellow-500 w-full ${small ? "h-[1px]" : 'h-[4px]'} `}
                type='range'
                value={value}
                onChange={handleChange}
                max={max}
                min={min}
                step={step}
            />
        </Container>
    )
}

export default SliderComp;

const Container = styled.div`
    display: grid;
    place-items: center;
`

// const Slider = styled.input`
//     -webkit-appearance: none;
//     -moz-appearance: none; /* Firefox */
//     -ms-appearance: none; /* Microsoft Edge */
//     width: 100%;
//     height: 4px;
//     background-color: #e6b000;
//     opacity: 1;
//     -webkit-transition: 0.2s;
//     transition: opacity 0.2s;
//     border-radius: 2px;
//     border: none;

//     ::-webkit-slider-thumb {
//         -webkit-appearance: none;
//         appearance: none;
//         width: 4px;
//         height: 4px;
//         background-color: red;
//     }
// `
