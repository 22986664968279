import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import TestCarousel from "../../components/home/TestCarousel";
import PromiseCarousel from "../../components/home/PromiseCarousel";
import SmallCarousel from "../../components/home/SmallCarousel";

import AwardImg from "../../assets/images/AwardImg.png";
import FooterBar from "../../components/common/FooterBar";
import { ArrayOfImgs, ArrayOfImgs2, ArrayOfImgs4 } from "../../constants/NavbarConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import CommonCarousel from "../../components/home/CommonCarousel";
import Certificate from "../../components/curriculum/Certificate";
import HomeCurriculumCarousel from "../../components/home/HomeCurriculumCarousel";
import LoadingSlides from "../../components/common/LoadingSlides";
import {
  getAchievementsAction,
  getProfileAction,
  setLoginHardBlocker,
  userFavAction,
  userUnFavAction,
} from "../../store/Home/HomeAction";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import award from "../../assets/images/certificate.png";
import html2canvas from "html2canvas";
import certificateTemplate from "../../assets/images/certificate_template.jpg";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import { useAuth0 } from "@auth0/auth0-react";
import Toastiy from "../../components/common/Toastiy";
import HomeTestimony from "../../components/home/HomeTestimony";
import HomeCarousel from "../../components/home/HomeCarousel";

const customStyles = {
  overlay: {
    zIndex: 1000, // Adjust this value as needed
  },
  content: {
    zIndex: 1001,
    padding: 0, // Adjust this value as needed
  },
};

const Curriculum = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { languagePreferred, currentLanguageTitles, userProfileDetails } = useSelector(
    (state) => state.languageReducer.data
  );

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [popCancle, setPopCancel] = useState(true);
  const [currentItem, setCurrentItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const initialTabs = ["Learn", "Your Achievements"];
  const initialSeeAll = "See All";
  const checkToken = localStorage.getItem("token");
  const initialMoreVideos = "More Videos";
  const pdfContentRef = useRef(null);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [moreVideosbtn, setMoreVideosbtn] = useState(initialMoreVideos);
  const [currentCode, setCurrectCode] = useState("");
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [curriculumData, setCurriculumData] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  console.log("userProfileDetails", userProfileDetails);
  console.log("curriculumData3", curriculumData);
  // const generatePDF = () => {
  //   const input = pdfContentRef.current;

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "PNG", 0, 0);
  //     pdf.save("download.pdf");
  //   });
  // };

  useEffect(() => {
    dispatch(
      curriculumReadAction({
        callback: (res) => {
          if (res?.type === 1) {
            setCurriculumData(res?.data);
          }
        },
      })
    );
    const checkToken = localStorage.getItem("token");
    if (checkToken) {
      dispatch(
        getAchievementsAction({
          callback: (res) => {
            const data = res?.data;
            setAchievements(
              data.map((item) => {
                return {
                  _id: item?._id,
                  title: item?.curriculum_name,
                  image: item?.bg_Images?.filter((item) => item.type_of_url === "1")[0]?.url_link,
                };
              })
            );
          },
        })
      );
    }
  }, [languagePreferred]);

  const getCertificateTemplate = (title, name) => {
    return (
      <div className="w-[792px] relative">
        <img src={certificateTemplate} alt="certificate template" className="w-full" />
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style={{ wordSpacing: "4px" }}
        >
          <p className="w-full text-center">{title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style={{ wordSpacing: "5px" }}
        >
          <p className="w-full text-center">{name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>
    );
  };

  const generatePDFDocument = (title = "") => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(
      ReactDOMServer.renderToStaticMarkup(
        getCertificateTemplate(
          title,
          userProfileDetails?.firstName ??
            (userProfileDetails?.firstName && userProfileDetails?.lastName)
            ? `${userProfileDetails?.firstName} ${userProfileDetails?.lastName}`
            : userProfileDetails?.twgId ?? ""
        )
      ),
      {
        callback: async function (doc) {
          doc.save("Certificate" + new Date().getTime());
        },
        margin: [0, 0, 0, 0],
        width: 792,
        windowWidth: 792,
      }
    );
  };

  const handleBookmark = (item) => {
    if (isAuthenticated) {
      const payload = { type: "2", curriculumObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setCurriculumData((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: false } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setCurriculumData((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: true } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      setPopCancel(false);
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <>
      <div className="flex flex-col md:flex-row w-full overflow-hidden">
        <Navbar active={"Curriculum"} />
        <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
          <HeaderBar
            popCancle={popCancle}
            cancelBtn={!popCancle}
            cancelBtnHandle={() => setPopCancel(true)}
            tabValues={tabValues}
            active={activeTab}
            handleTabChange={handleTabChange}
            getLanguagePreferred={(code) => {
              setCurrectCode(code);
            }}
            getCurrentLanguageTitles={(titles) => {
              if (titles?.curricullum_meta) {
                const requiredLangData = titles?.curricullum_meta;
                setTabsValues([requiredLangData.learn_lbl, requiredLangData.yourachievement_lbl]);
                setMoreVideosbtn(requiredLangData.morevideos_btn || initialMoreVideos);
                setSeeAllBtn(requiredLangData.seeall_btn);
              } else {
                setTabsValues(initialTabs);
                setSeeAllBtn(initialSeeAll);
                setMoreVideosbtn(initialMoreVideos);
              }
            }}
          />
          <div className="flex flex-col bg-black flex-1 overflow-y-auto w-full">
            {activeTab === 0 ? (
              <>
                {/* {curriculumData?.length > 0 && (
                  <TestCarousel
                    data={[
                      ...(curriculumData?.map((curriculum) => ({
                        _id: curriculum?._id,
                        title: curriculum?.curriculum_name,
                        subtitle: curriculum?.curriculum_description,
                        isLikedByCurrentUser: curriculum?.isLikedByCurrentUser ?? false,
                        image: curriculum?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link,
                      })) ?? []),
                    ]}
                    title={"Learn the word with Us"}
                    type="curriculum"
                    showBookmark={true}
                    handleBookmark={handleBookmark}
                  />
                )} */}
                {curriculumData?.length > 0 && (
                  <HomeCarousel
                    data={
                      curriculumData?.map((curriculum) => ({
                        _id: curriculum._id,
                        image: curriculum?.bg_Images?.filter((i) => i?.type_of_url === "1")?.[0]
                          ?.url_link,
                        title: curriculum?.curriculum_name,
                        subtitle: curriculum?.curriculum_description,
                        type: "curriculum",
                        video:
                          curriculum?.trailers?.find((d) => d?.type_of_url === "1")?.url_link ?? "",
                      })) || []
                    }
                    width={width}
                    allowHoverAnimation={true}
                  />
                )}

                {curriculumData?.length > 0 ? (
                  curriculumData?.map((curriculum) => (
                    <HomeCurriculumCarousel
                      allowHoverAnimation={true}
                      showProgress={true}
                      id={curriculum?._id}
                      title={curriculum?.curriculum_name}
                      description={curriculum?.curriculum_description}
                      curriculum_watch_percentage={curriculum?.curriculum_watch_percentage ?? 0}
                      bgImage={
                        curriculum?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]?.url_link
                      }
                      preview={
                        curriculum?.trailers?.filter((i) => i?.type_of_url === "1")[0]?.url_link
                      }
                      data={curriculum?.lessons?.map((item) => ({
                        _id: item?.lessonObjId,
                        title: item?.lesson_title,
                        subtitle: item?.lesson_title,
                        image:
                          item?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "1")
                            ?.url_link ?? "",
                        video:
                          item?.lesson_trailers?.find((d) => d?.type_of_url === "1")?.url_link ??
                          "",
                        type: "lesson",
                      }))}
                    />
                  ))
                ) : (
                  <div className="px-5">
                    <LoadingSlides />
                    <LoadingSlides />
                    <LoadingSlides />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="grid grid-cols-2 md:grid-cols-3 p-5 gap-6">
                  {achievements?.map((item) => (
                    <button onClick={() => setCurrentItem(item)}>
                      <Certificate
                        data={item}
                        setCurrentItem={setCurrentItem}
                        setShowModal={setShowModal}
                      />
                    </button>
                  ))}
                </div>
                {achievements?.length === 0 && (
                  <p className="text-white text-center w-full pt-10 pb-20 text-xl font-medium">
                    {currentLanguageTitles?.[0]?.curricullum_meta?.no_data_found_lbl ??
                      "No achievements found"}
                  </p>
                )}
              </>
            )}
            <FooterBar />
          </div>
        </div>
        <ReactModal
          style={customStyles}
          isOpen={showModal}
          className={"overflow-hidden"}
          onRequestClose={() => setShowModal(false)}
        >
          <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
            <button
              className="absolute top-36 md:top-5 right-5"
              onClick={() => setShowModal(false)}
            >
              <MdCancel color="#EFBC51" size={30} />
            </button>
            <div className="aspect-video rounded-2xl bg-black h-1/2 flex justify-center items-center flex-col">
              <div
                style={{ backgroundImage: `url('${award}')` }}
                className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
              ></div>
              <p className="text-xl text-yellowish">Congratulations</p>
              <p className="text-2xl text-white">
                {currentItem?.title?.length < 20
                  ? currentItem?.title
                  : currentItem?.title?.substring(0, 20) + ".."}
              </p>
            </div>
            <button
              // onClick={generatePDF}
              onClick={() => generatePDFDocument(currentItem?.title)}
              className="bg-yellowish px-6 py-4 text-xl font-semibold rounded-xl"
            >
              Download Certificate
            </button>
          </div>
          <div
            className="flex flex-col justify-start items-center "
            ref={pdfContentRef}
            style={{
              width: "595.28px",
              height: "842px",
              color: "black",
              background: "white",
            }}
          >
            <p className="font-bold text-2xl mb-4">
              Hello {userProfileDetails?.firstName} {userProfileDetails?.lastName}
            </p>
            <p>Congratulations on successfully completion of {currentItem?.title} </p>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Curriculum;
