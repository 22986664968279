import { useState } from "react";
import Message from "../../assets/icons/Message.svg";
import NeedPrayerModal from "../home/NeedPrayer/NeedPrayerModal";

const GotQuestions = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="cursor-pointer" onClick={() => setShowModal(true)}>
        <p className="px-6 text-lg font-semibold text-white">Got Questions</p>
        <div className="m-6 bg-white flex flex-col gap-6 justify-center items-center rounded-xl h-[35vh]">
          <p className="font-semibold text-lg">Ask me anything</p>
          <img src={Message} />
        </div>
      </div>
      <NeedPrayerModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default GotQuestions;
