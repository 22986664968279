import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    const language = localStorage.getItem("language_preferred");
    if (token) {
      request.headers.setAuthorization("Bearer " + token);

      if (["/uploadAsset"].includes(request?.url)) {
        request.headers["Content-Type"] = "multipart/form-data";
      }
    }
    request.data = {
      mobile: false,
      ...request.data,
    };
    if (!["/language/read", "/language/list"].includes(request?.url)) {
      request.data = {
        ...request.data,
        language_code: language || "en",
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.name === "TokenExpiredError" || response?.data?.type === 4) {
      window.location.pathname = "/logout";
      return;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
