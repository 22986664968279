import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginPage from "./screens/login/LoginPage";
import HomePage from "./screens/home/HomePage";
import Curriculum from "./screens/curriculum/Curriculum";
import MyFavourites from "./screens/myFavourites/MyFavourites";
import Givings from "./screens/givings/Givings";
import Testimonials from "./screens/testimonials/Testimonials";
import VideoInfo from "./components/common/VideoInfo";
import SeeAll from "./components/common/SeeAll";
import MyProfile from "./components/profileMenu/MyProfile";
import MyDownloads from "./components/profileMenu/MyDownloads";
import GivingTwo from "./components/givings/GivingTwo";
import GivingPastList from "./components/givings/GivingPastList";
import About from "./components/profileMenu/About";
import LessonDetails from "./components/curriculum/LessonDetails";
import VideoPlayerContainer from "./components/VideoPlayer/VideoPlayerContainer";
import CategoryInfo from "./components/common/CategoryInfo";
import TestimonialInfo from "./components/common/TestimonialInfo";
import CurriculumDetailsV2 from "./components/curriculum/CurriculumDetailsV2";
import SessionExpired from "./components/common/SessionExpired";
import HelpAndSupport from "./screens/support/HelpAndSupport";
import { setMobileView } from "./store/Common/CommonAction";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import { languageCurrentAction, languagePreferredAction } from "./store/Home/HomeAction";
import Contact from "./components/common/Contact";
import Media from "./components/common/Media";
import Devotions from "./screens/devotions/Devotions";
import CertificateDownload from "./screens/certificate/CertificateDownload";

function App() {
  const dispatch = useDispatch();
  const { languageHeadings } = useSelector((state) => state.languageReducer.data);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        dispatch(setMobileView(true));
      } else {
        dispatch(setMobileView(false));
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    const language_preferred = localStorage.getItem("language_preferred");
    if (language_preferred) {
      const currentLanguage = languageHeadings.filter(
        (item) => item.language_code === language_preferred
      );
      dispatch(languageCurrentAction(currentLanguage));
      dispatch(languagePreferredAction(language_preferred));
    } else {
      localStorage.setItem("language_preferred", "en");
      const currentLanguage = languageHeadings.filter((item) => item.language_code === "en");
      dispatch(languageCurrentAction(currentLanguage));
      dispatch(languagePreferredAction(language_preferred));
    }

    return window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="web-app">
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<SessionExpired />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/devotions" element={<Devotions />} />
              <Route path="/devotions/:id" element={<Devotions />} />
              <Route path="/curriculum" element={<Curriculum />} />
              <Route path="/curriculum-details/:id" element={<CurriculumDetailsV2 />} />
              <Route path="/lesson-details/:id/:index" element={<LessonDetails />} />
              <Route path="/favourites" element={<MyFavourites />} />
              <Route path="/givings" element={<Givings />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/videoInfo/:id" element={<VideoInfo />} />
              <Route path="/categoryInfo/:id" element={<CategoryInfo />} />
              <Route path="/testimonialInfo/:id" element={<TestimonialInfo />} />
              <Route path="/seeAll" element={<SeeAll />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/downloads" element={<MyDownloads />} />
              <Route path="/givings/comp" element={<GivingTwo />} />
              <Route path="/givings/past-list" element={<GivingPastList />} />
              <Route path="/about" element={<About />} />
              <Route path="/video-player" element={<VideoPlayerContainer />} />
              <Route path="/support" element={<HelpAndSupport />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/media" element={<Media />} />
              <Route path="/certificate/:id" element={<CertificateDownload />} />
            </>
          )
        )}
      />
      <ToastContainer />
    </div>
  );
}

export default App;
