import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { setShowNavigationAlertAction } from "../../store/GlobalLoader/GlobalLoaderAction";

const NavigationConfirmModal = ({ blocker }) => {
  const dispatch = useDispatch();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  return (
    <ReactModal
      isOpen={blocker?.state === "blocked"}
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
    >
      <div className="bg-blackishV2 w-[30dvw] gap-3 text-white flex flex-col items-center px-20 py-12">
        <h1 className="font-bold text-xl mb-5">
          {currentLanguageTitles?.[0]?.home_meta?.want_to_quit_lbl ??
            "Are you sure you want to quit this page?"}
        </h1>
        <div className="w-full gap-4 grid grid-cols-2">
          <button
            onClick={() => blocker?.reset()}
            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
          >
            {currentLanguageTitles?.[0]?.home_meta?.cancel_btn ?? "Cancel"}
          </button>
          <button
            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
            onClick={() => {
              blocker?.proceed();
              dispatch(setShowNavigationAlertAction(false));
            }}
          >
            {currentLanguageTitles?.[0]?.home_meta?.ok_btn ?? "Ok"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default NavigationConfirmModal;
