import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, EffectCoverflow, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { MdCancel, MdDownload } from "react-icons/md";
import { BsArrowLeftCircle, BsArrowRightCircle, BsPlayCircleFill } from "react-icons/bs";
import { strippedString } from "../../utils/Utils";
import { processVideoDownload } from "../../utils/VideoDownloadAndSave";
import { useDispatch, useSelector } from "react-redux";
import { savePickWhereLeftAction } from "../../store/Home/HomeAction";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import { setShowNavigationAlertAction } from "../../store/GlobalLoader/GlobalLoaderAction";
import Hls from "hls.js";
import ReactModal from "react-modal";

const LessonAndSlideComp = ({
  lesson,
  activeItem,
  setActiveItem,
  curriculumId,
  setCurriculumData,
  getCurriculumDetails,
  setShowLessonPopup,
  setShowTrailerModal,
  setTrailerData,
  width,
  topPopShow,
  setTopPopShow,
}) => {
  const { isAuthenticated } = useAuth0();
  const { isMobile } = useSelector((state) => state.common);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const playerRef = useRef();
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(true);
  const [remainingReadTime, setRemainingReadTime] = useState(0);
  const [playedVideoProgress, setPlayedVideoProgress] = useState(0);
  const [showSlidePopup, setShowSlidePopup] = useState(false);
  const [activeSlideItem, setActiveSlideItem] = useState({});
  const [activeSlideItemIndex, setActiveSlideItemIndex] = useState(0);

  console.log("isAuthenticated1", isAuthenticated, lesson);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleNavigate = (item, index) => {
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, {
        state: { _id: item?._id },
      });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/lesson-details/${item._id}/${index}`, {
        state: { _id: item?._id },
      });
    }
  };

  const handleSlideChange = (swiper) => {
    console.log(swiper, "swiper");
    setActiveIndex(swiper.realIndex);
  };

  const saveUserProgress = ({
    lessonTitle,
    lessonObjId,
    slideTitle,
    slideObjId,
    percentageWatched,
  }) => {
    dispatch(
      savePickWhereLeftAction({
        apiPayloadRequest: {
          type: "2", // 1-for video, 2- for curriculum, 3- for category
          curriculumObjId: curriculumId,
          lessonTitle: lessonTitle,
          lessonObjId: lessonObjId,
          slidetitle: slideTitle,
          slideObjId: slideObjId,
          percentagewatched: percentageWatched,
        },
        callback: (res) => {
          console.log("progress saved");
          setCurriculumData((prev) => ({
            ...prev,
            lessons: prev.lessons?.map((lessonItem) => {
              if (lessonItem?.lessonObjId === lessonObjId) {
                return {
                  ...lessonItem,
                  slides: lessonItem?.slides?.map((slideItem) => {
                    if (slideItem?.slideObjId === slideObjId) {
                      return { ...slideItem, watch_percentage: percentageWatched };
                    }
                    return slideItem;
                  }),
                };
              }
              return lessonItem;
            }),
          }));
          getCurriculumDetails(true);
        },
      })
    );
  };

  const updateSlideProgress = ({ slide, lesson, slideCompleted = false }) => {
    if (isAuthenticated) {
      let percentageWatched = 0;
      if (slideCompleted) {
        percentageWatched = 100;
        setShowNavigationAlert(false);
      } else if (
        slide?.slide_type?.[0]?.type_of_slide === "0" ||
        slide?.slide_type?.[0]?.type_of_slide === "2" ||
        slide?.slide_type?.[0]?.type_of_slide === "3"
      ) {
        percentageWatched = 100 - remainingReadTime * 10;
      } else {
        percentageWatched = playedVideoProgress;
      }
      if (slide?.watch_percentage < 100 && slide?.watch_percentage < percentageWatched) {
        saveUserProgress({
          lessonTitle: lesson?.lesson_title,
          lessonObjId: lesson?.lessonObjId,
          slideTitle: slide?.slide_title,
          slideObjId: slide?.slideObjId,
          percentageWatched: percentageWatched,
        });
      }
    }
  };

  const onVideoDownload = async (item) => {
    const record = {
      _id: item?._id,
      url: item?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link,
      title: item?.title,
      image: lesson?.lesson_tumbnail_urls?.[1]?.url_link,
      category: "curriculum",
    };
    await processVideoDownload(record);
  };

  const onDuration = (videoDuration, watch_percentage = 0) => {
    const percentagewatched = parseFloat(watch_percentage ?? 0);

    if (percentagewatched > 0) {
      const timeToStart = Math.round((percentagewatched / 100) * videoDuration);
      playerRef.current?.seekTo(timeToStart, "seconds");
    }
  };

  const lessonTrailer =
    lesson?.lesson_trailers?.find((i) => i?.type_of_url === "1")?.url_link ?? "";

  return (
    <div className="md:px-6 pt-[3%]">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        draggable={true}
        navigation={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          // stretch: "100%",
          //   scale: 0.8,
          // depth: 100,
          stretch: width * (isMobile ? 1 : 0.8),
          depth: width * (isMobile ? 1 : 0.11),
          modifier: 1,
          slideShadows: false,
        }}
        loop={false}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="flex justify-center"
        onSlideChange={handleSlideChange}
        observer={true}
        observeParents={true}
        parallax={true}
        onInit={(ev) => setSwiper(ev)}
      >
        {lesson?.slides?.map((slide, index) => (
          <SwiperSlide>
            <div className="flex justify-center w-full">
              <div className={`w-[95%] md:w-[85%]`} id={slide?.slideObjId}>
                {index === activeIndex && (
                  <p
                    className="text-white text-xl font-medium cursor-pointer mb-0 flex gap-3"
                    onClick={() => {
                      setActiveItem(lesson);
                      setShowLessonPopup((prev) => !prev);
                    }}
                  >
                    {lesson?.lesson_title}
                    {lessonTrailer && (
                      <BsPlayCircleFill
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowTrailerModal(true);
                          setTrailerData({
                            video: lessonTrailer,
                            description: lesson?.lesson_description,
                          });
                        }}
                        color="#EFBC51"
                        className="h-auto cursor-pointer text-2xl"
                        data-tooltip-id="lesson_trailer"
                        data-tooltip-place="right-start"
                        data-tooltip-content="Watch Preview"
                      />
                    )}
                    <Tooltip
                      id="lesson_trailer"
                      className="border border-greyish border-opacity-20"
                    />
                  </p>
                )}
                {topPopShow &&
                  index === activeIndex &&
                  activeItem?.lesson_title === lesson?.lesson_title && (
                    <div
                      className="bg-black border-b-0 border border-white border-opacity-30 w-full aspect-[16/13] md:aspect-[16/8] flex justify-center items-center relative rounded-t-2xl overflow-hidden mt-3"
                      onMouseOver={() => {
                        setActive(true);
                      }}
                      onMouseOut={() => {
                        setActive(false);
                      }}
                    >
                      <button className="absolute top-3 md:top-5 right-3 md:right-5 z-10 flex gap-5 items-center drop-shadow-lg">
                        {isAuthenticated &&
                          slide?.watch_percentage < 100 &&
                          (slide?.slide_type?.[0]?.type_of_slide === "0" ||
                            slide?.slide_type?.[0]?.type_of_slide === "2" ||
                            slide?.slide_type?.[0]?.type_of_slide === "3") && (
                            <CountdownCircleTimer
                              isPlaying
                              duration={10}
                              strokeWidth={3}
                              size={27}
                              colors={["#EFBC51", "#EFBC51", "#EFBC51", "#EFBC51"]}
                              colorsTime={[10, 5, 2, 0]}
                              onUpdate={(time) => setRemainingReadTime(time)}
                              onComplete={() =>
                                updateSlideProgress({ slide, lesson, slideCompleted: true })
                              }
                            >
                              {({ remainingTime }) => (
                                <span className="text-yellowish text-sm">{remainingTime}</span>
                              )}
                            </CountdownCircleTimer>
                          )}
                        <BsArrowLeftCircle
                          size={25}
                          color="#EFBC51"
                          onClick={() => {
                            updateSlideProgress({ slide, lesson });
                            swiper.slidePrev();
                          }}
                        />
                        <BsArrowRightCircle
                          onClick={() => {
                            updateSlideProgress({ slide, lesson });
                            swiper.slideNext();
                          }}
                          size={25}
                          color="#EFBC51"
                        />
                        <MdCancel
                          size={20}
                          color="#EFBC51"
                          onClick={() => {
                            updateSlideProgress({ slide, lesson });
                            setTopPopShow(false);
                          }}
                        />
                        {slide?.slide_type?.[0]?.type_of_slide === "1" &&
                          slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link && (
                            <MdDownload
                              size={20}
                              color="#EFBC51"
                              onClick={() => onVideoDownload(slide)}
                            />
                          )}
                      </button>
                      {slide?.slide_type && slide?.slide_type?.length > 0 ? (
                        slide?.slide_type?.[0]?.type_of_slide === "0" ? (
                          <div
                            className="w-full h-full bg-black bg-cover bg-center bg-no-repeat"
                            style={{
                              backgroundImage: `url('${
                                slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link ?? ""
                              }')`,
                            }}
                          >
                            <div className="h-[70%] w-full flex items-center px-[2%]">
                              <div className="w-full h-full overflow-auto custom-scrollbarV1 text-white text-xl font-medium p-4">
                                {slide?.slide_title}
                              </div>
                            </div>
                          </div>
                        ) : slide?.slide_type?.[0]?.type_of_slide === "1" ? (
                          slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link ? (
                            <ReactPlayer
                              height={"100%"}
                              width={"100%"}
                              ref={playerRef}
                              playing
                              url={slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link}
                              controls={true}
                              onStart={() => setActive(false)}
                              onProgress={(time) => setPlayedVideoProgress(time?.played * 100)}
                              onEnded={() =>
                                updateSlideProgress({ slide, lesson, slideCompleted: true })
                              }
                              onDuration={(duration) =>
                                onDuration(duration, slide?.watch_percentage)
                              }
                              config={{
                                file: {
                                  forceHLS:
                                    slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link?.includes(
                                      ".m3u8"
                                    ),
                                  hlsVersion: "latest",
                                  hls: (url) => {
                                    const hls = new Hls();
                                    hls.loadSource(url);
                                    return hls;
                                  },
                                },
                              }}
                            />
                          ) : (
                            <p className="text-white opacity-30 text-2xl">
                              {currentLanguageTitles?.[0]?.curricullum_meta?.no_video_url_lbl ??
                                "No Video URL.."}{" "}
                            </p>
                          )
                        ) : slide?.slide_type?.[0]?.type_of_slide === "3" ? (
                          <div
                            className="w-full h-full font-bold text-xl bg-cover bg-no-repeat"
                            style={{
                              backgroundImage: `url('${slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link}')`,
                            }}
                          >
                            <div className="flex gap-5 p-[2%] h-[17%] md:h-[30%] items-center mt-10 md:mt-0">
                              <div className="h-full w-[50px] bg-yellowish"></div>
                              <p className="text-xl leading-tight md:text-5xl font-medium md:font-normal w-10/12">
                                {lesson?.lesson_title}
                              </p>
                            </div>
                            <div className="h-[70%] w-full flex items-center px-[2%]">
                              <div
                                className="w-full h-full overflow-auto custom-scrollbarV1 custom-content-description py-3 !text-sm md:text-inherit"
                                dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="w-full h-full font-bold text-xl"
                            style={{
                              background:
                                slide?.slide_type?.[0]?.type_of_slide === "2"
                                  ? slide?.slide_type?.[0]?.slide_bg_color
                                  : "white",
                            }}
                          >
                            <div className="flex gap-5 p-[2%] h-[30%] items-center mt-10 md:mt-0">
                              <div className="h-full w-[50px] bg-yellowish"></div>
                              <p
                                className={`text-3xl md:text-5xl font-normal w-10/12 whitespace-pre ${
                                  slide?.slide_type?.[0]?.slide_bg_color === "#ffffff"
                                    ? "text-black"
                                    : "text-white"
                                }`}
                              >
                                {lesson?.lesson_title}
                              </p>
                            </div>
                            <div className="h-[70%] w-full flex items-center px-[2%]">
                              <div
                                className={`w-full h-full overflow-auto custom-scrollbarV1 custom-content-description ${
                                  slide?.slide_type?.[0]?.slide_bg_color === "#ffffff"
                                    ? "text-black"
                                    : "text-white"
                                }`}
                                dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                              ></div>
                            </div>
                          </div>
                        )
                      ) : (
                        <p className="text-white opacity-50 text-2xl">
                          {currentLanguageTitles?.[0]?.curricullum_meta?.slide_no_data_lbl ??
                            "No Data..."}
                        </p>
                      )}
                      {active &&
                        slide?.slide_type &&
                        slide?.slide_type?.length > 0 &&
                        slide?.slide_type?.[0]?.type_of_slide === "1" && (
                          <div className="flex flex-col justify-end items-start w-full p-6 bg-gradient-to-b from-black from-[10%] absolute top-0">
                            <p className="text-white text-lg md:text-2xl font-bold mb-2 w-11/12">
                              {slide?.slide_title}
                            </p>
                            <p
                              className="text-white text-lg text-opacity-60 text-start whitespace-pre-line custom-content-description"
                              dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                            ></p>
                          </div>
                        )}
                    </div>
                  )}
                <div
                  className={`w-full flex flex-col pb-8 ${
                    topPopShow &&
                    index === activeIndex &&
                    activeItem?.lesson_title === lesson?.lesson_title
                      ? ""
                      : "mt-3"
                  }`}
                >
                  <div
                    className={`w-full md:min-w-[300px] md:aspect-[16/3] bg-cover bg-no-repeat relative overflow-hidden bg-black border border-white border-opacity-30 flex flex-col md:flex-row justify-startitems-center gap-4 p-4 rounded-b-2xl ${
                      index === activeIndex + 1 ? "mt-4" : ""
                    } ${index === activeIndex + 2 ? "mt-8" : ""} ${
                      !topPopShow ? "rounded-t-2xl" : ""
                    }`}
                  >
                    <div
                      style={{
                        backgroundImage: `url('${
                          slide?.slide_bg?.[1]?.url_link
                            ? slide?.slide_bg?.[1]?.url_link
                            : slide?.slide_type?.[0]?.type_of_slide === "0"
                            ? slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                            : slide?.slide_type?.[0]?.type_of_slide === "3"
                            ? slide?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                            : lesson?.lesson_tumbnail_urls?.[1]?.url_link
                        }')`,
                      }}
                      className="h-full min-w-[26%] aspect-video rounded-xl overflow-hidden bg-black bg-cover p-4 flex flex-col justify-end relative bg-top"
                      // onClick={() => {
                      //   setActiveItem(lesson);
                      //   setShowLessonPopup((prev) => !prev);
                      // setTimeout(() => {
                      //   const slideBottomPopUp = document.getElementById(
                      //     `${slide?.slideObjId}BottomPopShow`
                      //   );
                      //   if (slideBottomPopUp)
                      //     slideBottomPopUp.scrollIntoView({ block: "center" });
                      // }, 1000);
                      // }}
                    ></div>
                    <div className="h-full flex flex-col justify-between min-w-[60%] flex-1">
                      <div className="flex flex-col flex-1 w-full overflow-ellipsis">
                        <p className="text-white font-bold text-lg md:text-3xl overflow-ellipsis text-nowrap text-start overflow-hidden w-[95%]">
                          {slide?.slide_title}
                        </p>
                        <p
                          style={{
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                          }}
                          className="text-white text-opacity-60 overflow-ellipsis overflow-hidden flex-1  custom-content-description line-clamp-2"
                        >
                          {strippedString(slide?.slide_description)?.length > 250 ? (
                            <>
                              {strippedString(slide?.slide_description)?.substring(
                                0,
                                isMobile ? 60 : 250
                              ) + ".."}{" "}
                              <span
                                className="text-yellowish cursor-pointer"
                                onClick={() => {
                                  setActiveItem(lesson);
                                  setShowLessonPopup((prev) => !prev);
                                  // setTimeout(() => {
                                  //   const slideBottomPopUp = document.getElementById(
                                  //     `${slide?.slideObjId}BottomPopShow`
                                  //   );
                                  //   if (slideBottomPopUp)
                                  //     slideBottomPopUp.scrollIntoView({ block: "center" });
                                  // }, 1000);
                                }}
                              >
                                {currentLanguageTitles?.[0]?.curricullum_meta?.more_lbl ?? "more"}
                              </span>
                            </>
                          ) : (
                            strippedString(slide?.slide_description)
                          )}
                        </p>
                      </div>
                      <div className="flex items-center md:items-end justify-between mt-3 md:mt-0">
                        <button
                          className="bg-yellowish md:text-xs lg:text-base py-2 px-6 w-fit rounded-lg font-semibold text-black"
                          onClick={() => {
                            console.log("isAuthenticated", isAuthenticated);
                            console.log("isMobile", isMobile);
                            if (isAuthenticated) {
                              setShowNavigationAlert(true);
                            }
                            setActiveItem(lesson);
                            if (isMobile) {
                              setShowSlidePopup(true);
                              setActiveSlideItem(slide);
                              setActiveSlideItemIndex(index);
                            } else {
                              console.log("check");
                              setTopPopShow((prev) => !prev);
                              setTimeout(() => {
                                const slideDetailsPopUp = document.getElementById(
                                  slide?.slideObjId
                                );
                                if (slideDetailsPopUp)
                                  slideDetailsPopUp.scrollIntoView({ block: "center" });
                              }, 1000);
                            }
                          }}
                        >
                          {/* {slide?.slide_type?.[0]?.type_of_slide === "2" ||
                          slide?.slide_type?.[0]?.type_of_slide === "3"
                            ? "Read Now"
                            : slide?.slide_type?.[0]?.type_of_slide === "0"
                            ? "View Now"
                            : "Watch Now"} */}
                          {currentLanguageTitles?.[0]?.curricullum_meta?.start_lesson_lbl ??
                            "Start Lesson"}
                        </button>
                        {isAuthenticated && (
                          <div className="flex gap-2 items-center">
                            <p className=" text-[12px] text-white opacity-50 text-center">
                              {currentLanguageTitles?.[0]?.curricullum_meta?.completed_lbl ??
                                "Completed"}{" "}
                              {slide?.watch_percentage ? parseInt(slide?.watch_percentage) : 0} %
                            </p>
                            <CountdownCircleTimer
                              isPlaying={false}
                              key={slide?.watch_percentage}
                              initialRemainingTime={slide?.watch_percentage ?? 0}
                              duration={100}
                              strokeWidth={3}
                              size={27}
                              colors={["#EFBC51", "#EFBC51", "#EFBC51", "#EFBC51"]}
                              colorsTime={[100, 5, 2, 0]}
                            ></CountdownCircleTimer>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {bottomPopShow && activeItem === lesson?.lesson_title && (
                    <div
                      className="bg-zinc-800 border-t-0 border-white border-opacity-30 border w-full rounded-b-2xl p-4"
                      id={`${slide?.slideObjId}BottomPopShow`}
                    >
                      <p className="text-white font-bold text-3xl">{slide?.slide_title}</p>
                      <p
                        className="!text-white opacity-60 mt-4 pb-2 custom-content-description max-h-[45vh] overflow-auto no-scrollbar"
                        dangerouslySetInnerHTML={{ __html: slide?.slide_description }}
                      ></p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001,
            padding: 0, // Adjust this value as needed
          },
        }}
        isOpen={showSlidePopup}
        className={"overflow-hidden"}
        onRequestClose={() => setShowSlidePopup(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <div className="bg-zinc-800 border-t-0 border-white border-opacity-30 border w-[90vw] min-h-[20vh] max-h-[80vh] custom-scrollbarV1 rounded-2xl">
            <div
              className="bg-black border-b-0 border border-white border-opacity-30 w-full flex flex-col justify-center items-center relative rounded-t-2xl"
              onMouseOver={() => {
                setActive(true);
              }}
              onMouseOut={() => {
                setActive(false);
              }}
            >
              <button className="absolute -top-9 md:top-5 right-0 md:right-5 z-10 flex gap-5 items-center drop-shadow-lg">
                {isAuthenticated &&
                  activeSlideItem?.watch_percentage < 100 &&
                  (activeSlideItem?.slide_type?.[0]?.type_of_slide === "0" ||
                    activeSlideItem?.slide_type?.[0]?.type_of_slide === "2" ||
                    activeSlideItem?.slide_type?.[0]?.type_of_slide === "3") && (
                    <CountdownCircleTimer
                      isPlaying
                      duration={10}
                      strokeWidth={3}
                      size={27}
                      colors={["#EFBC51", "#EFBC51", "#EFBC51", "#EFBC51"]}
                      colorsTime={[10, 5, 2, 0]}
                      onUpdate={(time) => setRemainingReadTime(time)}
                      onComplete={() =>
                        updateSlideProgress({ activeSlideItem, lesson, slideCompleted: true })
                      }
                    >
                      {({ remainingTime }) => (
                        <span className="text-yellowish text-sm">{remainingTime}</span>
                      )}
                    </CountdownCircleTimer>
                  )}
                {activeSlideItemIndex > 0 && (
                  <BsArrowLeftCircle
                    size={25}
                    color="#EFBC51"
                    onClick={() => {
                      updateSlideProgress({ activeSlideItem, lesson });
                      setActiveSlideItem(lesson?.slides?.[activeSlideItemIndex - 1]);
                      setActiveSlideItemIndex((prev) => prev - 1);
                    }}
                  />
                )}
                {activeSlideItemIndex < lesson?.slides?.length - 1 && (
                  <BsArrowRightCircle
                    onClick={() => {
                      updateSlideProgress({ activeSlideItem, lesson });
                      setActiveSlideItem(lesson?.slides?.[activeSlideItemIndex + 1]);
                      setActiveSlideItemIndex((prev) => prev + 1);
                    }}
                    size={25}
                    color="#EFBC51"
                  />
                )}
                {activeSlideItem?.slide_type?.[0]?.type_of_slide === "1" &&
                  activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link && (
                    <MdDownload
                      size={25}
                      color="#EFBC51"
                      onClick={() => onVideoDownload(activeSlideItem)}
                    />
                  )}
                <MdCancel
                  size={28}
                  color="#EFBC51"
                  onClick={() => {
                    updateSlideProgress({ activeSlideItem, lesson });
                    setShowSlidePopup(false);
                  }}
                />
              </button>
              {activeSlideItem?.slide_type && activeSlideItem?.slide_type?.length > 0 ? (
                activeSlideItem?.slide_type?.[0]?.type_of_slide === "0" ? (
                  <div
                    className="w-full h-full bg-black bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url('${
                        activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link ?? ""
                      }')`,
                    }}
                  >
                    <div className="h-[90%] w-full flex items-center px-[2%]">
                      <div className="w-full h-full overflow-auto custom-scrollbarV1 text-white text-xl font-medium p-4">
                        {activeSlideItem?.slide_title}
                      </div>
                    </div>
                  </div>
                ) : activeSlideItem?.slide_type?.[0]?.type_of_slide === "1" ? (
                  activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link ? (
                    <ReactPlayer
                      height={"100%"}
                      width={"100%"}
                      ref={playerRef}
                      playing
                      url={activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link}
                      controls={true}
                      onStart={() => setActive(false)}
                      onProgress={(time) => setPlayedVideoProgress(time?.played * 100)}
                      onEnded={() =>
                        updateSlideProgress({ activeSlideItem, lesson, slideCompleted: true })
                      }
                      onDuration={(duration) =>
                        onDuration(duration, activeSlideItem?.watch_percentage)
                      }
                      config={{
                        file: {
                          forceHLS:
                            activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link?.includes(
                              ".m3u8"
                            ),
                          hlsVersion: "latest",
                          hls: (url) => {
                            const hls = new Hls();
                            hls.loadSource(url);
                            return hls;
                          },
                        },
                      }}
                    />
                  ) : (
                    <p className="text-white opacity-30 text-2xl">
                      {currentLanguageTitles?.[0]?.curricullum_meta?.no_video_url_lbl ??
                        "No Video URL.."}{" "}
                    </p>
                  )
                ) : activeSlideItem?.slide_type?.[0]?.type_of_slide === "3" ? (
                  <div
                    className="w-full h-full font-bold text-xl bg-cover bg-no-repeat"
                    style={{
                      backgroundImage: `url('${activeSlideItem?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link}')`,
                    }}
                  >
                    <div className="flex gap-5 p-[2%] items-center mt-10 md:mt-0">
                      <div className="h-[30px] w-[50px] bg-yellowish"></div>
                      <p className="text-xl leading-tight md:text-5xl font-medium md:font-normal w-10/12">
                        {lesson?.lesson_title}
                      </p>
                    </div>
                    <div className="h-[90%] w-full flex items-center px-[2%] py-2">
                      <div
                        className="w-full h-full max-h-[60vh] overflow-auto custom-scrollbarV1 custom-content-description py-3 !text-sm md:text-inherit"
                        dangerouslySetInnerHTML={{ __html: activeSlideItem?.slide_description }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-full h-full font-bold text-xl"
                    style={{
                      background:
                        activeSlideItem?.slide_type?.[0]?.type_of_slide === "2"
                          ? activeSlideItem?.slide_type?.[0]?.slide_bg_color
                          : "white",
                    }}
                  >
                    <div className="flex gap-5 p-[2%] items-center mt-10 md:mt-0">
                      <div className="h-[30px] w-[50px] bg-yellowish"></div>
                      <p
                        className={`text-3xl md:text-5xl font-normal w-10/12 whitespace-pre ${
                          activeSlideItem?.slide_type?.[0]?.slide_bg_color === "#ffffff"
                            ? "text-black"
                            : "text-white"
                        }`}
                      >
                        {lesson?.lesson_title}
                      </p>
                    </div>
                    <div className="h-[90%] w-full flex items-center px-[2%]">
                      <div
                        className={`w-full h-full overflow-auto custom-scrollbarV1 custom-content-description ${
                          activeSlideItem?.slide_type?.[0]?.slide_bg_color === "#ffffff"
                            ? "text-black"
                            : "text-white"
                        }`}
                        dangerouslySetInnerHTML={{ __html: activeSlideItem?.slide_description }}
                      ></div>
                    </div>
                  </div>
                )
              ) : (
                <p className="text-white opacity-50 text-2xl">
                  {currentLanguageTitles?.[0]?.curricullum_meta?.slide_no_data_lbl ?? "No Data..."}
                </p>
              )}
              {activeSlideItem?.slide_type &&
                activeSlideItem?.slide_type?.length > 0 &&
                activeSlideItem?.slide_type?.[0]?.type_of_slide === "1" && (
                  <div className="flex flex-col justify-end items-start w-full p-6 bg-gradient-to-b from-black from-[10%]">
                    <p className="text-white text-lg md:text-2xl font-bold mb-2 w-11/12">
                      {activeSlideItem?.slide_title}
                    </p>
                    <p
                      className="text-white text-lg text-opacity-60 text-start whitespace-pre-line custom-content-description max-h-[50vh] overflow-y-auto"
                      dangerouslySetInnerHTML={{
                        __html:
                          activeSlideItem?.slide_description + activeSlideItem?.slide_description,
                      }}
                    ></p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default LessonAndSlideComp;
