import { useState } from "react";
import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
import FooterBar from "./FooterBar";
import { useSelector } from "react-redux";
import TextInputField from "./TextInputField";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhone, FaPhoneAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

const Contact = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const [tabValues, setTabsValues] = useState(["Contact"]);
  const [contactValues, setContactValues] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [contactErrors, setContactErrors] = useState({
    name: true,
    phone: true,
    email: true,
    message: true,
  });

  const handleChange = (e) => {
    setContactValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setContactErrors((prev) => ({ ...prev, [e.target.name]: true }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          back={true}
          tabValues={tabValues}
          active={0}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.menus) {
              const requiredLangData = titles?.menus;
              setTabsValues([requiredLangData.contact || ""]);
            } else {
              setTabsValues(["Contact"]);
            }
          }}
        />

        <div className="flex flex-col pr-[1%] bg-black flex-1 overflow-y-auto w-full items-center justify-between">
          <div className="w-9/12 grid grid-cols-12 gap-16 px-12 md:px-10 py-4 text-white">
            <div className="col-span-6 grid grid-cols-2 gap-x-6 gap-y-0 items-end justify-center">
              <div className="w-full flex flex-col items-center gap-3 -translate-y-5">
                <div className="w-5/12 flex items-center justify-center translate-y-8 rounded-full bg-yellowish aspect-square">
                  <IoLocationSharp className="text-5xl" />
                </div>
                <div className="w-full bg-blackish p-5 text-center">
                  <h4 className="text-greyish font-semibold text-lg mb-3">Head office address:</h4>
                  <p className="text-sm">122/235, Plot No.17 Fazalganj, Kanpur, UP PIN: 208012</p>
                </div>
              </div>
              <div className="flex flex-col items-center gap-3 -translate-y-5">
                <div className="w-5/12 flex items-center justify-center translate-y-8 rounded-full bg-yellowish aspect-square">
                  <FaPhoneAlt className="text-4xl" />
                </div>
                <div className="w-full bg-blackish p-5 text-center">
                  <h4 className="text-greyish font-semibold text-lg mb-3">Call for Inquiry:</h4>
                  <p className="text-sm">
                    +91 9998886687
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center items-center">
                <div className="flex flex-col items-center gap-3 -translate-y-5">
                  <div className="w-5/12 flex items-center justify-center translate-y-8 rounded-full bg-yellowish aspect-square">
                    <GrMail className="text-4xl" />
                  </div>
                  <div className="w-full bg-blackish p-5 px-8 text-center">
                    <h4 className="text-greyish font-semibold text-lg mb-3">
                      Mail for information:
                    </h4>
                    <p className="text-sm mb-5">support@todaywithgod.org</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <h1 className="text-white text-4xl font-semibold uppercase leading-normal">
                Contact Us
              </h1>
              <div className="bg-yellowish h-0.5 w-10"></div>
              <p>
                Feel free to reach out to us <span className="text-yellowish">anytime</span>
              </p>
              <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3 mt-5">
                <TextInputField
                  name={"name"}
                  labelText={"Name"}
                  placeholder={"Enter your name"}
                  value={contactValues.name}
                  handleInputChange={handleChange}
                  inputStyle="px-3"
                  isFieldRequired={true}
                />
                <TextInputField
                  name={"phone"}
                  labelText={"Phone"}
                  placeholder={"Enter your number"}
                  value={contactValues.phone}
                  handleInputChange={handleChange}
                  inputStyle="px-3"
                  isFieldRequired={true}
                />
                <TextInputField
                  name={"email"}
                  labelText={"Email"}
                  placeholder={"Enter your email"}
                  value={contactValues.email}
                  handleInputChange={handleChange}
                  inputStyle="px-3"
                  isFieldRequired={true}
                />
                <div className="w-full">
                  <label
                    htmlFor="message"
                    className="text-[12px] text-greyish font-medium leading-4 "
                  >
                    How can we help you?
                  </label>
                  <textarea
                    className="bg-blackish p-2 w-full focus:outline-none text-white text-[14px] font-medium mt-2 leading-4 rounded-md placeholder:text-greyish border border-none px-3"
                    rows={6}
                    name="message"
                    placeholder="Enter your message"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default Contact;
