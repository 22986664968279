import * as actionTypes from "../ActionTypes";

export const userFavAction = (payload) => ({
  type: actionTypes.USER_FAV,
  payload,
});

export const userUnFavAction = (payload) => ({
  type: actionTypes.USER_UN_FAV,
  payload,
});

export const getAchievementsAction = (payload) => ({
  type: actionTypes.GET_ACHIEVEMENTS,
  payload,
});

export const languageDataAction = (payload) => ({
  type: actionTypes.LANGUAGE_DATA,
  payload,
});

export const languageListAction = (payload) => ({
  type: actionTypes.LANGUAGE_LIST,
  payload,
});

export const languageCurrentAction = (payload) => ({
  type: actionTypes.LANGUAGE_CURRENT,
  payload,
});

export const languagePreferredAction = (payload) => ({
  type: actionTypes.LANGUAGE_PREFERRED,
  payload,
});

export const setLoginHardBlocker = (payload) => ({
  type: actionTypes.LOGIN_HARD_BLOCKER,
  payload,
});

export const setHoveredActiveItem = (payload) => ({
  type: actionTypes.HOVERED_ACTIVE_ITEM,
  payload,
});

export const getProfileAction = (payload) => ({
  type: actionTypes.GET_PROFILE_REQUEST,
  payload,
});

export const setUserProfileDetailsAction = (payload) => ({
  type: actionTypes.SET_USER_PROFILE_DETAILS,
  payload,
});

export const getLanguageListAction = (payload) => ({
  type: actionTypes.GET_LANGUAGE_LIST_REQUEST,
  payload,
});

export const getPickWhereLeftAction = (payload) => ({
  type: actionTypes.GET_PICK_WHERE_LEFT_REQUEST,
  payload,
});

export const savePickWhereLeftAction = (payload) => ({
  type: actionTypes.SAVE_PICK_WHERE_LEFT_REQUEST,
  payload,
});

export const readLanguageAction = (payload) => ({
  type: actionTypes.READ_LANGUAGE_REQUEST,
  payload,
});

export const readUserFavAction = (payload) => ({
  type: actionTypes.READ_FAV_REQUEST,
  payload,
});

export const saveUserProfileAction = (payload) => {
  return {
    type: actionTypes.SAVE_USER_PROFILE_REQUEST,
    payload: payload,
  };
};

export const updateUserProfileAction = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_REQUEST,
    payload: payload,
  };
};

export const layoutMaincarouselAction = (payload) => ({
  type: actionTypes.LAYOUT_MAIN_CAROUSEL_REQUEST,
  payload,
});

export const layoutTop10Action = (payload) => ({
  type: actionTypes.LAYOUT_TOP10_REQUEST,
  payload,
});

export const layoutHomeScreenAction = (payload) => ({
  type: actionTypes.LAYOUT_HOME_SCREEN_REQUEST,
  payload,
});

export const readVideoAction = (payload) => ({
  type: actionTypes.READ_VIDEO_REQUEST,
  payload,
});

export const uploadAssestsAction = (payload) => {
  return {
    type: actionTypes.UPLOAD_ASSEST_REQUEST,
    payload: payload,
  };
};

export const getPromiseOfTheDayAction = (payload) => {
  return {
    type: actionTypes.GET_PROMISE_OF_THE_DAY_REQUEST,
    payload: payload,
  };
};

export const readPromiseAction = (payload) => {
  return {
    type: actionTypes.GET_PROMISE_REQUEST,
    payload: payload,
  };
};

export const saveSupportRequestAction = (payload) => {
  return {
    type: actionTypes.SAVE_SUPPORT_REQUEST,
    payload: payload,
  };
};

export const savePrayerRequestAction = (payload) => {
  return {
    type: actionTypes.SAVE_PRAYER_REQUEST,
    payload: payload,
  };
};
