import jsPDF from "jspdf";
import { useParams, useSearchParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";

import certificateTemplate from "../../assets/images/certificate_template.jpg";
import { useEffect } from "react";

const CertificateDownload = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getCertificateTemplate = (title, name) => {
    return (
      <div className="w-[792px] relative">
        <img src={certificateTemplate} alt="certificate template" className="w-full" />
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style={{ wordSpacing: "4px" }}
        >
          <p className="w-full text-center">{title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style={{ wordSpacing: "5px" }}
        >
          <p className="w-full text-center">{name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>
    );
  };

  const generatePDFDocument = (title = "") => {
    const username = searchParams.get("username");
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(ReactDOMServer.renderToStaticMarkup(getCertificateTemplate(title, username)), {
      callback: async function (doc) {
        doc.save("Certificate" + new Date().getTime());
      },
      margin: [0, 0, 0, 0],
      width: 792,
      windowWidth: 792,
    });
  };

  const getCurriculumDetails = () => {
    const curriculumId = id.split(".")?.[0];
    dispatch(
      curriculumReadAction({
        apiPayloadRequest: { _id: curriculumId },
        callback: (res) => {
          if (res?.type === 1) {
            const curriculum = res?.data[0];
            generatePDFDocument(curriculum?.curriculum_name);
          }
        },
      })
    );
  };

  useEffect(() => {
    getCurriculumDetails();
  }, []);

  return (
    <div className="flex flex-col bg-black overflow-y-auto w-full gap-10 min-h-[100vh] relative overflow-auto"></div>
  );
};

export default CertificateDownload;
