import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import { setHoveredActiveItem } from "../../store/Home/HomeAction";

import { FaPlay } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";

import Top1 from "../../assets/images/Top10/Top1.svg";
import Top2 from "../../assets/images/Top10/Top2.svg";
import Top3 from "../../assets/images/Top10/Top3.svg";
import Top4 from "../../assets/images/Top10/Top4.svg";
import Top5 from "../../assets/images/Top10/Top5.svg";
import Top6 from "../../assets/images/Top10/Top6.svg";
import Top7 from "../../assets/images/Top10/Top7.svg";
import Top8 from "../../assets/images/Top10/Top8.svg";
import Top9 from "../../assets/images/Top10/Top9.svg";
import Top10 from "../../assets/images/Top10/Top10.svg";
import Hls from "hls.js";
import { MOBILE_DESCRIPTION_LENGTH } from "../../constants/CommonContants";

const imageArray = [Top1, Top2, Top3, Top4, Top5, Top6, Top7, Top8, Top9, Top10];

const Top10Carousel = ({
  data,
  seeAllValue = "See All",
  title = "Top 10",
  perViewImages = 4,
  linkTo,
  Backto = "Home",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMobile } = useSelector((state) => state.common);

  const [cardVisible, setCardVisible] = useState(false);
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (2 + perViewImages / 10);
  const { currentLanguageTitles, hoveredActiveItem } = useSelector(
    (state) => state.languageReducer.data
  );

  const carouselId = useMemo(() => {
    return new Date().getTime() * Math.random() * Math.random();
  }, []);

  useEffect(() => {
    if (hoveredActiveItem) {
      setCardVisible(true);
    }

    return () => setCardVisible(false);
  }, [hoveredActiveItem]);

  const handleSeeAll = (title, data) => {
    navigate("/seeAll", {
      state: { title: title, data: data, Backto: Backto },
    });
  };

  const handleNavigate = (item, index) => {
    console.log("item", item);
    if (item?.type === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (item?.type === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else {
      navigate(`/curriculum-details/${item?._id}`, {
        state: { _id: item?._id },
      });
    }
  };

  const CardDetails = ({ item, index, actualIndex, setActiveItem }) => {
    return (
      <div
        className="flex flex-col w-full"
        onMouseEnter={() => {
          setActiveItem({ ...item, activeIndex: index, carouselId: carouselId });
        }}
      >
        <div
          className={`relative !w-full cursor-pointer bg-no-repeat bg-cover bg-top rounded-lg aspect-video text-white bg-zinc-800 `}
          onClick={() => {
            handleNavigate(item, index);
          }}
          style={{
            backgroundImage: `url('${item?.image}')`,
            width: widthOfImage + "%",
            zIndex: 1,
          }}
        >
          <img
            src={imageArray[actualIndex * perViewImages + index]}
            className={`absolute w-full h-[75%] -bottom-[2px] ${
              actualIndex * perViewImages + index === 4 ? "right-[40%]" : "right-[42%]"
            }  `}
            alt={index + "Number"}
          />
        </div>
        <p className="mt-2 text-white text-sm overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
          {item?.title}
        </p>
      </div>
    );
  };

  const HoverCard = () => {
    const videoRef = useRef();
    const [videoPlayed, setVideoPlayed] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);
    const videoUrl = hoveredActiveItem?.video ?? "";

    const isHLS = videoUrl?.includes(".m3u8");

    console.log("videoPlayed1", videoPlayed);

    useEffect(() => {
      let timeout = null;
      if (cardVisible && videoUrl) {
        timeout = setTimeout(() => {
          setVideoPlayed(true);
          // videoRef.current.play();
        }, 2500);
      }

      return () => {
        clearTimeout(timeout);
        setVideoPlayed(false);
      };
    }, [cardVisible]);

    return (
      <div
        className={`absolute w-1/2 md:w-3/12 z-[100] top-0 flex-col transition-all duration-700 delay-[2000] animate-grow rounded-xl overflow-hidden bg-blackishV2 custom-drop-shadowV1 hidden ${
          cardVisible && hoveredActiveItem?.carouselId === carouselId ? "!block" : ""
        }`}
        onMouseLeave={() => {
          dispatch(setHoveredActiveItem(null));
          setCardVisible(false);
        }}
        style={
          hoveredActiveItem?.activeIndex + 1 === perViewImages
            ? {
                right: `${hoveredActiveItem?.activeIndex + 1 === perViewImages ? "2.2rem" : ""}`,
              }
            : {
                left: `${
                  hoveredActiveItem?.activeIndex === 0
                    ? "2rem"
                    : (hoveredActiveItem?.activeIndex * 100) / perViewImages + "%"
                }`,
              }
        }
      >
        <div className="w-full aspect-video relative">
          {!videoPlayed && (
            <img
              key={hoveredActiveItem?.id}
              src={hoveredActiveItem?.image}
              className="aspect-video object-cover object-top z-10 relative"
              alt={hoveredActiveItem?.title}
            />
          )}
          {/* <video
            className={`absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top ${
              videoPlayed ? "block" : "hidden"
            }`}
            ref={videoRef}
            src={videoUrl}
            muted
          /> */}
          <ReactPlayer
            ref={videoRef}
            url={videoUrl}
            className={`custom-player absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top`}
            width={"100%"}
            height={"100%"}
            muted={videoMuted}
            playing={videoPlayed}
            config={{
              file: {
                forceHLS: isHLS,
                hlsVersion: "latest",
                hls: (url) => {
                  const hls = new Hls();
                  hls.loadSource(url);
                  return hls;
                },
              },
            }}
          />
          <button
            className={`bg-transparent border-none absolute text-xl text-white z-50 right-4 bottom-4 ${
              videoPlayed ? "" : "hidden"
            }`}
            onClick={() => {
              if (videoRef.current) {
                setVideoMuted((prev) => !prev);
                // videoRef.current.muted = !videoRef?.current?.muted;
              }
            }}
          >
            {videoMuted ? <GoMute /> : <GoUnmute />}
          </button>
          <div className="absolute w-full h-[103%] top-0 left-0 bg-gradient-to-t from-blackishV2 z-20 to-black/0 from-5% to-20%"></div>
        </div>
        <div className="py-3 px-3.5">
          <button
            onClick={() => handleNavigate(hoveredActiveItem)}
            className="border-none bg-yellowish text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md font-semibold text-sm mb-4 w-full flex items-center justify-center gap-2 transition-all duration-700 hover:scale-[102%]"
          >
            {hoveredActiveItem?.type === "videos" && <FaPlay />}
            {hoveredActiveItem?.type === "videos"
              ? currentLanguageTitles?.[0]?.home_meta?.watchnow ?? "Watch Now"
              : currentLanguageTitles?.[0]?.home_meta?.readnow ?? "View More"}
          </button>
          <div className="px-0.5">
            <p className="mt-2 text-white text-sm font-semibold overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
              {hoveredActiveItem?.title}
            </p>
            {hoveredActiveItem?.subtitle && (
              <p className="mt-2 text-white text-sm text-opacity-60 text-wrap mb-1">
                {isMobile
                  ? hoveredActiveItem?.subtitle?.substring(0, MOBILE_DESCRIPTION_LENGTH) + ".."
                  : hoveredActiveItem?.subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="z-20 mt-8 pr-4 relative"
      onMouseLeave={() => {
        dispatch(setHoveredActiveItem(null));
        setCardVisible(false);
      }}
    >
      <HoverCard />

      <div className="px-5 flex justify-between items-center">
        <p className="my-3 text-xl md:text-2xl font-semibold text-white">{title}</p>
        <p
          className="text-xs text-yellow-500 cursor-pointer"
          onClick={() => handleSeeAll("Top 10", data)}
        >
          {seeAllValue}
        </p>
      </div>

      <Carousel
        className="p-1"
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={false}
        autoPlay={false}
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div
            key={"top10-item" + index}
            className={`gap-[3%] grid w-full pl-4 pb-8`}
            style={{ gridTemplateColumns: `repeat(${perViewImages}, minmax(0, 1fr))` }}
          >
            {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <CardDetails
                key={"top10-card-item" + i}
                item={item}
                index={i}
                actualIndex={index}
                setActiveItem={(activeitem) => dispatch(setHoveredActiveItem(activeitem))}
              />
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Top10Carousel;
