import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { useDispatch, useSelector } from "react-redux";
import SmallCarousel from "../home/SmallCarousel";
import TopSwiper2 from "./TopSwiper2";
import HeaderBar from "../common/HeaderBar";
import Navbar from "../common/Navbar";
import FooterBar from "../common/FooterBar";
import { ArrayOfImgs10, ArrayOfImgs9 } from "../../constants/NavbarConstants";
import {
  deleteTestimonyAction,
  testimonialApprovedReadAction,
  testimonialByCategoryReadAction,
  testimonialInReviewReadAction,
  testimonialLikedReadAction,
  testimonialReadAction,
} from "../../store/Testimonial/TestimonialAction";
import {
  getAchievementsAction,
  setLoginHardBlocker,
  uploadAssestsAction,
  userFavAction,
  userUnFavAction,
} from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";
import Record from "../../assets/icons/record.svg";
import CommonCarousel from "../home/CommonCarousel";
import { deleteCategoriesTestimonyAction } from "../../store/Categories/CategoriesActions";
import LoadingSlides from "../common/LoadingSlides";
import { useAuth0 } from "@auth0/auth0-react";
import HomeTestimony from "../home/HomeTestimony";
import TestimonialVideoModel from "../home/TestimonialVideoModel";

const TestimonialComp = ({ setShowRecording }) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector((state) => state.common);
  const { isAuthenticated } = useAuth0();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const OPTIONS = {
    type: "video",
    fileName: "testimonial-video",
    fileType: "video/webm",
    mimeType: "video/webm;codecs=vp9",
    width: 1920,
    height: 1080,
    // ondataavailable: (blob) => console.log(blob, "blob"),
    // previewStream: (stream) => console.log(stream, "stream")
  };
  const recordWebcam = useRecordWebcam(OPTIONS);
  // const getRecordingFileHooks = async () => {
  //     const blob = await recordWebcam.getRecording();
  //     console.log({ blob });
  // };

  const initialTabs = ["Testimonials", "Your Testimonies"];
  const initialSeeAll = "See All";
  const checkToken = localStorage.getItem("token");
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");
  const [popCancle, setPopCancel] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [allTestimonials, setAllTestimonials] = useState([]);
  const [allLikedTestimonials, setAllLikeTestimonials] = useState([]);
  const [allInReviewTestimonials, setAllInReviewTestimonials] = useState([]);
  const [allApprovedTestimonials, setAllApprovedTestimonials] = useState([]);
  const [byCategoryTestimonials, setByCategoryTestimonials] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [showRecordedPreview, setShowRecordedPreview] = useState(false);
  const [showInReviewModal, setShowInReviewModal] = useState(false);
  const [inReviewSelectedItemIndex, setInReviewSelectedItemIndex] = useState(0);
  const [testimonialModalType, setTestimonialModalType] = useState("");

  console.log("allInReviewTestimonials", allInReviewTestimonials);
  console.log("allApprovedTestimonials", allApprovedTestimonials);

  const fetchTestimoniesInReview = () => {
    dispatch(
      testimonialInReviewReadAction({
        callback: (res) => {
          const data = res?.data;
          setAllInReviewTestimonials(data || []);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      testimonialReadAction({
        callback: (res) => {
          const data = res?.data?.filter((item) => item.approval_status === 1);
          setAllTestimonials(data || []);
        },
      })
    );
    if (checkToken) {
      dispatch(
        testimonialLikedReadAction({
          callback: (res) => {
            const data = res?.data;
            setAllLikeTestimonials(data || []);
          },
        })
      );
      fetchTestimoniesInReview();
      dispatch(
        testimonialApprovedReadAction({
          callback: (res) => {
            const data = res?.data;
            setAllApprovedTestimonials(data || []);
          },
        })
      );
    }
    dispatch(
      testimonialByCategoryReadAction({
        callback: (res) => {
          console.log("res45", res);
          setByCategoryTestimonials(res?.data || []);
        },
      })
    );
  }, [currentCode, checkToken]);

  useEffect(() => {
    if (recordWebcam.status === "OPEN") {
      recordWebcam.start();
      setIsRecording(true);
    }
    if (recordWebcam.status === "PREVIEW") {
      setIsRecording(false);
      setShowRecordedPreview(true);
    }
  }, [recordWebcam.status]);

  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  const restructureData = (data) => {
    return data?.map((item) => ({
      _id: item._id,
      image: item?.testomonial_rec_tumbnailUrl,
      title: item?.testimonial_name,
      subtitle: item?.testimonial_description,
      category: item?.categories?.[0]?._id || "",
      userName: item?.user?.[0]?.firstName,
      profilePic: item?.user?.[0]?.profilePicUrl,
      video: item?.testomonial_rec_videoUrl,
      isLikedByCurrentUser: item?.isLikedByCurrentUser ?? false,
      type: "testimonial",
    }));
  };

  console.log("byCategoryTestimonials", byCategoryTestimonials);

  const handleFavourite = (item) => {
    if (isAuthenticated) {
      const payload = { type: "4", testimonyObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setAllTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: false } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setAllTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: true } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <div className="flex w-full md:flex-row flex-col overflow-hidden">
      <Navbar active={"Testimonials"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={popCancle}
          tabValues={tabValues}
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={(code) => {
            setCurrectCode(code);
          }}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.testimonies) {
              const requiredLangData = titles?.testimonies;
              setTabsValues([
                requiredLangData.testimonies_lbl,
                requiredLangData.your_testimonies_lbl,
              ]);

              setSeeAllBtn(requiredLangData.see_all_btn);
            } else {
              setTabsValues(initialTabs);
              setSeeAllBtn(initialSeeAll);
            }
          }}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
          {activeTab === 0 ? (
            <>
              {allTestimonials?.length > 0 ? (
                <>
                  {/* <TopSwiper2 data={allTestimonials} /> */}
                  <HomeTestimony
                    perViewImages={isMobile ? 2 : 4}
                    testimonials={allTestimonials?.map((item) => ({
                      _id: item?._id,
                      userName: item?.user[0]?.firstName,
                      profilePic: item?.user[0]?.profilePicUrl,
                      title: item?.testimonial_name,
                      subtitle: item?.testimonial_description,
                      image: item?.testomonial_rec_tumbnailUrl,
                      video: item?.testomonial_rec_videoUrl,
                      isLikedByCurrentUser: item?.isLikedByCurrentUser ?? false,
                      totallikes: item?.totallikes ?? false,
                      type: "testmonial",
                    }))}
                    title={
                      currentLanguageTitles?.[0]?.testimonies?.testimonies_near_you_lbl ??
                      "Testimonies near you"
                    }
                    type="testimonial"
                    showBookmark={true}
                    handleFavourite={handleFavourite}
                    autoplay={true}
                  />
                </>
              ) : (
                <div className="px-6 md:pr-4">
                  <LoadingSlides />
                  <LoadingSlides />
                  <LoadingSlides />
                  <LoadingSlides />
                </div>
              )}
              <div className="px-6 md:pr-4">
                {allLikedTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={
                      currentLanguageTitles?.[0]?.testimonies?.testimonies_you_liked_lbl ??
                      "Testimonial you liked"
                    }
                    data={restructureData(allLikedTestimonials)}
                    seeAll={true}
                    seeAllValue={seeAllBtn}
                    dataTitle={false}
                    perViewImages={isMobile ? 2 : 7}
                    showTitle={true}
                    type={"testimonial"}
                  />
                )}
                {byCategoryTestimonials?.length > 0 &&
                  byCategoryTestimonials?.map((category) => {
                    return (
                      category?.category_name && (
                        <CommonCarousel
                          title={category?.category_name}
                          data={restructureData(category?.documents)}
                          seeAll={true}
                          seeAllValue={seeAllBtn}
                          dataTitle={false}
                          perViewImages={isMobile ? 2 : 7}
                          showTitle={true}
                          linkTo={"testimonial"}
                          Backto="Testimonials"
                        />
                      )
                    );
                  })}
              </div>
            </>
          ) : (
            <div className="px-5 md:px-0">
              <div className="m-0 md:m-6 bg-gray-200 bg-opacity-10 h-[70vh] rounded-3xl flex flex-col gap-4 justify-center items-center">
                <img
                  src={Record}
                  onClick={() => setShowRecording((prev) => !prev)}
                  className="cursor-pointer"
                  alt=""
                />

                <p className="text-4xl text-white font-semibold">
                  {currentLanguageTitles?.[0]?.testimonies?.start_sharing_lbl ?? "Start Sharing"}
                </p>
                <p className="text-lg text-white text-opacity-50">
                  {currentLanguageTitles?.[0]?.testimonies?.share_what_lord_lbl ??
                    "what the Lord has done"}
                </p>
              </div>
              <div className="">
                {allInReviewTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={
                      currentLanguageTitles?.[0]?.testimonies?.your_inreview_testimonies ??
                      "Testimonies In Review"
                    }
                    data={restructureData(allInReviewTestimonials)}
                    seeAll={true}
                    seeAllValue={seeAllBtn}
                    dataTitle={false}
                    perViewImages={isMobile ? 2 : 7}
                    showTitle={true}
                    linkTo={"testimonial"}
                    canDelete={true}
                    hoverCardEffect={false}
                    testimonialClickHandler={(item) => {
                      const itemIndex = allInReviewTestimonials?.findIndex(
                        (d) => d?._id === item?._id
                      );
                      setInReviewSelectedItemIndex(itemIndex);
                      setShowInReviewModal(true);
                      setTestimonialModalType("review");
                    }}
                    handleDelete={(id) => {
                      dispatch(
                        deleteTestimonyAction({
                          apiPayloadRequest: {
                            _id: id,
                          },
                          callback: (res) => {
                            if (res?.type === 1) {
                              fetchTestimoniesInReview();
                              Toastiy("Testimonial Deleted Successfully!", 1);
                            }
                          },
                        })
                      );
                    }}
                  />
                )}
                {allApprovedTestimonials?.length > 0 && (
                  <CommonCarousel
                    title={
                      currentLanguageTitles?.[0]?.testimonies?.your_approved_testimonies ??
                      "Testimonial Approved"
                    }
                    data={restructureData(allApprovedTestimonials)}
                    seeAll={true}
                    seeAllValue={seeAllBtn}
                    dataTitle={false}
                    perViewImages={isMobile ? 2 : 7}
                    showTitle={true}
                    linkTo={"testimonial"}
                    hoverCardEffect={false}
                    testimonialClickHandler={(item) => {
                      const itemIndex = allApprovedTestimonials?.findIndex(
                        (d) => d?._id === item?._id
                      );
                      setInReviewSelectedItemIndex(itemIndex);
                      setShowInReviewModal(true);
                      setTestimonialModalType("approved");
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <TestimonialVideoModel
            isOpen={showInReviewModal}
            onRequestClose={() => setShowInReviewModal((prev) => !prev)}
            allTestimonials={restructureData(
              testimonialModalType === "review" ? allInReviewTestimonials : allApprovedTestimonials
            )}
            selectedItemIndex={inReviewSelectedItemIndex}
            key={
              (testimonialModalType === "review"
                ? allInReviewTestimonials
                : allApprovedTestimonials
              )?.length + inReviewSelectedItemIndex
            }
          />
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default TestimonialComp;
