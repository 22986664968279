import { useRef, useState } from "react";
import { BsArrowLeft, BsPlayCircleFill } from "react-icons/bs";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import { IoMdPlay } from "react-icons/io";
import Hls from "hls.js";

const CategoryVideoModel = ({
  isOpen,
  onRequestClose,
  data,
  relatedVideos = [],
  currentVideoIndex = null,
  setCurrentVideoDetails = null,
  setCurrentVideoIndex = null,
}) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001, // Adjust this value as needed
    },
  };

  const isHLS = data?.video?.includes(".m3u8");

  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoFinished, setVideoFinished] = useState(false);
  const [containerFocus, setContainerFocus] = useState(false);

  console.log("currentVideoIndex", currentVideoIndex);
  console.log("currentVideo", data);

  const togglePlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (playing) {
      // videoRef.current.pause();
      setPlaying((prev) => !prev);
      setVideoFinished(false);
    } else {
      // videoRef.current.play();
      setPlaying((prev) => !prev);
      setVideoFinished(false);
    }
  };

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <ReactModal
      style={customStyles}
      className={
        "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <button
        className="absolute left-4 top-4"
        onClick={() => {
          onRequestClose();
          setPlaying(false);
        }}
      >
        <BsArrowLeft size={40} color="white" />
      </button>
      <div
        className="h-[90vh] aspect-video bg-black rounded-xl flex justify-center items-center cursor-pointer relative"
        onClick={togglePlay}
        onMouseOver={() => setContainerFocus(true)}
        onMouseOut={() => setContainerFocus(false)}
      >
        <div
          className={`absolute top-0 left-0 w-full h-full bg-black/40 ${
            containerFocus ? "flex" : "hidden"
          }`}
        ></div>
        {!playing && (
          <div className="absolute top-0 p-5 w-full">
            <p className="text-xs text-white text-opacity-70  p-2">{data?.description}</p>
          </div>
        )}
        <button
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          onClick={togglePlay}
        >
          {!loading && !playing && (
            <BsPlayCircleFill size={100} className="opacity-80 text-yellowish" />
          )}
        </button>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div className="text-white">Loading...</div>
          </div>
        )}

        <ul
          className={`absolute w-full right-4 top-10 md:w-3/12 overflow-y-auto no-scrollbar h-[calc(100%-5rem)] z-10 border-yellowish/60 rounded-lg bg-blackishV2 border-[0.8px] flex-col transition-all duration-1000 ${
            relatedVideos?.length > 0 && (!playing || videoFinished || containerFocus)
              ? "flex"
              : "hidden"
          }`}
        >
          {relatedVideos?.map((item, index) => {
            return (
              <li
                className={`flex flex-col relative pl-1 pr-3 py-3 ${
                  index === currentVideoIndex ? "bg-yellowish/15" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (setCurrentVideoDetails) {
                    setCurrentVideoDetails(relatedVideos[currentVideoIndex + 1]);
                  }
                  if (setCurrentVideoIndex) {
                    setCurrentVideoIndex(currentVideoIndex + 1);
                  }
                  setVideoFinished(false);

                  setTimeout(() => {
                    setPlaying(true);
                  }, 600);
                }}
              >
                <div className="flex items-center gap-1">
                  <span className="text-white w-4 text-center">
                    {index === currentVideoIndex ? <IoMdPlay className="text-sm" /> : index + 1}
                  </span>
                  <div className="flex-1 relative flex flex-col overflow-hidden">
                    <img
                      src={item?.thumbnail}
                      alt="video-item-1"
                      className="rounded-lg w-full h-full object-cover object-top aspect-[16/11]"
                    />
                    <div className="absolute p-3 bg-gradient-to-t bottom-0 rounded-b-lg from-black from-[2%] h-full w-full">
                      <div className="flex flex-col h-full w-full justify-end">
                        <p className="text-white text-xl font-semibold mb-1 overflow-hidden text-nowrap text-start overflow-ellipsis w-[95%]">
                          {item?.title?.length < 25
                            ? item?.title
                            : item?.title?.substring(0, 25) + ".."}
                        </p>
                        <p className="text-white text-xs w-full text-opacity-60">
                          {item?.description?.length < 40
                            ? item?.description
                            : item?.description?.substring(0, 40) + ".."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>

        <ReactPlayer
          ref={videoRef}
          key={data?.video}
          controls={true}
          className="!h-full !w-auto custom-player object-cover rounded-xl"
          url={data?.video}
          onReady={handleLoadedData}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          playing={playing}
          onEnded={() => {
            setVideoFinished(true);
            setPlaying(false);
          }}
          config={{
            file: {
              forceHLS: isHLS,
              hlsVersion: "latest",
              hls: (url) => {
                const hls = new Hls();
                hls.loadSource(url);
                return hls;
              },
            },
          }}
        />
      </div>
    </ReactModal>
  );
};

export default CategoryVideoModel;
