import React, { useEffect, useRef, useState } from "react";
import CommonCarousel from "./CommonCarousel";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import Hls from "hls.js";

const HomeCurriculumCarousel = ({
  title,
  data = [],
  description,
  bgImage,
  id,
  showProgress = false,
  curriculum_watch_percentage = 0,
  preview = "",
  allowHoverAnimation = false,
}) => {
  const navigate = useNavigate();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const { isMobile } = useSelector((state) => state.common);
  const videoRef = useRef();

  const isHLS = preview?.includes(".m3u8");

  const [isHovered, setIsHovered] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);

  useEffect(() => {
    let timer = null;
    if (isHovered) {
      timer = setTimeout(() => {
        setVideoPlayed(true);
      }, 1500);
    }

    return () => {
      if (timer) clearTimeout(timer);
      setVideoPlayed(false);
    };
  }, [isHovered]);

  const handleMouseEnter = () => {
    console.log("handleMouseEnter");
    if (allowHoverAnimation) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setVideoPlayed(false);
  };

  console.log("preview", preview);
  console.log("videoPlayed", videoPlayed);
  console.log("isHovered", isHovered);

  return (
    <div className="ml-[1%] mt-[5%] pr-[2.5%]">
      <div
        className="w-full rounded-2xl px-[4%] py-[3%] bg-no-repeat bg-cover relative"
        style={{
          backgroundImage: `url('${bgImage}')`,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {preview && videoPlayed && (
          <>
            <ReactPlayer
              ref={videoRef}
              url={preview}
              className={`custom-player absolute top-0 left-0 w-full h-full aspect-video bg-blackishV2 object-cover object-top`}
              width={"100%"}
              height={"100%"}
              playing={true}
              muted={videoMuted}
              config={{
                file: {
                  forceHLS: isHLS,
                  hlsVersion: "latest",
                  hls: (url) => {
                    const hls = new Hls();
                    hls.loadSource(url);
                    return hls;
                  },
                },
              }}
            />
            <button
              className={`bg-transparent border-none absolute text-xl text-white z-50 right-7 top-7`}
              onClick={() => {
                if (videoRef.current) {
                  setVideoMuted((prev) => !prev);
                }
              }}
            >
              {videoMuted ? <GoMute /> : <GoUnmute />}
            </button>
          </>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            background: "black",
            height: "100%",
            width: "100%",
            opacity: 0.75,
          }}
        ></div>
        <div
          className="relative z-50 cursor-pointer"
          onClick={() => navigate(`/curriculum-details/${id}`, { state: { _id: id } })}
        >
          <p className="text-white text-2xl md:text-4xl font-semibold mb-5 flex justify-between items-center">
            <p className="hover:underline cursor-pointer">{title}</p>
            {showProgress && (
              <>
                {curriculum_watch_percentage === 100 ? (
                  <IoCheckmarkDoneCircle className="text-yellowish" />
                ) : curriculum_watch_percentage > 0 ? (
                  <div className="flex gap-2 items-center">
                    <p className=" text-[12px] text-white text-center text-nowrap">
                      In Progress{" "}
                      {curriculum_watch_percentage ? parseInt(curriculum_watch_percentage) : 0} %
                    </p>
                    <CountdownCircleTimer
                      isPlaying={false}
                      key={curriculum_watch_percentage}
                      initialRemainingTime={curriculum_watch_percentage ?? 0}
                      duration={100}
                      strokeWidth={3}
                      size={25}
                      colors={["#EFBC51", "#EFBC51", "#EFBC51", "#EFBC51"]}
                      colorsTime={[100, 5, 2, 0]}
                    ></CountdownCircleTimer>
                  </div>
                ) : null}
              </>
            )}
          </p>
          <p className="text-white text-sm lg:w-[30%] md:w-full min-h-[15vh] text-opacity-60">
            {description?.length <= 300 ? description : description?.substring(0, 300)}{" "}
            {description?.length > 300 && (
              <span
                onClick={() => navigate(`/curriculum-details/${id}`)}
                className="text-yellow-500 font-medium opacity-100 text-opacity-100 cursor-pointer"
              >
                {currentLanguageTitles?.[0]?.curricullum_meta?.more_lbl ?? "more"}
              </span>
            )}
          </p>
        </div>
        <div className="mt-6">
          <CommonCarousel
            _id={id}
            data={data}
            seeAll={false}
            perViewImages={isMobile ? 2 : 4}
            imageType={"landscape"}
            showItemTitle={true}
            dynamiclinkTo={true}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCurriculumCarousel;
