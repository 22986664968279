import React from "react";
import TopVid from "../../assets/images/TopVid.png";
import Play from "../../assets/icons/play.svg";
import { useLocation, useNavigate } from "react-router-dom";

const TopVideo = ({ background, url }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="m-6">
      <div
        style={{ backgroundImage: `url('${background}')` }}
        className="bg-no-repeat bg-cover bg-top bg-yellowish/80 h-[40vh] md:h-[70vh] w-full relative rounded-3xl flex justify-center items-center"
      >
        <img
          className="h-24 cursor-pointer drop-shadow-xl hover:drop-shadow-md transition-all duration-300"
          src={Play}
          onClick={() => {
            navigate("/video-player/", {
              state: { url: url, backTo: "TWG", returnTo: location?.pathname },
            });
          }}
          alt="plat button"
        />
      </div>
    </div>
  );
};

export default TopVideo;
