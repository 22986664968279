import React, { useEffect, useState } from "react";
import { BsStar, BsStarFill, BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { CircularCheckbox } from "../InputComps/CircularCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { saveSupportRequestAction } from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";

const HomeSupportUs = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [likedMost, setLikedMost] = useState("");
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "likedMost") setLikedMost(value);
    if (name === "feedback") setFeedback(value);
  };

  const clearField = () => {
    setRating(0);
    setLikedMost("");
    setFeedback("");
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    clearField();
  };

  const handleSubmit = () => {
    //save form data
    const data = { feedback: feedback, likedmost: likedMost, rating: rating };

    dispatch(
      saveSupportRequestAction({
        apiPayloadRequest: data,
        callback: (res) => {
          Toastiy("Support Request sent!", 1);
          closeModal();
        },
      })
    );
  };

  return (
    <>
      <div className="z-30">
        <p className=" text-lg font-semibold text-white">
          {currentLanguageTitles?.[0]?.home_meta?.support_us_lbl ?? "Support Us"}
        </p>
        <div className="support-us-bg my-5 rounded-2xl flex justify-center bg-center items-center h-[30vh] md:h-[50vh] w-full">
          <button
            className="bg-black opacity-70 font-semibold text-yellow-500 text-opacity-60 py-5 px-10 md:px-20 rounded-xl uppercase text-2xl"
            onClick={() => {
              openModal();
            }}
          >
            {currentLanguageTitles?.[0]?.home_meta?.support_btn ?? "Support"}
          </button>
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001, // Adjust this value as needed
          },
        }}
        className={
          "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
        }
        isOpen={showModal}
        onRequestClose={() => {
          closeModal();
        }}
      >
        <button
          className="absolute left-4 top-4 z-50"
          onClick={() => {
            closeModal();
          }}
        >
          <BsX size={40} color="white" />
        </button>
        <div className="h-[90vh] w-10/12 md:w-5/12 flex justify-center items-center cursor-pointer relative">
          <div className="py-10 bg-black px-8 md:px-12 w-full max-h-full h-fit overflow-y-auto object-cover rounded-xl flex flex-col">
            <div className="mb-6">
              <label className="text-yellowish text-xl font-semibold mb-3">
                {currentLanguageTitles?.[0]?.home_meta?.how_you_rate_lbl ??
                  "How do you rate the overall App?"}
              </label>
              <div className="flex gap-5 mt-3">
                {[...Array(5)].map((e, i) =>
                  rating >= i + 1 ? (
                    <BsStarFill
                      key={"rating-star" + i}
                      className="text-yellow-500 text-2xl"
                      onClick={() => {
                        setRating(i + 1);
                      }}
                    />
                  ) : (
                    <BsStar
                      key={"rating-star" + i}
                      className="text-yellow-500 text-2xl"
                      onClick={() => {
                        setRating(i + 1);
                      }}
                    />
                  )
                )}
              </div>
            </div>
            <div className="mb-6">
              <label className="text-yellowish text-xl font-semibold mb-3">
                {currentLanguageTitles?.[0]?.home_meta?.what_most_you_liked_lbl ??
                  "What did you like the most?"}
              </label>
              <div className="flex flex-col gap-3 mt-3 mb-8">
                <div className="flex items-center">
                  <CircularCheckbox
                    value={currentLanguageTitles?.[0]?.home_meta?.videos_category_lbl ?? "Videos"}
                    name="likedMost"
                    checked={
                      likedMost ===
                      (currentLanguageTitles?.[0]?.home_meta?.videos_category_lbl ?? "Videos")
                    }
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">
                    {currentLanguageTitles?.[0]?.home_meta?.videos_category_lbl ?? "Videos"}
                  </p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={
                      currentLanguageTitles?.[0]?.home_meta?.curriculum_category_lbl ?? "Curriculum"
                    }
                    name="likedMost"
                    checked={
                      likedMost ===
                      (currentLanguageTitles?.[0]?.home_meta?.curriculum_category_lbl ??
                        "Curriculum")
                    }
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">
                    {currentLanguageTitles?.[0]?.home_meta?.curriculum_category_lbl ?? "Curriculum"}
                  </p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={
                      currentLanguageTitles?.[0]?.home_meta?.testimonies_category_lbl ??
                      "Testimonies"
                    }
                    name="likedMost"
                    checked={
                      likedMost ===
                      (currentLanguageTitles?.[0]?.home_meta?.testimonies_category_lbl ??
                        "Testimonies")
                    }
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">
                    {currentLanguageTitles?.[0]?.home_meta?.testimonies_category_lbl ??
                      "Testimonies"}
                  </p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={
                      currentLanguageTitles?.[0]?.home_meta?.twg_category_lbl ?? "Today with God"
                    }
                    name="likedMost"
                    checked={
                      likedMost ===
                      (currentLanguageTitles?.[0]?.home_meta?.twg_category_lbl ?? "Today with God")
                    }
                    onChange={handleInputChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-[16px] text-white">
                    {currentLanguageTitles?.[0]?.home_meta?.twg_category_lbl ?? "Today with God"}
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <label className="text-yellowish text-xl font-semibold mb-6">
                  {currentLanguageTitles?.[0]?.home_meta?.what_explore_next_lbl ??
                    "What would you like to explore next?"}
                </label>
                <textarea
                  placeholder={
                    currentLanguageTitles?.[0]?.home_meta?.type_here_placeholder_lbl ??
                    "Type here.."
                  }
                  rows="3"
                  name="feedback"
                  className="border border-yellowish p-4 bg-transparent mt-4 rounded-xl text-white w-full focus-within:outline-none"
                  onChange={handleInputChange}
                />
              </div>
              <button
                className="bg-yellowish text-yellow-800 font-medium rounded-full flex justify-center whitespace-nowrap mb-5 md:mb-0 md:w-5/12 px-5 py-3 mt-3"
                onClick={handleSubmit}
              >
                {currentLanguageTitles?.[0]?.home_meta?.send_feedback_btn ?? "Send feedback"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default HomeSupportUs;
