import React, { useEffect, useState, useMemo } from "react";
import { Line } from "rc-progress";
import { ImCross } from "react-icons/im";

const ReactStories = ({
  storyData,
  setStoryData,
  currentIndex,
  setCurrentIndex,
  closeModal,
}) => {
  const currentItem = storyData[currentIndex];
  const { type, url, duration, color, text } = currentItem;
  const [startTime, setStartTime] = useState(
    currentItem.startTime === currentItem.duration ? 0 : currentItem.startTime
  );
  const [loading, setLoading] = useState(type === "1");

  const handleVideoLoad = () => {
    const duration = document.getElementById("my_video").duration;
    if (duration) {
      setStoryData((prev) => {
        const newData = [...prev];

        newData[currentIndex] = {
          ...newData[currentIndex],
          duration: duration * 1000,
        };

        return newData;
      });
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  };

  const onClose = () => {
    setStoryData((prev) => {
      const newData = [...prev];

      newData[currentIndex] = {
        ...newData[currentIndex],
        startTime: startTime,
      };

      return newData;
    });

    closeModal();
  };

  const onNext = () => {
    setLoading(storyData[currentIndex + 1].type === "1");

    setCurrentIndex((prevIndex) =>
      prevIndex < storyData.length - 1 ? prevIndex + 1 : prevIndex
    );
    if (currentIndex === storyData.length - 1) {
      console.log("Done");
    } else {
      setStoryData((prev) => {
        const newData = [...prev];

        newData[currentIndex] = {
          ...newData[currentIndex],
          startTime: startTime,
        };

        return newData;
      });

      setStartTime(
        storyData[currentIndex + 1].startTime ===
          storyData[currentIndex + 1].duration
          ? 0
          : storyData[currentIndex + 1].startTime
      );
    }
  };

  const onPrevious = () => {
    setLoading(storyData[currentIndex - 1].type === "1");
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    if (currentIndex === 0) {
      console.log("Done");
    } else {
      setStoryData((prev) => {
        const newData = [...prev];

        newData[currentIndex] = {
          ...newData[currentIndex],
          startTime: startTime,
        };

        return newData;
      });

      setStartTime(
        storyData[currentIndex - 1].startTime ===
          storyData[currentIndex - 1].duration
          ? 0
          : storyData[currentIndex - 1].startTime
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      const timeout = setTimeout(() => {
        if (startTime < duration) {
          setStartTime((prevStartTime) => prevStartTime + 50);
        } else {
          onNext();
        }
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [startTime, onNext, duration, loading]);

  const renderContent = useMemo(() => {
    switch (type) {
      case "0":
        return (
          <img
            style={{ height: "100%" }}
            src={url}
            onLoad={() => console.log("loaded")}
            alt="image not found"
          />
        );

      case "1":
        return (
          <>
            <video
              id="my_video"
              width="100%"
              muted={false}
              autoPlay={true}
              onCanPlayThrough={handleVideoLoad}
              src={`${url}#t=${storyData[currentIndex].startTime / 100},${
                storyData[currentIndex].duration / 100
              }`}
              type="video/mp4"
            />
          </>
        );

      case "2":
        return (
          <div
            style={{
              background: color,
              width: "100%",
              height: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>{text}</p>
          </div>
        );

      default:
        return null;
    }
  }, [type, url, color, text]);

  return (
    <div
      style={{
        width: "100%",
        background: "black",
        height: "100%",
        borderRadius: "10px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        onClick={onClose}
        style={{
          position: "absolute",
          top: "2%",
          right: "2%",
          zIndex: "100",
          color: "white",
          cursor: "pointer",
        }}
      >
        <ImCross size={20} color="white" />
      </div>
      <div
        style={{
          width: "94%",
          position: "absolute",
          bottom: "1%",
          display: "flex",
          gap: "5px",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div className="w-11/12">
          <Line
            percent={(startTime / duration) * 100}
            strokeWidth={2}
            trailWidth={1}
            strokeColor={(startTime / duration) * 100 === 0 ? "gray" : "white"}
            trailColor="gray"
          />
        </div>
        <div className="w-1/12 text-white font-bold text-xs">
          {currentIndex + 1} / {storyData.length}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          position: "absolute",
          left: "0px",
        }}
        onClick={onPrevious}
      ></div>
      <div
        style={{
          width: "50%",
          height: "100%",
          position: "absolute",
          right: "0px",
        }}
        onClick={onNext}
      ></div>
      {renderContent}
    </div>
  );
};

export default ReactStories;
