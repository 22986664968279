import { useState, useRef, useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import VideoPlayer from "./VideoPlayer";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import screenfull from "screenfull";
import PlayControls from "./PlayControls";
import { useDispatch, useSelector } from "react-redux";
import { savePickWhereLeftAction } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";
import LoginHardBlocker from "../common/LoginHardBlocker";
import { processVideoDownload } from "../../utils/VideoDownloadAndSave";
import NavigationConfirmModal from "../common/NavigationConfirmModal";

const VideoPlayerContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showNavigationAlert } = useSelector((state) => state.globalLoaderReducer);
  const { isAuthenticated } = useAuth0();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return showNavigationAlert;
  });

  const initialPlayerValues = {
    playing: true,
    muted: false,
    volume: 0.2,
    played: 0,
    fullscreen: false,
  };

  const [playerStates, setPlayerStates] = useState(initialPlayerValues);
  const [videoUrl, setVideoUrl] = useState("");
  const [isMouseMoving, setIsMouseMoving] = useState(true);

  // useEffect(() => {
  //     const handleMouseMove = () => {
  //         setIsMouseMoving(true);
  //         const timer = setTimeout(() => {
  //             setIsMouseMoving(false);
  //         }, 5000);

  //         return () => {
  //             clearTimeout(timer);
  //         };
  //     };

  //     document.addEventListener('mousemove', handleMouseMove);

  //     return () => {
  //         document.removeEventListener('mousemove', handleMouseMove);
  //     };
  // }, []);

  console.log("location.state", location.state);
  console.log("location5", location);

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";

  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);

  console.log("totalDuration", totalDuration);

  const { playing, muted, volume, played, fullscreen } = playerStates;

  const handlePlayPause = () => {
    setPlayerStates({ ...playerStates, playing: !playerStates.playing });
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleVolumeChange = (event) => {
    setPlayerStates({
      ...playerStates,
      volume: parseFloat(event.target.value / 100),
      muted: event.target.value === 0 ? true : false,
    });
  };

  const handleVolumeSeekDown = (event) => {
    setPlayerStates({
      ...playerStates,
      volume: parseFloat(event.target.value / 100),
      muted: event.target.value === 0 ? true : false,
    });
  };

  const handleToggleFullScreen = () => {
    setPlayerStates({
      ...playerStates,
      fullscreen: !playerStates.fullscreen,
    });
    screenfull.toggle(playerContainerRef.current);
  };

  const handleProgress = (changeState) => {
    setPlayerStates({
      ...playerStates,
      ...changeState,
    });
  };

  const handleSeekChange = (event) => {
    setPlayerStates({
      ...playerStates,
      played: parseFloat(event.target.value / 100),
      playedSeconds: parseFloat((duration * (event.target.value / 100)).toFixed(2)),
    });
  };

  const onVideoDownload = async () => {
    const record = {
      _id: location?.state?._id,
      url: location?.state?.url,
      title: location?.state?.title,
      image: location?.state?.thumbnailUrl,
      category: location?.state?.category,
    };
    await processVideoDownload(record);
  };

  const saveUserProgress = (percentageWatched) => {
    dispatch(
      savePickWhereLeftAction({
        apiPayloadRequest: {
          type: "1", // 1-for video, 2- for curriculum, 3- for category
          videoObjId: location?.state?._id,
          percentagewatched: percentageWatched,
        },
        callback: (res) => {
          console.log("progress saved");
        },
      })
    );
  };

  const updateSlideProgress = (slideCompleted = false) => {
    if (isAuthenticated) {
      let percentageWatched = 0;
      if (slideCompleted) {
        percentageWatched = 100;
      } else {
        percentageWatched = parseFloat((playerStates.played * 100).toFixed(2));
      }
      saveUserProgress(percentageWatched);
    }
  };

  useEffect(() => {
    // console.log("location?.state", location?.state);
    // if (location?.state?.download && location?.state?._id) {
    //   getDataFromIndexedDB(location?.state?._id, location?.state?.category).then((result) => {
    //     const blob = new Blob([result?.data], { type: "video/mp4" });
    //     const newVideoUrl = URL.createObjectURL(blob);
    //     console.log("videoData", result);
    //     console.log("newVideoUrl", newVideoUrl);
    //     setVideoUrl(newVideoUrl);
    //   });
    // } else {
    setVideoUrl(location?.state?.url);
    // }
  }, []);

  console.log('location?.state', location?.state)

  const [controlsActive, setControlsActive] = useState(false);

  useEffect(() => {
    let debounce;
    if (controlsActive) {
      debounce = setTimeout(() => {
        setControlsActive(false);
      }, 3000);
    }

    return () => {
      if (debounce) clearTimeout(debounce);
    };
  }, [controlsActive]);

  const onDuration = (videoDuration) => {
    const percentagewatched = parseFloat(location.state?.percentagewatched ?? 0);

    if (percentagewatched > 0) {
      const timeToStart = Math.round((percentagewatched / 100) * videoDuration);
      playerRef.current?.seekTo(timeToStart, "seconds");
    }
  };

  return (
    <div
      className={`relative flex flex-col w-full bg-black ${!isMouseMoving ? "cursor-none" : ""}`}
      onMouseMove={() => setControlsActive(true)}
      onMouseOut={() => setControlsActive(false)}
    >
      <button
        onClick={() => {
          updateSlideProgress();
          if (location.state?.returnTo)
            navigate(location.state?.returnTo, { state: location?.state });
          else {
            navigate(-1);
          }
        }}
        className={`${
          controlsActive ? "" : "invisible"
        } transition-all duration-[2500] absolute top-5 right-5 z-50`}
      >
        <MdOutlineCancel color="white" size={"2em"} />
      </button>
      <NavigationConfirmModal blocker={blocker} />
      <div className="w-full flex justify-center">
        {videoUrl && (
          <VideoPlayer
            url={videoUrl}
            onVideoDownload={onVideoDownload}
            playerContainerRef={playerContainerRef}
            playerRef={playerRef}
            muted={muted}
            playing={playing}
            volume={volume}
            handleProgress={handleProgress}
            title={location?.state?.title || ""}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onFastForward={handleFastForward}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            played={played}
            onToggleFullScreen={handleToggleFullScreen}
            onSeek={handleSeekChange}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            fullscreen={fullscreen}
            isMouseMoving={isMouseMoving}
            showDownload={isAuthenticated && !location?.state?.download}
            controlsActive={controlsActive}
            setControlsActive={setControlsActive}
            updateSlideProgress={updateSlideProgress}
            onDuration={onDuration}
          />
        )}
      </div>
      {!fullscreen && (
        <PlayControls
          onVideoDownload={onVideoDownload}
          showDownload={isAuthenticated && !location?.state?.download}
          title={location?.state?.title || ""}
          onPlayPause={handlePlayPause}
          onRewind={handleRewind}
          onFastForward={handleFastForward}
          onVolumeChange={handleVolumeChange}
          onVolumeSeekDown={handleVolumeSeekDown}
          volume={volume}
          muted={muted}
          playing={playing}
          played={played}
          onToggleFullScreen={handleToggleFullScreen}
          onSeek={handleSeekChange}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
          controlsActive={controlsActive}
          setControlsActive={setControlsActive}
        />
      )}
      <LoginHardBlocker enableGoToHome={true} />
    </div>
  );
};

export default VideoPlayerContainer;

const format = (sec) => {
  if (isNaN(sec)) {
    return "00:00";
  }

  const date = new Date(sec * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }

  return `${mm}:${ss}`;
};
