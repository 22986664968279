import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  readVideoAction,
  setLoginHardBlocker,
  userFavAction,
  userUnFavAction,
} from "../../store/Home/HomeAction";

import Navbar from "./Navbar";
import HeaderBar from "./HeaderBar";
import FooterBar from "./FooterBar";
import LoadingSlides from "./LoadingSlides";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { useAuth0 } from "@auth0/auth0-react";
import GotQuestions from "./GotQuestions";
import CommonCarousel from "../home/CommonCarousel";
import Toastiy from "./Toastiy";

const VideoInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { languagePreferred, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );

  const { id: _id } = useParams();

  const [activeTab, setActiveTab] = useState("Book of Jonh");
  const [dataLoading, setDataLoading] = useState(false);

  const [currentVideoData, setCurrentVideoData] = useState({
    thumbnailUrl: "",
    title: "",
    description: "",
    url: "",
    id: "",
    isLikedByCurrentUser: false,
    percentagewatched: 0,
  });

  const [relatedVideos, setRelatedVideos] = useState([]);

  console.log("relatedVideos", relatedVideos);
  console.log("currentVideoData", currentVideoData);

  const handleTab1 = () => {
    setActiveTab("Book of Jonh");
  };

  const handleVideosClick = () => {
    // if (!isAuthenticated) {
    //   dispatch(setLoginHardBlocker(true));
    //   return;
    // }
    navigate("/video-player", {
      state: {
        url: currentVideoData?.url,
        title: currentVideoData?.title,
        _id: currentVideoData?.id,
        category: "videos",
        thumbnailUrl: currentVideoData?.thumbnailUrl,
        percentagewatched: currentVideoData?.percentagewatched
          ? parseFloat(currentVideoData?.percentagewatched)
          : 0,
      },
    });
  };

  useEffect(() => {
    setDataLoading(true);
    dispatch(
      readVideoAction({
        apiPayloadRequest: {
          _id: _id,
        },
        callback: (res) => {
          if (res?.data[0]) {
            setRelatedVideos([]);

            const data = res?.data[0];
            const relatedvideosData = data?.relatedvideos;

            console.log("relatedvideosData", relatedvideosData);

            setCurrentVideoData((prev) => ({
              ...prev,
              thumbnailUrl:
                data?.tumbnailurls?.filter((item) => item.type_of_url === "1")[0]?.url_link || "",
              title: data?.video_title || "",
              id: data?._id || "",
              description: data?.video_description || "",
              url: data?.urls?.filter((item) => item.type_of_url === "1")[0]?.url_link || "",
              isLikedByCurrentUser: data?.isLikedByCurrentUser,
              percentagewatched: data?.pickuplefts?.percentagewatched ?? 0,
            }));

            const checkValidRelatedVideos = relatedvideosData?.find((v) => v?.videoObjId);

            if (checkValidRelatedVideos) {
              relatedvideosData.map((item) => {
                const vidId = item?.videoObjId?._id;
                const thumbnail = item?.videoObjId?.tumbnailurls[0]?.url_link;
                const title = item?.videoObjId?.video_title;

                setRelatedVideos((prev) => [
                  ...prev,
                  {
                    _id: vidId,
                    image: thumbnail,
                    title: title,
                  },
                ]);
              });
            }
          }
          setDataLoading(false);
        },
      })
    );
  }, [_id, languagePreferred]);

  const handleFavourite = () => {
    if (isAuthenticated) {
      const payload = { type: "1", videoObjId: currentVideoData.id };
      if (currentVideoData?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setCurrentVideoData((prev) => ({ ...prev, isLikedByCurrentUser: false }));
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setCurrentVideoData((prev) => ({ ...prev, isLikedByCurrentUser: true }));
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          title1={currentVideoData?.title ? currentVideoData?.title : "Book of John"}
          back={true}
          active={activeTab}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-10">
          <div className="mx-6 mt-6">
            <div className="relative rounded-3xl cursor-pointer overflow-hidden">
              {currentVideoData?.thumbnailUrl ? (
                <img
                  className="w-full h-[80vh] object-cover object-top"
                  src={currentVideoData?.thumbnailUrl}
                  alt="video_thumbnail"
                />
              ) : (
                <div className="w-full h-[80vh] bg-zinc-700 animate-pulse"></div>
              )}
              <div className="absolute top-0 left-0 flex items-end h-full w-full bg-gradient-to-t from-black from-[2%] to-black/0 to-[100%]">
                <div className="flex flex-col justify-end items-start w-full px-[3.5%] py-[3%]">
                  <p className="text-white text-xl md:text-3xl font-bold mb-2 overflow-hidden text-wrap md:text-nowrap text-start overflow-ellipsis">
                    {currentVideoData?.title}
                  </p>
                  <p className="text-white text-base md:text-lg text-opacity-60 text-start">
                    {currentVideoData?.description}
                  </p>
                  {currentVideoData?.title && (
                    <button
                      onClick={handleVideosClick}
                      className="border border-yellowish hover:bg-yellowish hover:text-black px-[13%] py-2 lg:py-[10px] md:py-[8px] rounded-md text-yellowish font-semibold lg:text-base md:text-xs mb-1 mt-5"
                    >
                      {currentLanguageTitles?.[0]?.home_meta?.watchnow ?? "Watch Now"}
                    </button>
                  )}
                </div>
                {currentVideoData?.isLikedByCurrentUser ? (
                  <FaHeart
                    className="absolute bottom-6 md:bottom-12 right-6 md:right-12 text-3xl text-yellowish cursor-pointer"
                    onClick={handleFavourite}
                  />
                ) : (
                  <FaRegHeart
                    className="absolute bottom-6 md:bottom-12 right-6 md:right-12 text-3xl text-yellowish cursor-pointer"
                    onClick={handleFavourite}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="px-6">
            {dataLoading ? (
              <LoadingSlides />
            ) : relatedVideos?.length > 0 ? (
              <CommonCarousel
                seeAllValue={true}
                title={"Related Videos"}
                linkTo={"video"}
                imageType={"landscape"}
                showItemTitle={true}
                data={relatedVideos}
                perViewImages={4}
              />
            ) : null}
          </div>
          <GotQuestions />
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default VideoInfo;
