import * as actionTypes from "../ActionTypes";

export const getCategoriesAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_READ_REQUEST,
    payload: payload,
  };
};

export const saveCategoriesTestimonyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_SAVE_REQUEST,
    payload: payload,
  };
};

export const updateCategoriesTestimonyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_UPDATE_REQUEST,
    payload: payload,
  };
};

export const readLayoutsAction = (payload) => {
  return {
    type: actionTypes.READ_LAYOUTS_REQUEST,
    payload: payload,
  };
};

export const deleteCategoriesTestimonyAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_TESTIMONY_DELETE_REQUEST,
    payload: payload,
  };
};
