import React from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import GiveImg from "../../assets/images/GiveImg.png";
import FooterBar from "../../components/common/FooterBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Givings = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  return (
    <div className="flex w-full md:flex-row flex-col overflow-hidden">
      <Navbar active={"Givings"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar popCancle={true} />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
          <div className="w-full p-8">
            <div className="flex justify-center items-center rounded-3xl bg-[#EFBC51] h-[60vh]">
              <img src={GiveImg} alt="giving" />
            </div>
            {currentLanguageTitles?.[0]?.givings?.give_desc_lbl ? (
              <p className="pt-12 text-white text-lg opacity-50 text-center">
                {currentLanguageTitles?.[0]?.givings?.give_desc_lbl}
              </p>
            ) : (
              <>
                <p className="pt-12 text-white text-lg opacity-50 text-center">
                  We give because God first gave. Your support help us take the Holy unedited Word
                  of God to the millions who need to hear the Good News of our Lord Jesus! Giving is
                  our part, multiplication isHis, we give our 5 loaves and 2 fishes to the Master
                  and He multiples it.
                </p>
                <p className="py-6 text-white text-lg opacity-50 text-center">
                  Thank you for the willingness to be a part of his calling.
                </p>
              </>
            )}
            <div className="py-6 flex w-full justify-center">
              <a
                className="p-2 rounded-md w-[300px] text-[15px] text-center font-semibold border border-yellow-400 text-yellow-400"
                // onClick={() => navigate("/givings/comp")}
                href="https://crosstalkinternational.com/donate"
                target="_blank"
                rel="noreferrer"
              >
                {currentLanguageTitles?.[0]?.givings?.give_btn ?? "Give now"}
              </a>
            </div>
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default Givings;
