import { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import award from "../../assets/images/certificate.png";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import certificateTemplate from "../../assets/images/certificate_template.jpg";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";

const AchievementsCarousel = ({ data, perViewImages = 5, user }) => {
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (0.2 + perViewImages / 10);
  const [currentItem, setCurrentItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const { userProfileDetails } = useSelector((state) => state.languageReducer.data);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001,
      padding: 0, // Adjust this value as needed
    },
  };

  // const generatePDF = () => {
  //   const input = pdfContentRef.current;

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "PNG", 0, 0);
  //     pdf.save("download.pdf");
  //   });
  // };

  const getCertificateTemplate = (title, name) => {
    return (
      <div className="w-[792px] relative">
        <img src={certificateTemplate} alt="certificate template" className="w-full" />
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style={{ wordSpacing: "4px" }}
        >
          <p className="w-full text-center">{title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style={{ wordSpacing: "5px" }}
        >
          <p className="w-full text-center">{name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>
    );
  };

  const generatePDFDocument = (title) => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(
      ReactDOMServer.renderToStaticMarkup(
        getCertificateTemplate(
          title + " Curriculum",
          `${userProfileDetails?.firstName} ${userProfileDetails?.lastName}` ?? ""
        )
      ),
      {
        callback: async function (doc) {
          doc.save("Certificate" + new Date().getTime());
        },
        margin: [0, 0, 0, 0],
        width: 792,
        windowWidth: 792,
      }
    );
  };

  return (
    <div className={"mt-4"}>
      <Carousel
        className="p-1"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div key={index} className={"gap-[1%] flex w-[100%]"}>
            {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <div
                onClick={() => {
                  setCurrentItem(item);
                  setShowModal(true);
                }}
                style={{
                  width: widthOfImage + "%",
                }}
                className={`cursor-pointer bg-no-repeat bg-cover bg-top rounded-lg aspect-video bg-black flex flex-col justify-center items-center`}
              >
                <div
                  style={{ backgroundImage: `url('${award}')` }}
                  className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
                ></div>
                <p className="text-[10px] text-yellowish">
                  {currentLanguageTitles?.[0]?.curricullum_meta?.congratulations_lbl ??
                    "Congratulations"}
                </p>
                <p className="text-xs text-white">
                  {item.title?.length < 20 ? item.title : item.title.substring(0, 20) + ".."}
                </p>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
      <ReactModal
        style={customStyles}
        isOpen={showModal}
        className={"overflow-hidden"}
        onRequestClose={() => setShowModal(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <button className="absolute top-5 right-5" onClick={() => setShowModal(false)}>
            <MdCancel color="#EFBC51" size={30} />
          </button>
          <div className="aspect-video rounded-2xl bg-black h-1/2 flex justify-center items-center flex-col">
            <div
              style={{ backgroundImage: `url('${award}')` }}
              className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
            ></div>
            <p className="text-xl text-yellowish">
              {" "}
              {currentLanguageTitles?.[0]?.curricullum_meta?.congratulations_lbl ??
                "Congratulations"}
            </p>
            <p className="text-2xl text-white">
              {currentItem?.title?.length < 20
                ? currentItem?.title
                : currentItem?.title?.substring(0, 20) + ".."}
            </p>
          </div>
          <button
            // onClick={generatePDF}
            onClick={() => generatePDFDocument(currentItem?.title)}
            className="bg-yellowish px-6 py-4 text-xl font-semibold rounded-xl"
          >
            {currentLanguageTitles?.[0]?.curricullum_meta?.download_certificate_lbl ??
              "Download Certificate"}
          </button>
        </div>
        <div
          className="flex flex-col justify-start items-center "
          ref={pdfContentRef}
          style={{
            width: "595.28px",
            height: "842px",
            color: "black",
            background: "white",
          }}
        >
          <p className="font-bold text-2xl mb-4">
            {currentLanguageTitles?.[0]?.curricullum_meta?.hello_lbl ?? "Hello"} {user?.firstName}{" "}
            {user?.lastName}
          </p>
          <p>
            {" "}
            {currentLanguageTitles?.[0]?.curricullum_meta?.congratulation_success_lbl ??
              "Congratulations on successfully completion of"}{" "}
            {currentItem?.title}{" "}
          </p>
        </div>
      </ReactModal>
    </div>
  );
};

export default AchievementsCarousel;
