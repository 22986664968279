export const CircularCheckbox = ({
  label,
  value,
  name,
  checked,
  onChange,
  borderColor,
  backgroundColor,
}) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        value={value}
        name={name ?? ""}
        checked={checked}
        onChange={onChange}
      />
      <span
        style={{ borderColor: borderColor }}
        className="w-5 h-5 rounded-full border-2  flex items-center justify-center transition duration-300 ease-in-out !ml-0"
      >
        {checked && (
          <span
            style={{ backgroundColor: backgroundColor }}
            className="w-2 h-2 rounded-full "
          ></span>
        )}
      </span>
      <span className="text-white">{label}</span>
    </label>
  );
};
