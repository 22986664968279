import * as actionTypes from "../ActionTypes";

export const testimonialSaveAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_SAVE_REQUEST,
  payload,
});

export const testimonialReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_REQUEST,
  payload,
});

export const testimonyOfTheDayReadAction = (payload) => ({
  type: actionTypes.TESTIMONY_OF_THE_DAY_READ_REQUEST,
  payload,
});

export const testimonialTrendingReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_TRENDING_REQUEST,
  payload,
});

export const testimonialLikedReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_LIKED_REQUEST,
  payload,
});

export const testimonialByCategoryReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_BY_CATEGORY_REQUEST,
  payload,
});

export const testimonialInReviewReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_INREVIEW_REQUEST,
  payload,
});

export const testimonialApprovedReadAction = (payload) => ({
  type: actionTypes.TESTIMONIAL_READ_APPROVED_REQUEST,
  payload,
});

export const deleteTestimonyAction = (payload) => {
  return {
    type: actionTypes.TESTIMONY_DELETE_REQUEST,
    payload: payload,
  };
};
