import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import FooterBar from "../../components/common/FooterBar";
import TopCarousel from "../../components/home/TopCarousel";
import SimpleSlider from "../../components/home/SimpleSlider";
import SmallCarousel from "../../components/home/SmallCarousel";
import Top10Carousel from "../../components/home/Top10Carousel";
import BackgroundCarousel from "../../components/home/BackgroundCarousel";
import TopSwiper from "../../components/home/TopSwiper";
import BottomSwiper from "../../components/home/BottomSwiper";
import TopVideo from "../../components/home/TopVideo";

import { useDispatch, useSelector } from "react-redux";
import {
  getPickWhereLeftAction,
  getPromiseOfTheDayAction,
  layoutHomeScreenAction,
  layoutMaincarouselAction,
  layoutTop10Action,
  readPromiseAction,
  setLoginHardBlocker,
  userFavAction,
  userUnFavAction,
} from "../../store/Home/HomeAction";
import HomeMainCarousel from "../../components/home/HomeMainCarousel";
import MobileAppDownload from "../../components/common/MobileAppDownload";
import ReactAudioPlayer from "react-audio-player";
import { useAuth0 } from "@auth0/auth0-react";
import CommonCarousel from "../../components/home/CommonCarousel";
import LongCarousel from "../../components/home/LongCarousel";
import ResponsiveCarousel from "../../components/home/ResponsiveCarousel";
import SlickCarousel from "../../components/home/SlickCarousel";
import {
  testimonialReadAction,
  testimonyOfTheDayReadAction,
} from "../../store/Testimonial/TestimonialAction";
import { useNavigate, useParams } from "react-router-dom";
import HomeCarousel from "../../components/home/HomeCarousel";
import LoadingSlides from "../../components/common/LoadingSlides";
import PromiseCarousel from "../../components/home/PromiseCarousel";
import TopSwiper2 from "../../components/testimonials/TopSwiper2";
import TransformCarousel from "../../components/home/TransformCarousel";
import HomeCurriculumCarousel from "../../components/home/HomeCurriculumCarousel";
import {
  curriculumHomeReadAction,
  curriculumReadAction,
} from "../../store/Curriculam/CurriculumAction";
import HomePromise from "../../components/home/HomePromise";
import CoverEffectCarousel from "../../components/home/CoverEffectCarousel";
import HomePromiseNew from "../../components/home/HomePromiseNew";
import HomeTestimony from "../../components/home/HomeTestimony";
import HomeSupportUs from "../../components/home/HomeSupportUs";
import HomeNeedPrayer from "../../components/home/NeedPrayer/HomeNeedPrayer";
import {
  addCategory,
  addCurriculum,
  addTestimonial,
  addVideos,
} from "../../store/Common/CommonAction";
import { removeDuplicacyById } from "../../utils/Utils";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import Toastiy from "../../components/common/Toastiy";
import { useLocation } from "react-router-dom/dist";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { isMobile } = useSelector((state) => state.common);
  const { languagePreferred, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );
  const [layoutLoading, setLayoutLoading] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      navigate("/");
    }
  }, [location]);

  const initialTabs = ["Welcome", "Today's Devotional"];
  const initialSeeAll = "See All";

  const [tabValues, setTabsValues] = useState(initialTabs);
  console.log("tabValues1", tabValues);
  console.log("location.state1", location?.state);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");

  const [homeData, setHomeData] = useState([]);
  const [curriculumData, setCurriculumData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [promiseOfTheDay, setPromiseOfTheDay] = useState();
  const [currentPromiseOfTheDay, setCurrentPromiseOfTheDay] = useState();
  const [promises, setPromises] = useState([]);
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));
  const [maincarousel, setMaincarousel] = useState([]);
  const [top10, setTop10] = useState([]);
  const [testimonyOfTheDay, setTestimonyOfTheDay] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [pickWhereLeft, setPickWhereLeft] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [twgReadWordsModal, setTwgReadWordsModal] = useState(false);

  console.log("top10", top10);
  console.log("promises", promises);
  console.log("promiseOfTheDay", promiseOfTheDay);
  console.log("currentPromiseOfTheDay", currentPromiseOfTheDay);
  console.log("maincarousel", maincarousel);
  console.log("pickWhereLeft", pickWhereLeft);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (location?.state?.backTo && location.state.backTo === "TWG") {
      handleTabChange(1);
      navigate(location?.pathname);
    }
  }, []);

  const restructureData = (data, isCategory = null) => {
    return (
      data?.map((item) => ({
        _id: item?._id,
        name: item?.curriculum_name ?? item?.name,
        image: item?.url,
        title: item?.testimonial_name ?? item?.curriculum_name ?? item?.name ?? item?.title,
        subtitle: item?.curriculum_description ?? item?.subtitle,
        type: isCategory === "1" ? "category" : item?.type,
        video: item?.videoUrl ?? "",
      })) || []
    );
  };

  const restructureDataMain = (data) => {
    return (
      data?.map((item) => ({
        _id: item._id,
        image: item?.url,
        title: item?.name,
        subtitle: item?.subtitle,
        type: item?.type,
        video: item?.videoUrl ?? "",
      })) || []
    );
  };

  const restructureTestimonialData = (data) => {
    return (
      data?.map((item) => ({
        _id: item._id,
        image: item?.testomonial_rec_tumbnailUrl,
        title: item?.testimonial_name,
        subtitle: item?.subtitle,
      })) || []
    );
  };

  const handleFavourite = (item) => {
    if (isAuthenticated) {
      const payload = { type: "4", testimonyObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: false } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: true } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  console.log("homeData", homeData);

  const getComponents = () => {
    // const cData = [...curriculumData];
    let hData = [...homeData.sort((a, b) => Number(a?.order) - Number(b?.order))];

    console.log("hData", hData);

    hData = (hData || []).map(({ order, item }) => {
      let currData = {};
      let lessData = [];
      if (item?.mobile_layout === "7") {
        currData = item?.itemstoDisplay?.find((d) => d?.type === "curriculum");
        lessData = item?.itemstoDisplay?.filter((d) => d?.type === "lesson");
      }
      return {
        order: parseInt(order),
        component:
          item?.mobile_layout === "2" ? (
            <SlickCarousel
              height={height}
              width={width}
              title={item?.layout_name}
              data={restructureData(item?.itemstoDisplay, item?.isCategory)}
              seeAllRoute={item?.isCategory === "1" ? `/categoryInfo/${item?._id}` : null}
            />
          ) : item?.mobile_layout === "3" ? (
            <CoverEffectCarousel
              height={height}
              width={width}
              title={item?.layout_name}
              data={restructureData(item?.itemstoDisplay, item?.isCategory)}
              seeAllRoute={item?.isCategory === "1" ? `/categoryInfo/${item?._id}` : null}
            />
          ) : item?.mobile_layout === "7" ? (
            <HomeCurriculumCarousel
              id={currData?._id}
              allowHoverAnimation={true}
              title={currData?.name}
              description={currData?.subtitle}
              bgImage={currData?.url}
              preview={currData?.videoUrl}
              data={lessData?.map((item) => ({
                _id: item?._id,
                title: item?.title,
                subtitle: item?.subtitle,
                image: item?.url,
                video: item?.videoUrl,
                type: "lesson",
              }))}
            />
          ) : (
            <div className="px-5 md:px-[1%]">
              <CommonCarousel
                seeAllValue={seeAllBtn}
                title={item?.layout_name}
                linkTo={""}
                dynamiclinkTo={true}
                seeAllRoute={item?.isCategory === "1" ? `/categoryInfo/${item?._id}` : null}
                imageType={"landscape"}
                showItemTitle={true}
                data={restructureData(item?.itemstoDisplay, item?.isCategory)}
                perViewImages={isMobile ? 2 : 4}
              />
            </div>
          ),
      };
    });

    const allData = hData?.length > 0 ? hData : [];
    // // Determine the maximum length of the two arrays
    // const maxLength = Math.max(cData.length, hData.length);

    // // Interleave elements from cData and hData arrays
    // for (let i = 0; i < maxLength; i++) {
    //   if (i < hData.length) {
    //     allData.push(hData[i]);
    //   }
    //   if (i < cData.length) {
    //     allData.push(cData[i]);
    //   }
    // }

    return (
      allData
        // .sort((a, b) => a.order - b.order)
        .map((item) => item.component)
    );
  };

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE_URL,
      });

      setCheckToken(token);
      return token;
    } catch (error) {
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    setHomeData([]);
    setCurriculumData([]);

    let videosList = [];
    let categoriesList = [];
    let curriculumList = [];
    let testimonialsList = [];

    setLayoutLoading(true);

    const homeScreenActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutHomeScreenAction({
          callback: (res) => {
            const data = res?.data;
            data?.forEach((layoutItem) => {
              const restructuredData = restructureData(layoutItem?.itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
            });

            const requiredData = (data || []).map((item) => ({
              order: parseInt(item?.order),
              item: item,
            }));

            setHomeData((prev) => [...prev, ...requiredData]);
            resolve();
          },
        })
      );
    });

    // const curriculumActionPromise = new Promise((resolve, reject) => {
    //   dispatch(
    //     curriculumReadAction({
    //       callback: (res) => {
    //         if (res?.type === 1) {
    //           const restructuredData = restructureData(res?.data);
    //           restructuredData?.forEach((item) => {
    //             curriculumList.push(item);
    //           });

    //           const curriculumDetails = res?.data?.map((curriculum) => {
    //             return {
    //               component: (
    //                 <HomeCurriculumCarousel
    //                   id={curriculum?._id}
    //                   allowHoverAnimation={true}
    //                   title={curriculum?.curriculum_name}
    //                   description={curriculum?.curriculum_description}
    //                   bgImage={
    //                     curriculum?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]?.url_link
    //                   }
    //                   preview={
    //                     curriculum?.trailers?.filter((i) => i?.type_of_url === "1")[0]?.url_link
    //                   }
    //                   data={curriculum?.lessons?.map((item) => ({
    //                     _id: curriculum?._id,
    //                     title: item?.lesson_title,
    //                     subtitle: item?.lesson_title,
    //                     image:
    //                       item?.lesson_tumbnail_urls?.find((i) => i?.type_of_url === "1")
    //                         ?.url_link ?? "",
    //                     video:
    //                       item?.lesson_trailers?.find((i) => i?.type_of_url === "1")?.url_link ??
    //                       "",
    //                   }))}
    //                 />
    //               ),
    //             };
    //           });
    //           setCurriculumData(curriculumDetails);
    //           resolve();
    //         }
    //       },
    //     })
    //   );
    // });

    const promiseOfTheDayActionPromise = new Promise((resolve, reject) => {
      dispatch(
        getPromiseOfTheDayAction({
          callback: (res) => {
            if (res?.type === 1) {
              setPromiseOfTheDay(res?.data[0]);
            }
            resolve();
          },
        })
      );
    });

    const promiseActionPromise = new Promise((resolve, reject) => {
      dispatch(
        readPromiseAction({
          callback: (res) => {
            if (res?.type === 1) {
              const promiseList = res?.data?.map((item) => ({
                ...item,
                _id: item?._id,
                title: item?.twg_title,
                name: item?.twg_title,
                hearGodsWord: item?.hear_gods_word,
                readGodsWord: item?.read_gods_word,
                subtitle: item?.read_gods_word,
                image: item?.video_Tumbnails?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                audio: item?.audios?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                video: item?.videos?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
                type: "promise",
              }));
              promiseList.sort((a, b) => new Date(b.date) - new Date(a.date));
              setPromises(promiseList);
              setCurrentPromiseOfTheDay(promiseList?.length > 0 ? promiseList[0] : {});
            }
            resolve();
          },
        })
      );
    });

    const testimonialActionPromise = new Promise((resolve, reject) => {
      dispatch(
        testimonialReadAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data);
              restructuredData?.forEach((item) => {
                testimonialsList.push(item);
              });
              res?.data
                ?.filter((item) => item.approval_status === 1)
                ?.map((item) => {
                  setTestimonials((prev) => [
                    ...prev,
                    {
                      _id: item?._id,
                      userName: item?.user[0]?.firstName,
                      profilePic: item?.user[0]?.profilePicUrl,
                      title: item?.testimonial_name,
                      subtitle: item?.testimonial_description,
                      image: item?.testomonial_rec_tumbnailUrl,
                      video: item?.testomonial_rec_videoUrl,
                      isLikedByCurrentUser: item?.isLikedByCurrentUser ?? false,
                      totallikes: item?.totallikes ?? false,
                      type: "testmonial",
                    },
                  ]);
                });
            }
            resolve();
          },
        })
      );
    });

    const layoutMaincarouselActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutMaincarouselAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data[0].itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
              setMaincarousel(
                res?.data[0].itemstoDisplay?.sort((a, b) => Number(a?.order) - Number(b?.order))
              );
            }
            resolve();
          },
        })
      );
    });

    const layoutTop10ActionPromise = new Promise((resolve, reject) => {
      dispatch(
        layoutTop10Action({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data?.[0]?.itemstoDisplay);
              restructuredData?.forEach((item) => {
                if (item?.type === "video" || item?.type === "videos") {
                  videosList.push(item);
                } else if (item?.type === "category") {
                  categoriesList.push(item);
                } else if (item?.type === "curriculum") {
                  curriculumList.push(item);
                } else if (item?.type === "testimonial") {
                  testimonialsList.push(item);
                }
              });
              setTop10(
                res?.data?.[0]?.itemstoDisplay
                  ?.sort((a, b) => Number(a?.order) - Number(b?.order))
                  ?.slice(0, 10)
                  ?.map((item) => {
                    return {
                      _id: item?._id,
                      title: item?.name,
                      subtitle: item?.subtitle,
                      image: item?.url,
                      type: item?.type,
                      video: item?.videoUrl ?? "",
                    };
                  })
              );
            }
            resolve();
          },
        })
      );
    });

    const testimonyOfTheDayActionPromise = new Promise((resolve, reject) => {
      dispatch(
        testimonyOfTheDayReadAction({
          callback: (res) => {
            if (res?.type === 1) {
              const restructuredData = restructureData(res?.data);
              restructuredData?.forEach((item) => {
                testimonialsList.push(item);
              });
              setTestimonyOfTheDay(res.data);
            }
            resolve();
          },
        })
      );
    });

    homeScreenActionPromise
      ?.then(() => {
        setLayoutLoading(false);
      })
      .catch(() => {
        setLayoutLoading(false);
      });

    Promise.all([
      homeScreenActionPromise,
      // curriculumActionPromise,
      promiseOfTheDayActionPromise,
      promiseActionPromise,
      testimonialActionPromise,
      layoutMaincarouselActionPromise,
      layoutTop10ActionPromise,
      testimonyOfTheDayActionPromise,
    ]).then(() => {
      dispatch(addCategory(removeDuplicacyById(categoriesList)));
      dispatch(addVideos(removeDuplicacyById(videosList)));
      dispatch(addCurriculum(removeDuplicacyById(curriculumList)));
      dispatch(addTestimonial(removeDuplicacyById(testimonialsList)));
    });
  }, [isMobile, languagePreferred]);

  useEffect(() => {
    if (checkToken !== null && currentCode !== "") {
      dispatch(
        getPickWhereLeftAction({
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0]?.pickuplefts;
              data?.map((item) => {
                if (item?.categoryDetails?.length > 0) {
                  const currentData = item?.categoryDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.icon_images.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.category_name,
                      subtitle: currentData?.category_description,
                      video: currentData?.videoUrl ?? "",
                      type: "category",
                    },
                  ]);
                }

                if (item?.curriculumDetails?.length > 0) {
                  const currentData = item?.curriculumDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.curriculum_name,
                      subtitle: currentData?.curriculum_name,
                      video: currentData?.videoUrl ?? "",
                      type: "curriculum",
                    },
                  ]);
                }

                if (item?.videoDetails?.length > 0) {
                  const currentData = item?.videoDetails[0];
                  setPickWhereLeft((prev) => [
                    ...prev,
                    {
                      _id: currentData?._id,
                      percentage: item?.percentagewatched,
                      image: currentData?.tumbnailurls?.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link,
                      title: currentData?.video_title,
                      subtitle: currentData?.video_title,
                      video: currentData?.videoUrl ?? "",
                      type: "video",
                    },
                  ]);
                }
              });
            }
          },
        })
      );
    }
  }, [checkToken, currentCode, isMobile]);

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        if (checkToken === null) {
          const token = await getToken();
          localStorage.setItem("token", token);
        }
      }
    };

    try {
      fetchToken();
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated, checkToken]);

  return (
    <>
      <div className="flex">
        <div className="flex w-full md:flex-row flex-col overflow-hidden">
          <Navbar active={"Home"} />
          <div className="md:ml-[104px] flex flex-col w-full">
            <HeaderBar
              popCancle={true}
              tabValues={tabValues}
              active={activeTab}
              absolute={false}
              handleTabChange={handleTabChange}
              getLanguagePreferred={(code) => {
                setCurrectCode(code);
              }}
              getCurrentLanguageTitles={(titles) => {
                if (titles?.home_meta) {
                  const requiredLangData = titles?.home_meta;
                  setTabsValues([requiredLangData.welcome_lbl, requiredLangData.todaywithgod_lbl]);

                  setSeeAllBtn(requiredLangData.seeall_btn);
                } else {
                  setTabsValues(initialTabs);
                  setSeeAllBtn(initialSeeAll);
                }
              }}
            />
            <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
              {activeTab === 0 ? (
                <>
                  {maincarousel && maincarousel?.length > 0 ? (
                    <HomeCarousel data={restructureDataMain(maincarousel)} width={width} />
                  ) : (
                    <div className="p-6">
                      <div className="w-[100%] h-[75vh] animate-pulse bg-zinc-600 rounded-2xl"></div>
                      <div className="p-4 flex justify-center">
                        <div className="w-[100px] h-[10px] animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </div>
                    </div>
                  )}
                  {top10 && top10?.length > 0 && (
                    <Top10Carousel
                      perViewImages={isMobile ? 2 : 4}
                      data={top10}
                      seeAllValue={seeAllBtn}
                      title={currentLanguageTitles?.[0]?.home_meta?.top10_lbl ?? "Top 10"}
                    />
                  )}
                  {pickWhereLeft && pickWhereLeft?.length > 0 && (
                    <div className="px-4">
                      <CommonCarousel
                        seeAllValue={seeAllBtn}
                        title={
                          currentLanguageTitles?.[0]?.home_meta?.pick_where_you_are_left_lbl ??
                          "Pickup Where You Left"
                        }
                        imageType={"landscape"}
                        linkTo={""}
                        showProgress={true}
                        data={pickWhereLeft}
                        dynamiclinkTo={true}
                        perViewImages={isMobile ? 2 : 5}
                      />
                    </div>
                  )}
                  {!layoutLoading ? (
                    homeData && homeData?.length > 0 && getComponents()
                  ) : (
                    <div className="w-full px-6 flex flex-col gap-6">
                      <LoadingSlides />
                      <LoadingSlides />
                      <LoadingSlides />
                    </div>
                  )}
                  {testimonyOfTheDay?.length > 0 && (
                    <HomeTestimony
                      title={
                        currentLanguageTitles?.[0]?.home_meta?.testimony_videos_lbl ??
                        "Testimonial Videos"
                      }
                      autoplay={true}
                      perViewImages={isMobile ? 2 : 4}
                      testimonials={testimonials}
                      showBookmark={true}
                      handleFavourite={handleFavourite}
                    />
                  )}
                  {promiseOfTheDay && (
                    <div className="p-6 flex flex-col justify-center items-center">
                      <HomePromiseNew
                        bgImage={
                          promiseOfTheDay?.hwg_Image?.filter((i) => i?.type_of_url === "1")[0]
                            ?.url_link
                        }
                        title={promiseOfTheDay?.twg_title}
                        hearGodsWord={promiseOfTheDay?.hear_gods_word}
                        readGodsWord={promiseOfTheDay?.read_gods_word}
                        audioFile={
                          promiseOfTheDay?.audios?.filter((i) => i?.type_of_url === "1")[0]
                            ?.url_link
                        }
                        promises={promises}
                      />
                    </div>
                  )}
                  <div className="grid md:grid-cols-2 gap-10 p-6">
                    <HomeSupportUs />
                    <HomeNeedPrayer />
                  </div>
                </>
              ) : (
                <>
                  {promises?.length > 0 && (
                    <CoverEffectCarousel
                      height={height}
                      width={width}
                      seeAllValue={seeAllBtn}
                      data={promises}
                      onItemChange={(index, swiper) => setCurrentPromiseOfTheDay(promises[index])}
                      className="!pt-0"
                      backTo={"TWG"}
                    />
                  )}
                  <TopVideo
                    background={
                      currentPromiseOfTheDay?.video_Tumbnails?.filter(
                        (i) => i?.type_of_url === "1"
                      )[0]?.url_link
                    }
                    url={
                      currentPromiseOfTheDay?.videos?.filter((i) => i?.type_of_url === "1")[0]
                        ?.url_link
                    }
                  />
                  <p className="px-6 pt-6 text-lg font-semibold text-white">
                    {currentLanguageTitles?.[0]?.promises_meta?.hear_godsword_lbl ??
                      "Hear Gods Word"}
                  </p>
                  <div className="m-6 flex flex-col md:flex-row">
                    {currentPromiseOfTheDay?.hwg_Image?.filter((i) => i?.type_of_url === "1")[0]
                      ?.url_link ? (
                      <img
                        className="md:w-[30%] h-[35vh] object-cover object-top rounded-lg mb-3 md:mb-0 bg-yellowish/80"
                        src={
                          currentPromiseOfTheDay?.hwg_Image?.filter(
                            (i) => i?.type_of_url === "1"
                          )[0]?.url_link
                        }
                        alt="thumbnail not found"
                      />
                    ) : (
                      <div className="md:w-[30%] h-[35vh] object-cover object-top rounded-lg mb-3 md:mb-0 bg-yellowish/80"></div>
                    )}

                    <div className="flex flex-col gap-3 justify-between md:px-6 md:w-[70%]">
                      <p className="text-base md:text-xl text-white opacity-50">
                        {currentPromiseOfTheDay?.hear_gods_word}
                      </p>
                      <div className="flex flex-col gap-4 pt-6 mb-6">
                        <p className="text-yellow-400">
                          {currentLanguageTitles?.[0]?.promises_meta?.hear_godsword_lbl ??
                            "Hear Gods Word"}
                        </p>
                        <audio controls className="w-full">
                          <source
                            src={
                              currentPromiseOfTheDay?.audios?.filter(
                                (i) => i?.type_of_url === "1"
                              )[0]?.url_link
                            }
                          />
                        </audio>
                      </div>
                    </div>
                  </div>
                  <p className="px-6 pt-6 text-lg font-semibold text-white">
                    {currentLanguageTitles?.[0]?.promises_meta?.read_godsword_lbl ??
                      "Read Gods Word"}
                  </p>
                  <div className="px-6">
                    <div
                      className="mt-6 relative bg-no-repeat w-full h-[80vh] rounded-xl bg overflow-hidden bg-cover bg-top bg-yellowish/60"
                      style={{
                        backgroundImage: `url('${
                          currentPromiseOfTheDay?.bg_Images?.filter(
                            (i) => i?.type_of_url === "1"
                          )[0]?.url_link
                        }')`,
                      }}
                    >
                      <div className="absolute w-full h-full bg-gradient-to-r from-black to-transparent"></div>
                      <div
                        className=" flex w-full h-full justify-center items-center p-[5%] 
                      "
                      >
                        <p className="text-white text-base md:text-3xl opacity-60">
                          {currentPromiseOfTheDay?.read_gods_word?.length < 150 ? (
                            currentPromiseOfTheDay?.read_gods_word
                          ) : (
                            <>
                              {currentPromiseOfTheDay?.read_gods_word?.substring(0, 300) + ".."}{" "}
                              <span
                                className="text-yellowish cursor-pointer"
                                onClick={() => {
                                  setTwgReadWordsModal(true);
                                }}
                              >
                                {currentLanguageTitles?.[0]?.home_meta?.readnow ?? "more"}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <ReactModal
                isOpen={twgReadWordsModal}
                className={
                  "flex justify-center items-center h-[100vh] z-[100] overflow-hidden bg-black bg-opacity-90 relative "
                }
                onRequestClose={() => setTwgReadWordsModal(false)}
              >
                <div className="w-full h-screen bg-blackish flex justify-center items-center flex-col gap-6 relative">
                  <button
                    className="absolute top-5 right-5"
                    onClick={() => setTwgReadWordsModal(false)}
                  >
                    <MdCancel color="#EFBC51" size={30} />
                  </button>
                  <div
                    className="mt-6 relative bg-no-repeat w-9/12 h-[80vh] rounded-xl overflow-y-auto no-scrollbar bg-cover bg-top bg-yellowish/60"
                    style={{
                      backgroundImage: `url('${
                        currentPromiseOfTheDay?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link
                      }')`,
                    }}
                  >
                    <div className="absolute w-full h-full bg-gradient-to-r from-black to-transparent"></div>
                    <div
                      className=" flex w-full h-full justify-center items-center p-[5%] 
                      "
                    >
                      <p className="text-white text-base md:text-3xl opacity-60">
                        {currentPromiseOfTheDay?.read_gods_word}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactModal>
              <FooterBar />
            </div>
          </div>
        </div>
      </div>
      {/* <MobileAppDownload /> */}
    </>
  );
};

export default HomePage;
