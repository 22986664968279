import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import Language from "../../assets/icons/language.svg";
import Avatar from "../../assets/icons/UserAvatar.svg";
import Back from "../../assets/icons/Back.svg";
import Cross from "../../assets/icons/Cross.svg";
import CheckTick from "../../assets/icons/Check-Tick.svg";
import CheckBox from "../../assets/icons/Check-Box.svg";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLanguageListAction,
  getProfileAction,
  languageCurrentAction,
  languageDataAction,
  languageListAction,
  languagePreferredAction,
  readLanguageAction,
  setUserProfileDetailsAction,
  updateUserProfileAction,
} from "../../store/Home/HomeAction";
import Toastiy from "./Toastiy";
import LoginHardBlocker from "./LoginHardBlocker";

const HeaderBar = ({
  active,
  back,
  className,
  backLink,
  cancelBtn = false,
  cancelBtnHandle,
  enableBack = false,
  enableGoToHome = false,
  small,
  absolute,
  tabValues = [],
  handleTabChange = () => {},
  getLanguagePreferred = () => {},
  getCurrentLanguageTitles = () => {},
  popCancle = false,
}) => {
  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001, // Adjust this value as needed
    },
  };
  const checkToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const {
    languagePreferred,
    languageHeadings,
    languageList,
    currentLanguageTitles,
    userProfileDetails,
  } = useSelector((state) => state.languageReducer.data);
  const [showMenu, setShowMenu] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [showModal, setShowModal] = useState(!popCancle);
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  const onBack = () => {
    // navigate(-1);
    const previousPath = document.referrer ? new URL(document.referrer).pathname : "/";
    navigate(previousPath);
  };

  console.log("languageHeadings12", languageHeadings);
  console.log("currentLanguageTitles", currentLanguageTitles);
  console.log("languagePreferred", languagePreferred);

  const changeLanguageHandle = (code) => {
    console.log("code", code);
    if (checkToken) {
      dispatch(
        updateUserProfileAction({
          apiPayloadRequest: {
            language_prefference: code,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const currentLanguage = languageHeadings.filter(
                (item) => item.language_code === code
              );
              dispatch(languageCurrentAction(currentLanguage));

              getCurrentLanguageTitles(currentLanguage[0]);

              localStorage.setItem("language_preferred", code);

              dispatch(languagePreferredAction(code));
              getLanguagePreferred(code);
              setShowLang((preState) => !preState);
              Toastiy("Language Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      const currentLanguage = languageHeadings.filter((item) => item.language_code === code);
      dispatch(languageCurrentAction(currentLanguage));

      getCurrentLanguageTitles(currentLanguage[0]);

      localStorage.setItem("language_preferred", code);

      dispatch(languagePreferredAction(code));
      setShowLang((preState) => !preState);
    }
  };

  useEffect(() => {
    if (languageHeadings?.length === 0) {
      dispatch(
        readLanguageAction({
          callback: (res) => {
            dispatch(languageDataAction(res?.data));
          },
        })
      );
    }

    if (languagePreferred && languageHeadings?.length > 0) {
      if (currentLanguageTitles?.length === 0) {
        const currentLanguage = languageHeadings?.filter(
          (item) => item.language_code === languagePreferred
        );
        dispatch(languageCurrentAction(currentLanguage));
        getCurrentLanguageTitles(currentLanguage[0]);
      }
    }
  }, [languagePreferred, languageHeadings, currentLanguageTitles]);

  useEffect(() => {
    if (currentLanguageTitles.length > 0 && languagePreferred) {
      getLanguagePreferred(languagePreferred);
      getCurrentLanguageTitles(currentLanguageTitles[0]);
    }
    dispatch(
      getLanguageListAction({
        callback: (res) => {
          dispatch(languageListAction(res?.data));
        },
      })
    );
  }, []);

  useEffect(() => {
    if (checkToken) {
      dispatch(
        getProfileAction({
          callback: (res) => {
            dispatch(languagePreferredAction(res?.data[0]?.language_prefference));
            dispatch(setUserProfileDetailsAction(res?.data[0]));
            getLanguagePreferred(res?.data[0]?.language_prefference || "en");
            localStorage.setItem("language_preferred", res?.data[0]?.language_prefference || "en");
          },
        })
      );
    }
    if (!checkToken) {
      localStorage.getItem("language_preferred");
      dispatch(languagePreferredAction(localStorage.getItem("language_preferred")));
    }
  }, [checkToken]);

  useEffect(() => {
    setShowModal((prev) => !prev);
  }, [popCancle]);

  useEffect(() => {
    const eventHandler = (e) => {
      let headerSideNavbar = document.querySelector("#headerSideNavbar");
      if (headerSideNavbar && !headerSideNavbar?.contains(e.target)) {
        setShowMenu((preState) => !preState);
      }
      let preferredLanguageDrawer = document.querySelector("#preferredLanguageDrawer");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setShowLang((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div
      className={
        small
          ? `py-5 md:py-6 px-4 bg-black flex justify-between items-center w-full ${className}`
          : absolute
          ? `py-5 md:py-6 px-8 flex justify-between items-center w-full md:w-[calc(100%-6.5rem)] absolute z-50 ${className}`
          : `py-5 md:py-6 px-8 bg-black flex justify-between items-center w-full ${className}`
      }
    >
      <div className={small ? "flex gap-4 w-full" : "flex gap-8 md:w-[40%] "}>
        {small && <div className="bg-white w-8 h-8 rounded-md"></div>}
        {back && <img className="cursor-pointer" src={Back} onClick={onBack} alt="back" />}
        {tabValues?.map((tab, index) => {
          return (
            <p
              key={tab}
              className={`${
                active === index
                  ? `text-white font-semibold cursor-pointer ${
                      tabValues.length === 1 ? "" : "pb-3 border-b-[2px] border-yellow-500"
                    }`
                  : "text-white text-opacity-50 cursor-pointer"
              } text-xs md:text-base`}
              onClick={() => handleTabChange(index)}
            >
              {tab}
            </p>
          );
        })}
        {/* <p
          className={
            active === title1
              ? back || title1 === "My Favourites"
                ? "text-white cursor-pointer font-medium"
                : "text-white font-semibold pb-3 border-b-[2px] border-yellow-500 cursor-pointer"
              : "text-white text-opacity-50 cursor-pointer"
          }
          onClick={handleTab1}
        >
          {title1}
        </p>
        {title1 === "My Favourites" && <img src={Save} />}
        <p
          className={
            active === title2
              ? "text-white font-semibold pb-3 border-b-[2px] border-yellow-500 cursor-pointer"
              : "text-white text-opacity-50 cursor-pointer"
          }
          onClick={handleTab2}
        >
          {title2}
        </p> */}
      </div>
      <SearchBar
        small={small}
        placeholder={currentLanguageTitles?.[0]?.home_meta?.search_lbl ?? "Search"}
        name={"searchByCategory"}
        className="hidden md:block"
      />

      <div className="flex gap-8 w-[35%] justify-end">
        <div className="relative z-50">
          <img
            className="cursor-pointer"
            src={Language}
            alt=""
            onClick={() => {
              // if (checkToken) {
              setShowLang((preState) => !preState);
              // } else {
              //   setShowModal((prev) => !prev);
              // }

              setShowMenu(false);
            }}
          />
          {showLang && (
            <div
              className="absolute w-[250px] md:w-[400px] rounded-2xl bg-[#1F1F1F] right-0 top-16 shadow-2xl z-50"
              id="preferredLanguageDrawer"
            >
              <div className="p-6 flex justify-between">
                <p className="text-lg text-white font-medium">
                  {currentLanguageTitles?.[0]?.home_meta?.preferred_language_lbl ??
                    "Preferred Language"}
                </p>
                <img
                  className="cursor-pointer"
                  src={Cross}
                  onClick={() => setShowLang((preState) => !preState)}
                  alt="close"
                />
              </div>
              <ul className="px-2 py-4 flex flex-col cursor-pointer">
                {languageList.length > 0 &&
                  languageList?.map((item) => {
                    return (
                      <li
                        className="flex justify-between px-4 py-3 hover:bg-black rounded-md pb-3"
                        onClick={() => changeLanguageHandle(item.language_code)}
                      >
                        <p className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer">
                          {item.language_name}
                        </p>
                        <img
                          src={item.language_code === languagePreferred ? CheckTick : CheckBox}
                          alt="checkbox"
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
        <div className="relative z-50">
          <img
            className="cursor-pointer w-[37px] md:w-[50px] h-[37px] md:h-[50px] object-cover object-top overflow-hidden rounded-full"
            src={userProfileDetails?.profilePicUrl ?? Avatar}
            alt=""
            onClick={() => {
              setShowMenu((preState) => !preState);
              setShowLang(false);
            }}
          />

          {showMenu && (
            <div
              className={`absolute w-[300px] md:w-[380px] rounded-2xl bg-[#1F1F1F] right-0 top-16 shadow-xl p-6`}
              id="headerSideNavbar"
            >
              <div className="flex justify-between">
                <p className="text-xs text-yellow-400 font-medium">
                  {currentLanguageTitles?.[0]?.menus?.menu_lbl ?? "Menu"}
                </p>
                <img
                  className="cursor-pointer"
                  src={Cross}
                  onClick={() => setShowMenu((preState) => !preState)}
                  alt="close"
                />
              </div>
              <ul className="pt-6 flex flex-col gap-4">
                {checkToken && checkToken.length > 0 && (
                  <>
                    {" "}
                    <li
                      className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                      onClick={() => {
                        if (checkToken) {
                          navigate("/profile");
                          setShowMenu((preState) => !preState);
                        } else {
                          setShowModal(true);
                        }
                      }}
                    >
                      {currentLanguageTitles?.[0]?.menus?.my_profile_lbl ?? "My Profile"}
                    </li>
                    <li
                      className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                      onClick={() => (checkToken ? navigate("/favourites") : setShowModal(true))}
                    >
                      {currentLanguageTitles?.[0]?.menus?.my_favourites_lbl ?? "My Favourites"}
                    </li>
                    <li
                      className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                      onClick={() => navigate("/downloads")}
                    >
                      {currentLanguageTitles?.[0]?.menus?.my_downloads_lbl ?? "My Downloads"}
                    </li>
                    <li
                      className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                      onClick={() => navigate("/givings")}
                    >
                      {currentLanguageTitles?.[0]?.menus?.my_givings_lbl ?? "My Givings"}
                    </li>
                  </>
                )}
                <li
                  className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                  onClick={() => navigate("/privacy")}
                >
                  {currentLanguageTitles?.[0]?.menus?.privacy_policy_lbl ?? "Privacy Policy"}
                </li>
                <li
                  className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                  onClick={() => navigate("/support")}
                >
                  {currentLanguageTitles?.[0]?.menus?.help_n_support ?? "Help & Support"}
                </li>
                <li
                  className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                  onClick={() => navigate("/about")}
                >
                  {currentLanguageTitles?.[0]?.menus?.about_twg ?? "About TWG"}
                </li>
                {checkToken && checkToken.length > 0 ? (
                  <li
                    className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    {currentLanguageTitles?.[0]?.menus?.logout_lbl ?? "Logout"}
                  </li>
                ) : (
                  <li
                    className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                    onClick={() => navigate("/login")}
                  >
                    {currentLanguageTitles?.[0]?.menus?.login_lbl ?? "Login"}
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* {!checkToken && (
        <ReactModal
          style={customStyles}
          className="flex p-[5%] justify-center items-center h-[100vh] bg-black bg-opacity-50"
          isOpen={showModal}
        >
          <div className="w-1/2 h-1/2 bg-black rounded-lg flex flex-col justify-between items-center p-[5%]">
            <p className="text-yellowish text-xl text-center font-bold mb-6">
              Discover more with a login! Gain access to exclusive content and
              features by signing in.
            </p>
            <div className="w-full flex justify-between px-[2%]">
              {(popCancle || cancelBtn) ? (
                <button
                  onClick={() => {
                    if(cancelBtnHandle) {
                      cancelBtnHandle()
                    } else {
                      setShowModal(false)
                    }
                  }}
                  className="text-white px-8 py-4 border border-white rounded-lg font-semibold text-lg hover:bg-white hover:text-black"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={() => {
                    setShowModal(false);
                    navigate("/home");
                  }}
                  className="text-white px-8 py-4 border border-white rounded-lg font-semibold text-lg hover:bg-white hover:text-black"
                >
                  Back To Home
                </button>
              )}
              <button
                onClick={() => navigate("/login")}
                className="text-white px-8 py-4 border border-white rounded-lg font-semibold text-lg hover:bg-white hover:text-black"
              >
                Sign In
              </button>
            </div>
          </div>
        </ReactModal>
      )} */}
      <LoginHardBlocker enableGoToHome={enableGoToHome} enableBack={enableBack} />
    </div>
  );
};

export default HeaderBar;
