import { useEffect, useState } from "react";
import HeaderBar from "../../components/common/HeaderBar";
import Navbar from "../../components/common/Navbar";
import FooterBar from "../../components/common/FooterBar";
import CommonCarousel from "../../components/home/CommonCarousel";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { readUserFavAction, setLoginHardBlocker } from "../../store/Home/HomeAction";
import LoadingSlides from "../../components/common/LoadingSlides";

const MyFavourites = () => {
  const dispatch = useDispatch();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const { isAuthenticated } = useAuth0();

  console.log("currentLanguageTitles", currentLanguageTitles);

  const checkToken = localStorage.getItem("token");
  const initialTabs = ["My Favourites"];
  const initialSeeAll = "See All";
  const { isMobile } = useSelector((state) => state.common);
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);
  const [currentCode, setCurrectCode] = useState("");
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(0);
  const [favVideos, setFavVideos] = useState([]);
  const [favCurriculums, setFavCurriculums] = useState([]);
  const [favCategories, setFavCategories] = useState([]);
  const [favTestimonies, setFavTestimonies] = useState([]);

  const restructureVideoData = (data) => {
    return {
      _id: data?._id,
      image: data?.tumbnailurls[1]?.url_link,
      title: data?.video_title,
      type: "videos",
    };
  };

  const restructureCurriculumData = (data) => {
    return {
      _id: data?._id,
      image: data?.bg_Images[1]?.url_link,
      title: data?.curriculum_name,
      type: "curriculum",
    };
  };

  const restructureCategoryData = (data) => {
    return {
      _id: data?._id,
      image: data?.icon_images[1]?.url_link,
      title: data?.category_name,
      type: "category",
    };
  };

  const restructureTestimonyData = (data) => {
    return {
      _id: data?._id,
      image: data?.testomonial_rec_tumbnailUrl,
      title: data?.testimonial_name,
      type: "testimonial",
    };
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    setFavVideos([]);
    setFavCategories([]);
    setFavCurriculums([]);
    if (checkToken) {
      setLoading(true);
      dispatch(
        readUserFavAction({
          callback: (res) => {
            const data = res?.data[0]?.favourites;

            const favVideosList = [];
            const favCategoriesList = [];
            const favTestimoniesList = [];
            const favCurriculumsList = [];

            data.map((item) => {
              if (item?.videoDetails?.length > 0) {
                favVideosList.push(restructureVideoData(item?.videoDetails[0]));
              }
              if (item?.categoryDetails?.length > 0) {
                favCategoriesList.push(restructureCategoryData(item?.categoryDetails[0]));
              }
              if (item?.curriculumDetails?.length > 0) {
                favCurriculumsList.push(restructureCurriculumData(item?.curriculumDetails[0]));
              }
              if (item?.testimonyDetails?.length > 0) {
                favTestimoniesList.push(restructureTestimonyData(item?.testimonyDetails[0]));
              }
            });
            setFavVideos(favVideosList);
            setFavCategories(favCategoriesList);
            setFavCurriculums(favCurriculumsList);
            setFavTestimonies(favTestimoniesList);
            setLoading(false);
          },
        })
      );
    }
  }, [currentCode, checkToken]);

  useEffect(() => {
    if (!isAuthenticated && !checkToken) {
      dispatch(setLoginHardBlocker(true));
    }
  }, [isAuthenticated]);

  console.log("isAuthenticated", isAuthenticated);

  return (
    <div className="flex w-full md:flex-row flex-col overflow-hidden">
      <Navbar active={"My Favourites"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          enableGoToHome={true}
          tabValues={tabValues}
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={(code) => {
            setCurrectCode(code);
          }}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.my_favourites) {
              const requiredLangData = titles?.my_favourites;
              setTabsValues([titles?.menus?.my_fav_lbl || initialTabs]);

              setSeeAllBtn(requiredLangData.see_all_btn || initialSeeAll);
            } else {
              setTabsValues(initialTabs);
              setSeeAllBtn(initialSeeAll);
            }
          }}
        />
        <div className="flex flex-col pr-[1%] bg-black h-full overflow-y-auto px-6 w-full">
          {!loading && (
            <CommonCarousel
              title={currentLanguageTitles?.[0]?.my_favourites?.videos_lbl ?? "Videos"}
              linkTo={""}
              data={favVideos}
              imageType={"landscape"}
              seeAll={favVideos?.length > 0}
              seeAllValue={seeAllBtn}
              perViewImages={isMobile ? 2 : 5}
              dynamiclinkTo={true}
            />
          )}
          {!loading && favVideos?.length === 0 && (
            <p className="text-center text-white text-opacity-50">
              {currentLanguageTitles?.[0]?.my_favourites?.no_data_available_lbl ??
                "No Videos Available"}
            </p>
          )}
          {loading && <LoadingSlides className={"px-3 md:px-0"} />}
          {!loading && (
            <CommonCarousel
              title={currentLanguageTitles?.[0]?.my_favourites?.categories_lbl ?? "Categories"}
              linkTo={""}
              data={favCategories}
              imageType={"landscape"}
              seeAll={favCategories?.length > 0}
              seeAllValue={seeAllBtn}
              perViewImages={isMobile ? 2 : 5}
              dynamiclinkTo={true}
            />
          )}
          {!loading && favCategories?.length === 0 && (
            <p className="text-center text-white text-opacity-50">
              {" "}
              {currentLanguageTitles?.[0]?.my_favourites?.no_data_available_lbl ??
                "No Categories Available"}
            </p>
          )}
          {loading && <LoadingSlides className={"px-3 md:px-0"} />}
          {!loading && (
            <CommonCarousel
              title={currentLanguageTitles?.[0]?.my_favourites?.curriculum_lbl ?? "Curriculums"}
              linkTo={""}
              data={favCurriculums}
              perViewImages={isMobile ? 2 : 5}
              imageType={"landscape"}
              seeAll={favCurriculums?.length > 0}
              seeAllValue={seeAllBtn}
              dynamiclinkTo={true}
            />
          )}
          {!loading && favCurriculums?.length === 0 && (
            <p className="text-center text-white text-opacity-50">
              {" "}
              {currentLanguageTitles?.[0]?.my_favourites?.no_data_available_lbl ??
                "No Curriculums Available"}
            </p>
          )}
          {loading && <LoadingSlides className={"px-3 md:px-0"} />}
          {!loading && (
            <CommonCarousel
              title={currentLanguageTitles?.[0]?.my_favourites?.testimonies_lbl ?? "Testimonies"}
              linkTo={""}
              data={favTestimonies}
              perViewImages={isMobile ? 2 : 7}
              seeAll={favTestimonies?.length > 0}
              seeAllValue={seeAllBtn}
              dynamiclinkTo={true}
            />
          )}
          {!loading && favTestimonies?.length === 0 && (
            <p className="text-center text-white text-opacity-50">
              {" "}
              {currentLanguageTitles?.[0]?.my_favourites?.no_data_available_lbl ??
                "No Testimonies Available"}
            </p>
          )}
          {loading && <LoadingSlides className={"px-3 md:px-0"} />}
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default MyFavourites;
