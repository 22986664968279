import { useState } from "react";
import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
import FooterBar from "./FooterBar";
import { useSelector } from "react-redux";
import ImagesItem1 from "../../assets/images/Fav2/i1.png";
import ImagesItem2 from "../../assets/images/Fav2/i2.png";
import ImagesItem3 from "../../assets/images/Fav2/i3.png";
import ImagesItem4 from "../../assets/images/Fav2/i4.png";
import ImagesItem5 from "../../assets/images/luke/luke1.png";
import ImagesItem6 from "../../assets/images/luke/luke2.png";
import ImagesItem7 from "../../assets/images/luke/luke3.png";
import ImagesItem8 from "../../assets/images/luke/luke4.png";
import ReactModal from "react-modal";
import { BsX } from "react-icons/bs";

const Media = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const [tabValues, setTabsValues] = useState(["Media"]);

  const [mediaItems, setMediaItems] = useState([
    ImagesItem1,
    ImagesItem2,
    ImagesItem3,
    ImagesItem4,
    ImagesItem5,
    ImagesItem6,
    ImagesItem7,
    ImagesItem8,
  ]);
  const [activeItem, setActiveItem] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          back={true}
          tabValues={tabValues}
          active={0}
          getCurrentLanguageTitles={(titles) => {
            if (titles?.menus) {
              const requiredLangData = titles?.menus;
              setTabsValues([requiredLangData.media || ""]);
            } else {
              setTabsValues(["Media"]);
            }
          }}
        />

        <div className="flex flex-col pr-[1%] bg-black flex-1 overflow-y-auto w-full items-center justify-between">
          <div className="w-full px-12 md:px-10 py-4 text-white">
            <h1 className="text-white text-3xl font-semibold py-5 text-center">Media</h1>
            <div className="grid grid-cols-4 gap-3">
              {mediaItems?.length > 0 &&
                mediaItems?.map((media, index) => (
                  <img
                    src={media}
                    alt="list-item-1"
                    key={"media-item" + index}
                    className="aspect-video w-full object-cover object-top overflow-hidden rounded-lg cursor-pointer"
                    onClick={() => {
                      setActiveItem(media);
                      setPreviewOpen(true);
                    }}
                  />
                ))}
            </div>
          </div>
          <FooterBar />
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001, // Adjust this value as needed
          },
        }}
        className={
          "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
        }
        isOpen={previewOpen}
        onRequestClose={() => {
          setPreviewOpen(false);
        }}
      >
        <button
          className="absolute left-4 top-4"
          onClick={() => {
            setPreviewOpen(false);
          }}
        >
          <BsX size={40} color="white" />
        </button>
        <div className="h-[80vh] w-6/12 flex justify-center items-center cursor-pointer relative">
          <img
            src={activeItem}
            alt="Media Preview"
            className="w-full object-contain object-top overflow-hidden rounded-lg cursor-pointer"
          />
        </div>
      </ReactModal>
    </div>
  );
};

export default Media;
