import React from "react";
import ReactPlayer from "react-player";
import PlayControls from "./PlayControls";
import { setShowNavigationAlertAction } from "../../store/GlobalLoader/GlobalLoaderAction";
import { useDispatch } from "react-redux";
import Hls from "hls.js";

const VideoPlayer = ({
  url,
  playerContainerRef,
  playerRef,
  muted,
  playing,
  volume,
  handleProgress,
  title,
  onPlayPause,
  onRewind,
  onFastForward,
  onVolumeChange,
  onVolumeSeekDown,
  played,
  onToggleFullScreen,
  onSeek,
  elapsedTime,
  totalDuration,
  fullscreen,
  onVideoDownload,
  showDownload,
  controlsActive,
  setControlsActive,
  updateSlideProgress,
  onDuration,
  // isMouseMoving
}) => {
  const dispatch = useDispatch();

  const isHLS = url?.includes(".m3u8");

  return (
    <div ref={playerContainerRef} className="relative flex h-[100vh]">
      <ReactPlayer
        ref={playerRef}
        width={"100%"}
        height={"100%"}
        url={url}
        muted={muted}
        playing={playing}
        volume={volume}
        onPlay={() => {
          dispatch(setShowNavigationAlertAction(true));
        }}
        onProgress={handleProgress}
        onEnded={() => {
          updateSlideProgress(true);
          dispatch(setShowNavigationAlertAction(false));
        }}
        onDuration={onDuration}
        config={{
          file: {
            forceHLS: isHLS,
            hlsVersion: "latest",
            hls: (url) => {
              const hls = new Hls();
              hls.loadSource(url);
              return hls;
            },
          },
        }}
      />
      {fullscreen && (
        // isMouseMoving &&
        <PlayControls
          onVideoDownload={onVideoDownload}
          title={title}
          onPlayPause={onPlayPause}
          onRewind={onRewind}
          onFastForward={onFastForward}
          onVolumeChange={onVolumeChange}
          onVolumeSeekDown={onVolumeSeekDown}
          volume={volume}
          muted={muted}
          playing={playing}
          played={played}
          onToggleFullScreen={onToggleFullScreen}
          onSeek={onSeek}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
          showDownload={showDownload}
          controlsActive={controlsActive}
          setControlsActive={setControlsActive}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
