import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DateTimePicker from "react-datetime-picker";
import { useAuth0 } from "@auth0/auth0-react";

import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import ImageUpload from "../common/ImageUpload";
import TextInputField from "../common/TextInputField";
import DropdownField from "../common/DropDown";
import Profile from "./Profile";

import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { ProfileHooks } from "./hooks/ProfileHooks";
import ProfilePic from "../../assets/icons/Avatar.svg";

const MyProfile = () => {
  const {
    value,
    editProfile,
    setEditProfile,
    profileValues,
    handleChange,
    onChangeSearchBar,
    imageUrl,
    setImageUrl,
    handleUpdateProfile,
    setProfileValues,
  } = ProfileHooks();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("My Profile");
  const { isAuthenticated } = useAuth0();

  const handleTab1 = () => {
    setActiveTab("My Profile");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!isAuthenticated && !token) {
      dispatch(setLoginHardBlocker(true));
      return;
    }
  }, [isAuthenticated]);

  return (
    isAuthenticated && (
      <div className="flex flex-col md:flex-row w-full">
        <Navbar active={"Home"} />
        <div className="md:ml-[104px] flex flex-col w-full h-[100vh]">
          <HeaderBar
            enableGoToHome={true}
            title1={"My Profile"}
            back={true}
            active={activeTab}
            handleTab1={handleTab1}
            backLink={"givings"}
          />
          <div className="flex bg-black h-full overflow-y-auto justify-center">
            {!editProfile ? (
              <div className="px-4 md:p-6 bg-[#1F1F1F] w-[90vw] md:w-[50vw] h-[97%] mt-3 md:mt-0 rounded-3xl py-10 flex flex-col overflow-hidden">
                <div className="flex justify-between mb-3">
                  <p className="text-white text-2xl font-semibold">
                    {profileValues.firstName} {profileValues.lastName}
                  </p>
                  <button
                    className="py-3 px-6 rounded-xl text-sm font-semibold border border-yellow-400 text-yellow-400"
                    onClick={() => {
                      handleUpdateProfile();
                    }}
                  >
                    {editProfile ? " Update Profile" : "Confirm"}
                  </button>
                </div>
                <div className="flex-1 overflow-y-auto custom-scrollbar md:pr-2">
                  <div className="flex flex-col items-center justify-center p-4 gap-3">
                    <ImageUpload
                      placeholderImage={profileValues?.profilePicUrl ?? ProfilePic}
                      imageUrl={imageUrl}
                      setImageUrl={(value) => {
                        setImageUrl(value);
                        handleChange({ target: { name: "profilePicUrl", value: value } });
                      }}
                      isUpload={!editProfile}
                    />
                    <p className="text-xs text-white text-opacity-50"> Upload Picture</p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <TextInputField
                      name={"firstName"}
                      labelText={"First Name"}
                      placeholder={"First Name"}
                      value={profileValues.firstName}
                      handleInputChange={handleChange}
                      disabled={editProfile}
                    />
                    <TextInputField
                      name={"lastName"}
                      labelText={"Last Name"}
                      placeholder={"Last Name"}
                      value={profileValues.lastName}
                      handleInputChange={handleChange}
                      disabled={editProfile}
                    />
                    <DropdownField
                      name={"gender"}
                      placeholder={"Select role"}
                      labelText={"Gender"}
                      isDisabled={editProfile}
                      defaultValue={"male"}
                      options={[
                        {
                          label: "Male",
                          value: "male",
                        },
                        {
                          label: "Female",
                          value: "female",
                        },
                      ]}
                      handleInputChange={handleChange}
                      value={profileValues.gender}
                    />
                    <div>
                      <p className="text-[12px] font-semibold text-greyish mb-[10px] pt-1">
                        Date of Birth
                      </p>
                      <div>
                        <DateTimePicker
                          disabled={editProfile}
                          className={"w-full"}
                          value={profileValues.dob}
                          onChange={(value) => {
                            setProfileValues((prev) => ({
                              ...prev,
                              dob: value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <TextInputField
                        name={"aboutMe"}
                        labelText={"About me"}
                        placeholder={"About me"}
                        value={profileValues.aboutMe}
                        handleInputChange={handleChange}
                        disabled={editProfile}
                      />
                    </div>

                    <TextInputField
                      name={"mobile"}
                      labelText={"Phone Number"}
                      placeholder={"Phone Number"}
                      value={profileValues.mobile}
                      handleInputChange={handleChange}
                      disabled={editProfile}
                    />
                    <TextInputField
                      name={"email"}
                      labelText={"Email ID"}
                      placeholder={"Email ID"}
                      value={profileValues.email}
                      handleInputChange={handleChange}
                      disabled={true}
                    />
                    <TextInputField
                      name={"country"}
                      labelText={"Country"}
                      placeholder={"Country"}
                      value={profileValues.country}
                      handleInputChange={handleChange}
                      disabled={editProfile}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Profile setEditProfile={setEditProfile} profileValues={profileValues} />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default MyProfile;
