import { useState } from "react";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { CircularCheckbox } from "../../InputComps/CircularCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { savePrayerRequestAction } from "../../../store/Home/HomeAction";
import Toastiy from "../../common/Toastiy";

const NeedPrayerModal = ({ showModal, setShowModal }) => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const dispatch = useDispatch();

  const [prayerRequest, setPrayerRequest] = useState("");
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "subject") setSubject(value);
    if (name === "prayerRequest") setPrayerRequest(value);
    if (name === "category") setCategory(value);
  };

  const clearField = () => {
    setCategory("");
    setSubject("");
    setPrayerRequest("");
  };

  const closeModal = () => {
    setShowModal(false);
    clearField();
  };

  const handleSubmit = () => {
    //save form data
    const data = {
      subject: subject,
      prayer_request: prayerRequest,
      category: category,
    };

    dispatch(
      savePrayerRequestAction({
        apiPayloadRequest: data,
        callback: (res) => {
          Toastiy("Prayer Request sent!", 1);
          closeModal();
        },
      })
    );
  };

  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 1000, // Adjust this value as needed
        },
        content: {
          zIndex: 1001, // Adjust this value as needed
        },
      }}
      className={
        "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      }
      isOpen={showModal}
      onRequestClose={() => {
        closeModal();
      }}
    >
      <button
        className="absolute left-4 top-4 z-50"
        onClick={() => {
          closeModal();
        }}
      >
        <BsX size={40} color="white" />
      </button>
      <div className="h-[90vh] w-10/12 md:w-5/12 flex items-center justify-center cursor-pointer relative">
        <div className="py-10 px-8 md:px-12 bg-black w-full max-h-full h-fit object-cover overflow-y-auto rounded-xl flex flex-col">
          <div className="mb-6">
            <label className="text-yellowish text-xl font-semibold mb-3">
              {currentLanguageTitles?.[0]?.home_meta?.category_lbl ?? "Category"}
            </label>
            <div className="flex md:items-center flex-col md:flex-row gap-3 mt-3">
              <div className="flex items-center">
                <CircularCheckbox
                  value={currentLanguageTitles?.[0]?.home_meta?.healing_category_lbl ?? "Healing"}
                  name="category"
                  checked={
                    category ===
                    (currentLanguageTitles?.[0]?.home_meta?.healing_category_lbl ?? "Healing")
                  }
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">
                  {currentLanguageTitles?.[0]?.home_meta?.healing_category_lbl ?? "Healing"}
                </p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value={currentLanguageTitles?.[0]?.home_meta?.salvation_category_lbl ?? "Salvation"}
                  name="category"
                  checked={
                    category ===
                    (currentLanguageTitles?.[0]?.home_meta?.salvation_category_lbl ?? "Salvation")
                  }
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">
                  {currentLanguageTitles?.[0]?.home_meta?.salvation_category_lbl ?? "Salvation"}
                </p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value={currentLanguageTitles?.[0]?.home_meta?.general_category_lbl ?? "General"}
                  name="category"
                  checked={
                    category ===
                    (currentLanguageTitles?.[0]?.home_meta?.general_category_lbl ?? "General")
                  }
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">
                  {currentLanguageTitles?.[0]?.home_meta?.general_category_lbl ?? "General"}
                </p>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <input
              placeholder="Subject"
              rows="1"
              name="subject"
              className="border border-yellowish p-4 bg-transparent mt-4 rounded-xl text-white w-full focus-within:outline-none"
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-6">
            <div className="mb-6">
              <label className="text-yellowish text-xl font-semibold mb-6">
                {currentLanguageTitles?.[0]?.home_meta?.prayer_request_lbl ?? "Prayer Request:"}
              </label>
              <textarea
                name="prayerRequest"
                placeholder={currentLanguageTitles?.[0]?.home_meta?.type_request_placeholder_lbl ?? "Type your prayer request.."}
                rows="6"
                className="border border-yellowish p-4 bg-transparent mt-4 rounded-xl text-white w-full focus-within:outline-none"
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button
              className="bg-yellowish text-yellow-800 font-medium rounded-full flex justify-center w-5/12 py-3 mt-1"
              onClick={handleSubmit}
            >
              {currentLanguageTitles?.[0]?.home_meta?.prayer_submit_btn ?? "Submit"}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default NeedPrayerModal;
