import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectCoverflow, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { trimTextByCharacterLimit } from "../../utils/Utils";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";
import Hls from "hls.js";

const HomeCarousel = ({ data, title, height, width, allowHoverAnimation = true }) => {
  const navigate = useNavigate();
  const videoRef = useRef();
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const { isMobile } = useSelector((state) => state.common);

  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);

  const handleNavigate = (row) => {
    if (row?.type === "curriculum") {
      navigate(`/curriculum-details/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
    if (row?.type === "category") {
      navigate(`/categoryInfo/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
    if (row?.type === "videos") {
      navigate(`/videoInfo/${row._id}`, {
        state: {
          _id: row._id,
          fromLink: "/home",
        },
      });
    }
  };

  const handleSlideChange = (swiper) => {
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 600);
    }
  }, [showAnimation]);

  useEffect(() => {
    let timer = null;
    if (isHovered) {
      timer = setTimeout(() => {
        setVideoPlayed(true);
      }, 1500);
    }

    return () => {
      if (timer) clearTimeout(timer);
      setVideoPlayed(false);
    };
  }, [isHovered]);

  const handleMouseEnter = () => {
    console.log("handleMouseEnter");
    if (swiper) {
      console.log("handleMouseEnter swiper", swiper);
      swiper?.autoplay?.stop();
    }
    if (allowHoverAnimation) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    console.log("handleMouseLeave");
    if (swiper) {
      console.log("handleMouseLeave swiper", swiper);
      swiper?.autoplay?.start();
    }
    setIsHovered(false);
    setVideoPlayed(false);
  };

  return (
    <div className="px-6 relative">
      <p className="mb-6 text-lg font-semibold text-white">{title}</p>
      <div className="w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Swiper
          onInit={(ev) => setSwiper(ev)}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: width * 0.7905,
            depth: width * 0.11,
            modifier: 1,
            slideShadows: false,
          }}
          loop={allowHoverAnimation}
          autoplay={{
            delay: videoPlayed ? 15000 : 4000, // Set autoplay duration in milliseconds
            disableOnInteraction: true, // Disable autoplay when user interacts with the Swiper
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="flex justify-center"
          onSlideChange={handleSlideChange}
          breakpoints={{
            640: {
              coverflowEffect: {
                stretch: width * 0.76,
                depth: width * 0.11,
              },
            },
            1024: {
              coverflowEffect: {
                stretch: width * 0.7905,
                depth: width * 0.11,
              },
            },
          }}
        >
          {data?.map((row, index) => (
            <SwiperSlide key={index + row?._id}>
              <div className="flex justify-start pb-12">
                <div
                  className=" w-[86%] aspect-[4/5] md:aspect-[16/7] bg-cover bg-no-repeat rounded-2xl relative overflow-hidden  hover:scale-[101%] duration-500 bg-blackishV2"
                  style={
                    {
                      // backgroundImage: `url('${row?.image}')`,
                    }
                  }
                >
                  {row?.image && (
                    <img
                      src={row?.image}
                      alt=""
                      className="absolute min-w-full min-h-full object-cover object-top"
                      loading="eager"
                    />
                  )}
                  <div
                    className={`home-maincarousel-linear-gradient absolute w-full h-full ${
                      activeIndex === index ? "opacity-50" : "opacity-60"
                    } `}
                  ></div>
                  {activeIndex === index && (
                    <>
                      {row?.video && videoPlayed && (
                        <>
                          {/* <video
                          ref={videoRef}
                          src={row?.video}
                          className={`absolute top-0 left-0 w-full h-full aspect-video bg-blackishV2 object-cover object-top`}
                          autoPlay={true}
                          muted={videoMuted}
                        /> */}
                          <ReactPlayer
                            ref={videoRef}
                            url={row?.video}
                            className={`custom-player absolute top-0 left-0 w-full h-full aspect-video bg-blackishV2 object-cover object-top`}
                            width={"100%"}
                            height={"100%"}
                            playing={true}
                            muted={videoMuted}
                            config={{
                              file: {
                                forceHLS: row?.video?.includes(".m3u8"),
                                hlsVersion: "latest",
                                hls: (url) => {
                                  const hls = new Hls();
                                  hls.loadSource(url);
                                  return hls;
                                },
                              },
                            }}
                          />
                          <button
                            className={`bg-transparent border-none absolute text-xl text-white z-50 right-7 bottom-7`}
                            onClick={() => {
                              if (videoRef.current) {
                                setVideoMuted((prev) => !prev);
                              }
                            }}
                          >
                            {videoMuted ? <GoMute /> : <GoUnmute />}
                          </button>
                        </>
                      )}
                      <div
                        className={`${
                          showAnimation ? "btn-slide3" : ""
                        } absolute bottom-[10%] w-8/12 md:w-1/2 px-[5%] text-white`}
                      >
                        <p className="lg:text-4xl md:text-2xl md:mb-2 font-semibold lg:mb-4 text-wrap w-">
                          {row?.title}
                        </p>
                        <p className="lg:text-base text-xs lg:mb-6 mb-4">
                          {trimTextByCharacterLimit(row?.subtitle, isMobile ? 25 : 350)}
                        </p>
                        <button
                          onClick={() => handleNavigate(row)}
                          className="border border-yellowish hover:bg-yellowish hover:text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md text-yellowish font-semibold lg:text-base md:text-xs mb-1 hover:scale-105 transition-all duration-300"
                        >
                          {currentLanguageTitles?.[0]?.home_meta?.watchnow ?? "Watch Now"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="absolute top-[45%] z-10 opacity-20 hover:opacity-95 cursor-pointer"
          onClick={() => swiper.slidePrev()}
        >
          <FaCaretLeft size={50} color="white" />
        </div>
        <div
          className="absolute top-[45%] right-5 z-10 opacity-20 hover:opacity-95 cursor-pointer"
          onClick={() => swiper.slideNext()}
        >
          <FaCaretRight size={50} color="white" />
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
