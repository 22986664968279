import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import FooterBar from "../../components/common/FooterBar";

const HelpAndSupport = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  const [tabValues, setTabsValues] = useState(["Help and Support"]);
  const [activeAccordion, setActiveAccordion] = useState({
    panel_description: "",
    panel_title: "",
  });

  useEffect(() => {
    setActiveAccordion(currentLanguageTitles?.[0]?.help_n_supports?.[0]?.accordians[0]);
  }, [currentLanguageTitles?.[0]?.help_n_supports?.[0]?.accordians]);

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Curriculum"} />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          popCancle={true}
          back={true}
          tabValues={tabValues}
          active={0}
          getCurrentLanguageTitles={(titles) => {
            console.log("titles", titles);
            if (titles?.help_n_supports) {
              const requiredLangData = titles?.help_n_supports[0];
              setTabsValues([requiredLangData.help_n_support_lbl || ""]);
            } else {
              setTabsValues(["Help and Support"]);
            }
          }}
        />

        <div className="flex flex-col pr-[1%] bg-black flex-1 overflow-y-auto w-full justify-between">
          <div className="w-full px-6 md:px-10 py-4">
            <h1 className="text-white text-2xl font-semibold py-5">
              {activeAccordion?.panel_title}
            </h1>
            <div className="flex flex-col-reverse md:grid grid-cols-12 text-white gap-0 md:gap-14 w-full">
              <div className="col-span-12 md:col-span-8 text-white text-opacity-60">
                {activeAccordion?.panel_description}
              </div>
              <div className="col-span-12 md:col-span-4 mb-5">
                <div className="border border-white border-opacity-25 bg-blackish min-h-[50vh] rounded-xl">
                  <h2 className="py-5 px-6 text-lg font-semibold mb-1">
                    {currentLanguageTitles?.[0]?.help_n_supports[0]?.help_center_lbl ??
                      "Help Center"}
                  </h2>
                  <ul className="flex flex-col gap-3">
                    {currentLanguageTitles?.[0]?.help_n_supports?.[0]?.accordians?.map(
                      (accordion) => {
                        return (
                          <li
                            className={`${
                              accordion?.accorObjId === activeAccordion?.accorObjId
                                ? "text-yellowish font-semibold border-l-2 border-l-yellowish"
                                : "text-white text-opacity-60"
                            } px-6 py-0.5 text-sm cursor-pointer`}
                            onClick={() => setActiveAccordion(accordion)}
                          >
                            {accordion?.panel_title}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
