import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeaderBar from "./HeaderBar";
import Navbar from "./Navbar";
import FooterBar from "./FooterBar";
import { getCategoriesAction } from "../../store/Categories/CategoriesActions";

import Play from "../../assets/icons/play.svg";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import GotQuestions from "./GotQuestions";
import {
  savePickWhereLeftAction,
  setLoginHardBlocker,
  userFavAction,
  userUnFavAction,
} from "../../store/Home/HomeAction";
import Toastiy from "./Toastiy";
import CategoryVideoModelV2 from "../home/CategoryVideoModelV2";
import { Helmet } from "react-helmet-async";

const CategoryInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  console.log("location", location);

  const { id } = useParams();
  const { isAuthenticated } = useAuth0();
  const { languagePreferred } = useSelector((state) => state.languageReducer.data);

  const [showModal, setShowModal] = useState(false);
  const [showCategoryInfoModal, setShowCategoryInfoModal] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoDetails, setCurrentVideoDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("Book of Jonh");
  const [currentCategoryData, setCurrentCategoryData] = useState({
    thumbnailUrl: "",
    title: "",
    description: "",
    url: "",
    isLikedByCurrentUser: false,
  });

  const [relatedVideos, setRelatedVideos] = useState([]);

  console.log("location relatedVideos", relatedVideos);
  console.log("currentCategoryData", currentCategoryData);
  const handleTab1 = () => {
    setActiveTab("Book of Jonh");
  };

  const fetchCategoryDetails = () => {
    dispatch(
      getCategoriesAction({
        apiPayloadRequest: {
          _id: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setRelatedVideos([]);

            const data = res?.data[0];
            const relatedvideosData = data?.relatedvideos?.sort(
              (a, b) => Number(a?.order) - Number(b?.order)
            );
            const image =
              data?.icon_images?.filter((item) => item.type_of_url === "1")[0]?.url_link || "";

            setCurrentCategoryData((prev) => ({
              ...prev,
              id: data?._id || "",
              thumbnailUrl: image || "",
              title: data?.category_name || "",
              description: data?.category_description || "",
              url: image || "",
              isLikedByCurrentUser: data?.isLikedByCurrentUser ?? false,
              // url: data?.urls[0]?.url_link || '',
            }));

            const checkValidRelatedVideos = relatedvideosData?.find((v) => v?.videoObjId);

            const relatedVideosList = [];
            if (checkValidRelatedVideos) {
              relatedvideosData.forEach((item) => {
                const vidId = item?.videoObjId[0]?._id;
                const thumbnail =
                  item?.videoObjId[0]?.tumbnailurls?.find((item) => item.type_of_url === "1")
                    ?.url_link || "";
                const vidUrl =
                  item?.videoObjId[0]?.urls?.find((item) => item.type_of_url === "1")?.url_link ||
                  "";

                if (vidId) {
                  relatedVideosList.push({
                    _id: vidId,
                    video: vidUrl,
                    thumbnail: thumbnail,
                    title: item?.videoObjId[0]?.video_title,
                    description: item?.videoObjId[0]?.video_description,
                  });
                }
              });
              setRelatedVideos(relatedVideosList);
            }

            if (location.state?.videoObjId) {
              const findVideo = relatedVideosList?.find(
                (d) => d?._id === location.state?.videoObjId
              );
              const findVideoIndex = relatedVideosList?.findIndex(
                (d) => d?._id === location.state?.videoObjId
              );
              if (findVideo && findVideoIndex) {
                setCurrentVideoDetails(findVideo);
                setCurrentVideoIndex(findVideoIndex);
                setShowModal(true);
              }
            }
          }
        },
      })
    );
  };

  const saveUserProgress = ({ videoObjId, percentageWatched }) => {
    if (isAuthenticated) {
      dispatch(
        savePickWhereLeftAction({
          apiPayloadRequest: {
            type: "3", // 1-for video, 2- for curriculum, 3- for category
            categoryObjId: id,
            videoObjId: videoObjId,
            percentagewatched: percentageWatched,
          },
          callback: (res) => {
            console.log("progress saved");
            fetchCategoryDetails();
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchCategoryDetails();
  }, [id, languagePreferred]);

  const handleFavourite = (type = "3", item = null) => {
    if (isAuthenticated) {
      const payload = { type: type };
      if (type === "3") {
        payload["categoryObjId"] = item?.id;
      } else if (type === "1") {
        payload["videoObjId"] = item?._id;
      }
      console.log("payload", payload);
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                if (type === "3") {
                  setCurrentCategoryData((prev) => ({ ...prev, isLikedByCurrentUser: false }));
                } else if (type === "1") {
                  setCurrentVideoDetails((prev) => ({ ...prev, isLikedByCurrentUser: false }));
                }
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                if (type === "3") {
                  setCurrentCategoryData((prev) => ({ ...prev, isLikedByCurrentUser: true }));
                } else if (type === "1") {
                  setCurrentVideoDetails((prev) => ({ ...prev, isLikedByCurrentUser: true }));
                }
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          title1={currentCategoryData?.title ? currentCategoryData?.title : ""}
          back={true}
          active={activeTab}
          handleTab1={handleTab1}
        />
        {currentCategoryData && (
          <Helmet>
            <title>{currentCategoryData?.title}</title>
            <meta name="description" content={currentCategoryData?.description} />
            <meta property="og:title" content={currentCategoryData?.title} />
            <meta property="og:description" content={currentCategoryData?.description} />
            <meta property="og:image" content={currentCategoryData?.thumbnailUrl} />
          </Helmet>
        )}
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-10">
          <div className="mx-7 mt-6 h-[80vh]">
            <div
              className="w-full h-full rounded-2xl px-[4%] py-[3%] bg-no-repeat bg-cover overflow-hidden relative"
              style={{
                backgroundImage: `url('${currentCategoryData?.thumbnailUrl}')`,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  backgroundImage:
                    "linear-gradient(to top, rgba(0,0,0,0.9) 2%, rgba(0,0,0,0.5) 45%)",
                  height: "100%",
                  width: "100%",
                  zIndex: 2,
                }}
              ></div>
              <div className="w-full flex flex-col md:flex-row h-full justify-between">
                <div className="z-10 relative w-full md:w-6/12 flex flex-col justify-between">
                  <p className="mb-4 md:mb-0">
                    {currentCategoryData?.isLikedByCurrentUser ? (
                      <FaHeart
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={() => handleFavourite("3", currentCategoryData)}
                      />
                    ) : (
                      <FaRegHeart
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={() => handleFavourite("3", currentCategoryData)}
                      />
                    )}
                  </p>

                  <div className="flex flex-col">
                    <p className="text-white text-4xl font-semibold mb-5 ">
                      {currentCategoryData?.title}
                    </p>
                    <p className="text-white text-sm w-full mb-5 text-opacity-60">
                      {currentCategoryData?.description?.length <= 300 ? (
                        currentCategoryData?.description
                      ) : (
                        <>
                          {currentCategoryData?.description?.substring(0, 300)}{" "}
                          <span
                            onClick={() => {
                              setShowCategoryInfoModal(true);
                            }}
                            className="text-yellow-500 font-medium opacity-100 text-opacity-100 cursor-pointer"
                          >
                            More
                          </span>
                        </>
                      )}{" "}
                    </p>
                  </div>
                </div>
                {relatedVideos?.length > 0 && (
                  <ul className="w-full md:w-4/12 overflow-y-auto no-scrollbar grid gap-3 h-full z-10 border-yellowish/60 p-4 rounded-lg bg-blackishV2 border-[0.8px]">
                    {relatedVideos?.map((item, index) => {
                      return (
                        <li
                          className="flex flex-col relative rounded-lg overflow-hidden h-[28vh]"
                          key={item?._id}
                        >
                          <img
                            src={item?.thumbnail}
                            alt="video-item-1"
                            className="rounded-lg w-full h-full object-cover object-top"
                          />
                          <img
                            src={Play}
                            className="z-10 absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[15%] cursor-pointer"
                            alt="playBtn"
                            onClick={() => {
                              // if(!isAuthenticated){
                              //   dispatch(setLoginHardBlocker(true));
                              //   return;
                              // }
                              setCurrentVideoDetails(item);
                              setShowModal(true);
                              setCurrentVideoIndex(index);
                            }}
                          />
                          <div className="absolute p-3 bg-gradient-to-t from-black/90 from-[10%] to-[55%] h-full w-full">
                            <div className="flex flex-col h-full w-full justify-end">
                              <p className="text-white text-wrap text-xl font-semibold mb-1 text-start w-[95%]">
                                {item?.title}
                              </p>
                              <p className="text-white text-xs w-full text-opacity-60">
                                {item?.description?.length < 70
                                  ? item?.description
                                  : item?.description?.substring(0, 70) + ".."}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <CategoryVideoModelV2
            isOpen={showModal}
            onRequestClose={() => {
              setShowModal((prev) => !prev);
              setCurrentVideoDetails(null);
              setCurrentVideoIndex(null);
            }}
            data={currentVideoDetails}
            relatedVideos={relatedVideos}
            currentVideoIndex={currentVideoIndex}
            setCurrentVideoDetails={setCurrentVideoDetails}
            setCurrentVideoIndex={setCurrentVideoIndex}
            currentCategoryData={currentCategoryData}
            saveUserProgress={saveUserProgress}
            handleFavourite={handleFavourite}
          />
          <ReactModal
            style={{
              overlay: {
                zIndex: 1000, // Adjust this value as needed
              },
              content: {
                zIndex: 1001, // Adjust this value as needed
              },
            }}
            className={
              "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
            }
            isOpen={showCategoryInfoModal}
            onRequestClose={() => {
              setShowCategoryInfoModal(false);
            }}
          >
            <button
              className="absolute left-4 top-4"
              onClick={() => {
                setShowCategoryInfoModal(false);
              }}
            >
              <BsX size={40} color="white" />
            </button>
            <div className="h-[80vh] w-6/12 bg-black rounded-xl flex justify-center items-center cursor-pointer relative">
              <div className="absolute bottom-0 p-10 w-full h-full object-cover rounded-xl flex flex-col">
                <p className="text-yellowish text-4xl font-semibold mb-5 ">
                  {currentCategoryData?.title}
                </p>
                <p className="text-white text-lg w-full mb-5 text-opacity-60 flex-1 overflow-y-auto no-scrollbar">
                  {currentCategoryData?.description}
                </p>
              </div>
            </div>
          </ReactModal>
          <GotQuestions />
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default CategoryInfo;
