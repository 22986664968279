import React, { useEffect, useState } from "react";

const DropdownField = ({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateFunction,
  options,
  defaultValue = 0,
  width,
  isDisabled = false,
}) => {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);

  return (
    <div className="relative text-white flex flex-col">
      <label
        htmlFor={htmlFor}
        style={{
          color: `${
            isSubmitted && !validateFunction(value) && isFieldRequired
              ? "border-red-500"
              : labelColor
          }`,
        }}
        className="text-greyish text-[12px] font-semibold"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={(event) => handleInputChange(event)}
        placeholder={placeholder}
        disabled={isDisabled}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        defaultValue={defaultValue}
        onInput={(e) => {
          if (isSubmitted && !validateFunction(value) && isFieldRequired) {
            setIfInvalid(false);
          }
        }}
        className={` bg-blackish  p-2 py-[11px] ${width} text-white text-[12px] font-medium leading-4 mt-[14px] rounded-md ${
          (isSubmitted && !validateFunction(value) && isFieldRequired) ||
          ifInvalid
            ? "border-red-500"
            : ""
        }`}
      >
        {options.map((option) => (
          <option
            className="text-white"
            key={option.label}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      {isSubmitted && !validateFunction(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default DropdownField;
