import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "./Navbar";
import HeaderBar from "./HeaderBar";
import FooterBar from "./FooterBar";
import { FaPlay } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";
import { setHoveredActiveItem } from "../../store/Home/HomeAction";
import Hls from "hls.js";
import { MOBILE_DESCRIPTION_LENGTH } from "../../constants/CommonContants";

const SeeAll = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hoveredActiveItem } = useSelector((state) => state.languageReducer.data);
  const { isMobile } = useSelector((state) => state.common);

  const Data = location?.state?.data;
  const Title = location?.state?.title;
  const BackTo = location?.state?.Backto;
  const imageType = location?.state?.imageType ?? "landscape";
  const perViewImages = isMobile ? 1 : 5;
  const [activeTab, setActiveTab] = useState(BackTo);
  const [cardVisible, setCardVisible] = useState(false);

  const carouselId = useMemo(() => {
    return new Date().getTime() * Math.random();
  }, []);

  useEffect(() => {
    if (hoveredActiveItem) {
      setCardVisible(true);
    }

    return () => setCardVisible(false);
  }, [hoveredActiveItem]);

  const handleNavigate = (item, index) => {
    console.log("item", item);
    const itemType = item?.type;
    if (itemType === "video" || itemType === "videos") {
      navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "category") {
      navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "testimonial") {
      navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
    } else if (itemType === "curriculum") {
      navigate(`/curriculum-details/${item._id}`, { state: { _id: item?._id } });
    }
  };

  const handleTab1 = () => {
    setActiveTab(BackTo);
  };

  console.log("Data", Data);

  const CardDetails = ({ item, index, actualIndex, setActiveItem }) => {
    return (
      <div
        className="flex flex-col gap-3 cursor-pointer"
        onClick={() => handleNavigate(item?.type, item?._id, index)}
        onMouseEnter={(e) => {
          setActiveItem({
            ...item,
            activeIndex: index,
            offsetTop: e.currentTarget.offsetTop,
            carouselId: carouselId,
          });
        }}
      >
        <img
          src={item.image}
          className={`w-full rounded-lg object-cover object-top ${
            imageType === "landscape" ? "aspect-video" : "aspect-[12/16]"
          }`}
          alt="carousel thumbnail"
        />
        {item?.title && (
          <p className="text-white font-bold">
            {item?.title?.length < 20 ? item?.title : item?.title?.substring(0, 20) + ".."}
          </p>
        )}
        {item?.subtitle && (
          <p className="text-white text-opacity-50 text-sm">
            {item?.subtitle?.length < 100
              ? item?.subtitle
              : item?.subtitle?.substring(0, 100) + ".."}
          </p>
        )}
      </div>
    );
  };

  const HoverCard = () => {
    const videoRef = useRef();
    const [videoPlayed, setVideoPlayed] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);
    const videoUrl = hoveredActiveItem?.video ?? "";

    const isHLS = videoUrl?.includes(".m3u8");

    useEffect(() => {
      let timeout = null;
      if (cardVisible && videoUrl) {
        timeout = setTimeout(() => {
          setVideoPlayed(true);
          // videoRef.current.play();
        }, 2500);
      }

      return () => {
        clearTimeout(timeout);
        setVideoPlayed(false);
      };
    }, [cardVisible]);

    return (
      <div
        className={`absolute w-3/12 z-[100] flex-col transition-all duration-700 delay-[2000] animate-grow rounded-xl overflow-hidden bg-blackishV2 custom-drop-shadowV1 hidden ${
          cardVisible && hoveredActiveItem?.carouselId === carouselId ? "!block" : ""
        }`}
        onMouseLeave={() => {
          dispatch(setHoveredActiveItem(null));
          setCardVisible(false);
        }}
        style={
          (hoveredActiveItem?.activeIndex + 1) % perViewImages === 0
            ? {
                right: `${
                  (hoveredActiveItem?.activeIndex + 1) % perViewImages === 0 ? "2rem" : ""
                }`,
                top: `${hoveredActiveItem?.offsetTop}px`,
              }
            : {
                left: `${
                  (hoveredActiveItem?.activeIndex + 1) % perViewImages === 1
                    ? "1.5rem"
                    : ((hoveredActiveItem?.activeIndex % perViewImages) * 100) / perViewImages + "%"
                }`,
                top: `${hoveredActiveItem?.offsetTop}px`,
              }
        }
      >
        <div className={`w-full aspect-video relative`}>
          {!videoPlayed && (
            <img
              key={hoveredActiveItem?.id}
              src={hoveredActiveItem?.image}
              className={`aspect-video object-cover object-top z-10 relative`}
              alt={hoveredActiveItem?.title}
            />
          )}
          {/* <video
            className={`absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top ${
              videoPlayed ? "block" : "hidden"
            }`}
            ref={videoRef}
            src={videoUrl}
            muted
          /> */}
          <ReactPlayer
            ref={videoRef}
            url={videoUrl}
            className={`custom-player absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top`}
            width={"100%"}
            height={"100%"}
            muted={videoMuted}
            playing={videoPlayed}
            config={{
              file: {
                forceHLS: isHLS,
                hlsVersion: "latest",
                hls: (url) => {
                  const hls = new Hls();
                  hls.loadSource(url);
                  return hls;
                },
              },
            }}
          />
          <button
            className={`bg-transparent border-none absolute text-xl text-white z-50 right-4 bottom-4 ${
              videoPlayed ? "" : "hidden"
            }`}
            onClick={() => {
              if (videoRef.current) {
                setVideoMuted((prev) => !prev);
                // videoRef.current.muted = !videoRef?.current?.muted;
              }
            }}
          >
            {videoMuted ? <GoMute /> : <GoUnmute />}
          </button>
          <div className="absolute w-full h-[103%] top-0 left-0 bg-gradient-to-t from-blackishV2 z-20 to-black/0 from-5% to-20%"></div>
        </div>
        <div className="py-3 px-3.5">
          <button
            onClick={() => handleNavigate(hoveredActiveItem)}
            className="border-none bg-yellowish text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md font-semibold text-sm mb-4 w-full flex items-center justify-center gap-2 transition-all duration-700 hover:scale-[102%]"
          >
            {hoveredActiveItem?.type === "videos" && <FaPlay />}
            {hoveredActiveItem?.type === "videos" ? "Watch Now" : "View More"}
          </button>
          <div className="px-0.5">
            <p className="mt-2 text-white text-sm font-semibold overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
              {hoveredActiveItem?.title}
            </p>
            {hoveredActiveItem?.subtitle && (
              <p className="mt-2 text-white text-sm text-opacity-60 text-wrap mb-1">
                {isMobile
                  ? hoveredActiveItem?.subtitle?.substring(0, MOBILE_DESCRIPTION_LENGTH) + ".."
                  : hoveredActiveItem?.subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col md:flex-row overflow-hidden">
      <Navbar active={"Home"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          title1={BackTo}
          back={true}
          active={activeTab}
          tabValues={[BackTo]}
          handleTab1={handleTab1}
        />
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-5">
          {Title && <p className="px-6 text-white font-semibold text-xl">{Title}</p>}
          <div
            className="relative"
            onMouseLeave={() => {
              dispatch(setHoveredActiveItem(null));
              setCardVisible(false);
            }}
          >
            <HoverCard />
            <div
              className={`grid p-6 pt-0 gap-6`}
              style={{ gridTemplateColumns: `repeat(${perViewImages}, minmax(0, 1fr))` }}
            >
              {Data?.map((item, index) => (
                <CardDetails
                  key={item?._id}
                  item={item}
                  index={index}
                  actualIndex={index}
                  setActiveItem={(activeitem) => dispatch(setHoveredActiveItem(activeitem))}
                />
              ))}
            </div>
          </div>
          <FooterBar />
        </div>
      </div>
    </div>
  );
};

export default SeeAll;
