import { useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useParams } from "react-router-dom";
import Navbar from "../common/Navbar";
import HeaderBar from "../common/HeaderBar";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";
import { useDispatch, useSelector } from "react-redux";
import LessonAndSlideComp from "./LessonAndSlideComp";
import { FaDownload, FaHeart, FaRegHeart } from "react-icons/fa";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import award from "../../assets/images/certificate.png";
import certificateTemplate from "../../assets/images/certificate_template.jpg";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import { useAuth0 } from "@auth0/auth0-react";
import { setLoginHardBlocker, userFavAction, userUnFavAction } from "../../store/Home/HomeAction";
import Toastiy from "../common/Toastiy";
import CategoryVideoModel from "../home/CategoryVideoModel";
import { BsPlayCircleFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const CurriculumDetailsV2 = ({ fromLink = "" }) => {
  const { id } = useParams();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const { userProfileDetails } = useSelector((state) => state.languageReducer.data);
  const { languagePreferred, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );
  const { showNavigationAlert } = useSelector((state) => state.globalLoaderReducer);
  const [activeTab, setActiveTab] = useState("Curriculum");
  const [curriculumData, setCurriculumData] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [showLessonPopup, setShowLessonPopup] = useState(false);
  const [showTrailerModal, setShowTrailerModal] = useState(false);
  const [trailerData, setTrailerData] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [topPopShow, setTopPopShow] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => showNavigationAlert);

  console.log("blocker showNavigationAlert", showNavigationAlert);
  console.log("blocker", blocker);
  console.log("location", location);

  const handleTab1 = () => {
    setActiveTab("Curriculum");
  };

  const getCurriculumDetails = (updateProgress = false) => {
    dispatch(
      curriculumReadAction({
        apiPayloadRequest: { _id: id },
        callback: (res) => {
          if (res?.type === 1) {
            const curriculum = res?.data[0];
            if (updateProgress) {
              setCurriculumData((prev) => ({
                ...prev,
                curriculum_watch_percentage: curriculum?.curriculum_watch_percentage,
              }));
              if (
                curriculumData?.curriculum_watch_percentage !==
                  curriculum?.curriculum_watch_percentage &&
                Number(curriculum?.curriculum_watch_percentage) === 100
              ) {
                setShowModal(true);
              }
            } else {
              setCurriculumData(curriculum);
              if (location.state?.lessonObjId) {
                const findLesson = curriculum?.lessons?.find(
                  (d) => d?.lessonObjId === location.state?.lessonObjId
                );
                if (findLesson?.lessonObjId) {
                  setActiveItem(findLesson);
                  setTopPopShow((prev) => !prev);
                  setTimeout(() => {
                    const slideDetailsPopUp = document.getElementById(
                      findLesson?.slides?.[0]?.slideObjId
                    );
                    if (slideDetailsPopUp) slideDetailsPopUp.scrollIntoView({ block: "center" });
                  }, 1000);
                }
              }
            }
          }
        },
      })
    );
  };

  const getCertificateTemplate = (title, name) => {
    return (
      <div className="w-[792px] relative">
        <img src={certificateTemplate} alt="certificate template" className="w-full" />
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style={{ wordSpacing: "4px" }}
        >
          <p className="w-full text-center">{title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style={{ wordSpacing: "5px" }}
        >
          <p className="w-full text-center">{name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>
    );
  };

  const generatePDFDocument = (title = "") => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(
      ReactDOMServer.renderToStaticMarkup(
        getCertificateTemplate(
          title,
          userProfileDetails?.firstName ??
            (userProfileDetails?.firstName && userProfileDetails?.lastName)
            ? `${userProfileDetails?.firstName} ${userProfileDetails?.lastName}`
            : userProfileDetails?.twgId ?? ""
        )
      ),
      {
        callback: async function (doc) {
          doc.save("Certificate" + new Date().getTime());
        },
        margin: [0, 0, 0, 0],
        width: 792,
        windowWidth: 792,
      }
    );
  };

  useEffect(() => {
    getCurriculumDetails();
  }, [languagePreferred]);

  useEffect(() => {
    const updateHeight = () => {
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const handleFavourite = (item) => {
    if (isAuthenticated) {
      const payload = { type: "2", curriculumObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setCurriculumData((prev) => ({ ...prev, isLikedByCurrentUser: false }));
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setCurriculumData((prev) => ({ ...prev, isLikedByCurrentUser: true }));
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  const curriculumTrailer =
    curriculumData?.trailers?.find((i) => i?.type_of_url === "1")?.url_link ?? "";

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Curriculum"} blocker={blocker} />
      <div className="md:ml-[104px] flex flex-col w-full">
        <HeaderBar
          popCancle={true}
          back={true}
          backLink={fromLink ? fromLink : "/curriculum"}
          title1={activeTab}
          active={activeTab}
          handleTab1={handleTab1}
          absolute={true}
          enableBack={true}
        />
        <div className="flex flex-col bg-black overflow-y-auto w-full gap-10 min-h-[100vh] relative overflow-auto">
          <div
            className="fixed w-full h-full bg-no-repeat bg-center bg-cover blur-md ml-4"
            style={{
              backgroundImage: `url('${curriculumData?.bg_Images?.[1]?.url_link}')`,
            }}
          ></div>
          {curriculumData && (
            <div className="absolute top-0 w-full h-[20vh] bg-gradient-to-b from-black from-[25%] "></div>
          )}
          {curriculumData ? (
            <div className="w-full h-full bg-black bg-opacity-50 pt-[22%] md:pt-[13%] lg:pt-[8%] p-[5%] z-10 ">
              <div className="text-white text-3xl md:text-6xl font-bold mb-[1%] flex flex-col md:flex-row items-start md:items-center gap-8">
                {curriculumData?.curriculum_name}
                {isAuthenticated && (
                  <>
                    {curriculumData?.isLikedByCurrentUser ? (
                      <FaHeart
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={() => {
                          handleFavourite(curriculumData);
                        }}
                      />
                    ) : (
                      <FaRegHeart
                        className="text-3xl text-yellowish cursor-pointer"
                        onClick={() => {
                          handleFavourite(curriculumData);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <p className="text-xl text-white mb-[1%] flex items-center">
                {isAuthenticated && (
                  <div className="flex gap-2 items-center">
                    <p className=" text-[12px] text-white text-center text-nowrap">
                      {currentLanguageTitles?.[0]?.curricullum_meta?.completed_lbl ?? "Completed"}{" "}
                      {curriculumData?.curriculum_watch_percentage
                        ? parseInt(curriculumData?.curriculum_watch_percentage)
                        : 0}{" "}
                      %
                    </p>
                    <CountdownCircleTimer
                      isPlaying={false}
                      key={curriculumData?.curriculum_watch_percentage}
                      initialRemainingTime={curriculumData?.curriculum_watch_percentage ?? 0}
                      duration={100}
                      strokeWidth={3}
                      size={25}
                      colors={["#EFBC51", "#EFBC51", "#EFBC51", "#EFBC51"]}
                      colorsTime={[100, 5, 2, 0]}
                    >
                      {/* {({ remainingTime }) => (
                      <span className="text-yellowish text-[0.7rem]">{remainingTime}</span>
                    )} */}
                    </CountdownCircleTimer>
                  </div>
                )}
                <span
                  className={`${
                    isAuthenticated ? "px-5 md:px-36" : "pr-5 md:pr-36"
                  } opacity-70 text-nowrap text-sm md:text-base`}
                >
                  {curriculumData?.lessons?.length +
                    " " +
                    (currentLanguageTitles?.[0]?.curricullum_meta?.lesson_lbl ?? "Lesson")}
                </span>
                {curriculumData?.curriculumPdfUrl && (
                  <FaDownload
                    className="cursor-pointer opacity-70"
                    onClick={() => window.open(curriculumData?.curriculumPdfUrl, "_blank")}
                  />
                )}
                {curriculumTrailer && (
                  <span
                    className={`${
                      curriculumData?.curriculumPdfUrl ? "ps-5 md:ps-36" : ""
                    } opacity-70 text-nowrap text-sm md:text-base flex gap-3`}
                    onClick={() => {
                      setShowTrailerModal(true);
                      setTrailerData({
                        video: curriculumTrailer,
                        description: curriculumData?.curriculum_description,
                      });
                    }}
                  >
                    {currentLanguageTitles?.[0]?.curricullum_meta?.watch_preview_btn ??
                      "Watch Preview"}{" "}
                    <BsPlayCircleFill
                      color="#EFBC51"
                      className="h-auto cursor-pointer text-2xl"
                      data-tooltip-id="curriculum_trailer"
                      data-tooltip-place="right-start"
                      data-tooltip-content="Watch Preview"
                    />
                    <Tooltip
                      id="curriculum_trailer"
                      className="border border-greyish border-opacity-20"
                    />
                  </span>
                )}
              </p>
              <p className="opacity-50 text-white w-full md:w-[80%] lg:w-[50%] mt-[1%]">
                {curriculumData?.curriculum_description}
              </p>
              {curriculumData?.lessons?.map((lesson) => (
                <LessonAndSlideComp
                  lesson={lesson}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  setShowLessonPopup={setShowLessonPopup}
                  curriculumId={curriculumData?._id}
                  setCurriculumData={setCurriculumData}
                  getCurriculumDetails={getCurriculumDetails}
                  setShowTrailerModal={setShowTrailerModal}
                  setTrailerData={setTrailerData}
                  width={width}
                  topPopShow={topPopShow}
                  setTopPopShow={setTopPopShow}
                />
              ))}
            </div>
          ) : (
            <div className="w-full h-full pt-[8%] p-[5%] z-10">
              <div className="bg-zinc-600 rounded-xl w-[40%] h-[10vh] animate-pulse"></div>
              <div className="bg-zinc-600 rounded-xl w-[60%] h-[55vh] mt-[2%] animate-pulse"></div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001,
            padding: 0, // Adjust this value as needed
          },
        }}
        isOpen={showLessonPopup}
        className={"overflow-hidden"}
        onRequestClose={() => setShowLessonPopup(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <button className="absolute top-5 right-5" onClick={() => setShowLessonPopup(false)}>
            <MdCancel color="#EFBC51" size={30} />
          </button>
          <div className="bg-zinc-800 border-t-0 border-white border-opacity-30 border w-[85vw] min-h-[40vh] max-h-[85vh] overflow-auto custom-scrollbarV1 rounded-2xl p-6">
            <p className="text-white font-bold text-3xl">
              {activeItem?.lesson_title} -{" "}
              {currentLanguageTitles?.[0]?.curricullum_meta?.lesson_intro_lbl ?? "Lesson Intro"}
            </p>
            <p
              className="!text-white opacity-60 mt-4 pb-2 custom-content-description max-h-[45vh]"
              dangerouslySetInnerHTML={{ __html: activeItem?.lesson_description }}
            ></p>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        style={{
          overlay: {
            zIndex: 1000, // Adjust this value as needed
          },
          content: {
            zIndex: 1001,
            padding: 0, // Adjust this value as needed
          },
        }}
        isOpen={showModal}
        className={"overflow-hidden"}
        onRequestClose={() => setShowModal(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <button className="absolute top-5 right-5" onClick={() => setShowModal(false)}>
            <MdCancel color="#EFBC51" size={30} />
          </button>
          <div className="aspect-video rounded-2xl bg-black h-1/2 flex justify-center items-center flex-col">
            <div
              style={{ backgroundImage: `url('${award}')` }}
              className="w-1/2 h-1/2 bg-contain bg-no-repeat bg-center"
            ></div>
            <p className="text-xl text-yellowish">
              {currentLanguageTitles?.[0]?.curricullum_meta?.congratulations_lbl ??
                "Congratulations"}
            </p>
            <p className="text-2xl text-white">
              {curriculumData?.curriculum_name?.length < 20
                ? curriculumData?.curriculum_name
                : curriculumData?.curriculum_name?.substring(0, 20) + ".."}
            </p>
          </div>
          <button
            // onClick={generatePDF}
            onClick={() => generatePDFDocument(curriculumData?.curriculum_name)}
            className="bg-yellowish px-6 py-4 text-xl font-semibold rounded-xl"
          >
            {currentLanguageTitles?.[0]?.curricullum_meta?.download_certificate_lbl ??
              "Download Certificate"}
          </button>
        </div>
        <div
          className="flex flex-col justify-start items-center "
          ref={pdfContentRef}
          style={{
            width: "595.28px",
            height: "842px",
            color: "black",
            background: "white",
          }}
        >
          <p className="font-bold text-2xl mb-4">
            {currentLanguageTitles?.[0]?.curricullum_meta?.hello_lbl ?? "Hello"}{" "}
            {userProfileDetails?.firstName} {userProfileDetails?.lastName}
          </p>
          <p>
            {currentLanguageTitles?.[0]?.curricullum_meta?.congratulation_success_lbl ??
              "Congratulations on successfully completion of"}{" "}
            {curriculumData?.curriculum_name}{" "}
          </p>
        </div>
      </ReactModal>
      <CategoryVideoModel
        isOpen={showTrailerModal}
        onRequestClose={() => {
          setShowTrailerModal((prev) => !prev);
        }}
        data={trailerData}
      />
    </div>
  );
};

export default CurriculumDetailsV2;
