import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import TopSwiper from "../../components/home/TopSwiper";
import FooterBar from "../../components/common/FooterBar";
import SmallCarousel from "../../components/home/SmallCarousel";
import {
  ArrayOfImgs10,
  ArrayOfImgs2,
  ArrayOfImgs6,
  ArrayOfImgs7,
  ArrayOfImgs9,
} from "../../constants/NavbarConstants";
import TopSwiper2 from "../../components/testimonials/TopSwiper2";
import Record from "../../assets/icons/record.svg";
import TimePause from "../../assets/icons/timerpause.svg";
// import { useReactMediaRecorder } from "react-media-recorder";
// import VideoRecorder from "react-video-recorder";
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS,
} from "react-record-webcam";
import { useDispatch } from "react-redux";
import {
  testimonialApprovedReadAction,
  testimonialInReviewReadAction,
  testimonialLikedReadAction,
  testimonialReadAction,
  testimonialTrendingReadAction,
} from "../../store/Testimonial/TestimonialAction";
import Toastiy from "../../components/common/Toastiy";
import { useNavigate } from "react-router-dom";
import { uploadAssestsAction } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";
import WebcamComponent from "../../components/testimonials/WebcamComponent";
import TestimonialComp from "../../components/testimonials/TestimonialComp";

const Testimonials = () => {
  const [showRecording, setShowRecording] = useState(false);

  return (
    <>
      {showRecording ? (
        <WebcamComponent setShowRecording={setShowRecording} />
      ) : (
        <TestimonialComp setShowRecording={setShowRecording} />
      )}
    </>
  );
};

export default Testimonials;

// const RecordView = () => {
//     const {
//         status,
//         startRecording,
//         stopRecording,
//         mediaBlobUrl
//     } = useReactMediaRecorder({
//         video: true,
//         facingMode: { exact: "environment" }
//     });

//     return (
//         <div>
//             <p>{status}</p>
//             <button onClick={startRecording}>Start Recording</button>
//             <button onClick={stopRecording}>Stop Recording</button>
//             <video src={mediaBlobUrl} controls autoPlay loop />
//         </div>
//     );
// };
