import React, { useRef, useState } from "react";
import Navbar from "./Navbar";
// import HeaderBar from "./HeaderBar";
import Avatar from "../../assets/icons/Avatar.svg";
import SmallCarousel from "../home/SmallCarousel";
// import FooterBar from "./FooterBar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { testimonialReadAction } from "../../store/Testimonial/TestimonialAction";
// import GotQuestions from "./GotQuestions";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCaretDown, FaCaretUp, FaPlay } from "react-icons/fa";
import "swiper/css";
import { Keyboard, Mousewheel } from "swiper/modules";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom/dist";
import { FaHeart } from "react-icons/fa6";
import { useAuth0 } from "@auth0/auth0-react";
import { setLoginHardBlocker, userFavAction, userUnFavAction } from "../../store/Home/HomeAction";
import Toastiy from "./Toastiy";
import { getFormattedLikes } from "../../utils/Utils";

const TestimonialInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const { isMobile } = useSelector((state) => state.common);
  const { id } = useParams();
  const videoRef = useRef();

  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  const [activeTab, setActiveTab] = useState("Book of Jonh");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isDescriptionExpended, setIsDescriptionExpended] = useState(false);

  const [allTestimonials, setAllTestimonials] = useState([]);
  const [relatedVideos, setRelatedVideos] = useState([]);

  console.log("allTestimonials", allTestimonials);

  const handleTab1 = () => {
    setActiveTab("Book of Jonh");
  };

  useEffect(() => {
    dispatch(
      testimonialReadAction({
        // apiPayloadRequest: {
        //   _id: id,
        // },
        callback: (res) => {
          if (res?.type === 1) {
            setRelatedVideos([]);

            // const data = res?.data[0];
            // const relatedvideosData = data?.relatedvideos;
            const data = res?.data?.filter((item) => item.approval_status === 1);
            const testimonialPosition = data?.findIndex((d) => d?._id === id);
            if (testimonialPosition === -1) {
              Toastiy("Testimonial does not exists!!", 2);
              return;
            } else {
              const testimonialData = data[testimonialPosition];
              data.splice(testimonialPosition, 1);
              const updatedData = [testimonialData, ...data];
              setAllTestimonials(updatedData);
            }

            // const checkValidRelatedVideos = relatedvideosData?.find(v => v?.videoObjId);

            // if(checkValidRelatedVideos) {
            //     relatedvideosData.map((item) => {
            //         const vidId = item?.videoObjId[0]?._id;
            //         const thumbnail = item?.videoObjId[0]?.tumbnailurls ? item?.videoObjId[0]?.tumbnailurls[0]?.url_link : "";

            //         setRelatedVideos((prev) => ([
            //             ...prev,
            //             {
            //                 _id: vidId,
            //                 url: thumbnail,
            //             }
            //         ]))
            //     })
            // }
          }
        },
      })
    );
  }, [id]);

  const handleSlideChange = (swiper) => {
    console.log(swiper, "swiper");
    setShowAnimation(true);
    setActiveIndex(swiper.realIndex);
    setIsDescriptionExpended(false);
  };

  const handleBookmark = (item) => {
    if (isAuthenticated) {
      const payload = { type: "4", testimonyObjId: item?._id };
      if (item?.isLikedByCurrentUser) {
        dispatch(
          userUnFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Removed from Favourites!", res?.type);
                setAllTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: false } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      } else {
        dispatch(
          userFavAction({
            apiPayloadRequest: payload,
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Favourite Added Successfully!", res?.type);
                setAllTestimonials((prev) =>
                  prev?.map((d) =>
                    d?._id === item?._id ? { ...d, isLikedByCurrentUser: true } : d
                  )
                );
              } else {
                Toastiy("Something Went Wrong!", res?.type);
              }
            },
          })
        );
      }
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Home"} />
      <div className="md:ml-[104px] flex flex-col w-full">
        {/* <HeaderBar
          popCancle={true}
          // title1={currentVideoData?.title ? currentVideoData?.title : "Book of John"}
          back={true}
          className={isMobile ? "hidden":""}
          active={activeTab}
          handleTab1={handleTab1}
        /> */}
        <div className="flex flex-col bg-black h-full overflow-y-auto w-full gap-10 relative">
          <button
            className="absolute left-4 md:left-16 top-5 z-50"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BsArrowLeft size={30} color="white" />
          </button>
          <Swiper
            onInit={(ev) => setSwiper(ev)}
            grabCursor={true}
            direction="vertical"
            centeredSlides={true}
            slidesPerView={1}
            onSlideChange={handleSlideChange}
            keyboard={{
              enabled: true, // Enable keyboard control
              onlyInViewport: false, // Allow keyboard control outside of Swiper container
            }}
            freeMode={true}
            mousewheel={{ enabled: true, forceToAxis: true }}
            className="h-screen !pt-5 custom-swiper"
            modules={[Keyboard, Mousewheel]}
          >
            {allTestimonials?.map((row, index) => (
              <SwiperSlide key={index} className="h-full">
                <div className="flex justify-center h-[92%]">
                  <div
                    className="h-full aspect-[9/16] bg-blackish bg-cover bg-center bg-no-repeat rounded-2xl relative cursor-pointer overflow-hidden"
                    style={{
                      backgroundImage: `url('${
                        !isPlaying && !isVideoPlayed ? row?.testomonial_rec_tumbnailUrl : ""
                      }')`,
                    }}
                    onClick={() => {
                      setIsVideoPlayed(true);
                      if (isPlaying) {
                        videoRef.current.pause();
                      } else {
                        videoRef.current.play();
                      }
                      setIsPlaying(!isPlaying);
                    }}
                  >
                    {activeIndex === index && (
                      <>
                        <video
                          className={`h-full w-full object-contain rounded-xl ${
                            isVideoPlayed ? "block" : "hidden"
                          }`}
                          ref={videoRef}
                          loop={true}
                          src={row?.testomonial_rec_videoUrl}
                          onClick={() => videoRef.current.pause()}
                          onLoadedMetadata={(event) => {
                            if (event?.target) {
                              try {
                                event?.target
                                  ?.play()
                                  ?.then(() => {
                                    setIsVideoPlayed(true);
                                    if (!isPlaying) setIsPlaying(!isPlaying);
                                  })
                                  .catch((error) => console.log("Video Play Error", error));
                              } catch (error) {
                                console.log("Video Play Error", error);
                              }
                            }
                          }}
                        />
                        {!isPlaying && (
                          <>
                            <div
                              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[90%] z-10 opacity-20 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-5"
                              onClick={() => videoRef.current.play()}
                            >
                              <FaPlay size={20} color="white" className="translate-x-[10%]" />
                            </div>
                          </>
                        )}
                        <div
                          className={`${
                            showAnimation ? "btn-slide3" : ""
                          } absolute bottom-0 w-full py-3 bg-gradient-to-t from-black to-black/0 px-5`}
                        >
                          <div className="flex items-center gap-3 text-white font-medium">
                            <img
                              src={row?.user?.[0]?.profilePicUrl ?? Avatar}
                              alt="user avatar"
                              className="!h-[30px] !w-[30px] object-cover object-top rounded-full overflow-hidden"
                            />
                            <h4>{row?.user?.[0]?.firstName}</h4>
                          </div>
                          <p className="text-white text-sm font-bold drop-shadow-xl mb-1">
                            {row?.testimonial_name}
                          </p>
                          <p className="text-white text-xs drop-shadow-xl text-opacity-80">
                            {isDescriptionExpended ? (
                              row?.testimonial_description
                            ) : (
                              <>
                                {row?.testimonial_description?.substring(0, 150) + " .."}{" "}
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsDescriptionExpended(true);
                                  }}
                                  className="text-yellowish"
                                >
                                  more
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                      </>
                    )}
                    {isAuthenticated && (
                      <div className="absolute -right-10 translate-x-1/2 bottom-6 flex flex-col items-center justify-center gap-1">
                        <button
                          className={`flex items-center justify-center p-4 ${
                            row?.isLikedByCurrentUser ? "bg-white" : "bg-white/10"
                          } rounded-full`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookmark(row);
                          }}
                        >
                          <FaHeart
                            className={`text-xl ${
                              row?.isLikedByCurrentUser ? "text-black" : "text-white"
                            }`}
                          />
                        </button>
                        <span className="text-white text-sm">
                          {row?.totalikes ? getFormattedLikes(row?.totalikes) : "Favourite"}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="absolute top-6 right-6 md:right-10 z-10 opacity-60 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-2"
            onClick={() => {
              swiper.slidePrev();
            }}
          >
            <FaCaretUp size={isMobile ? 30 : 45} color="white" className="-translate-y-[10%]" />
          </div>
          <div
            className="absolute bottom-16 md:bottom-10 right-6 md:right-10 z-10 opacity-60 hover:opacity-95 cursor-pointer bg-blackishV2 rounded-full p-2"
            onClick={() => {
              swiper.slideNext();
            }}
          >
            <FaCaretDown size={isMobile ? 30 : 45} color="white" />
          </div>
          {relatedVideos?.length > 0 && (
            <div className="px-6">
              <SmallCarousel
                title={"Related Videos"}
                data={relatedVideos}
                seeAll={true}
                dataTitle={false}
                perViewImages={7}
                aspectRatio={"10/16"}
                height={"32"}
              />
            </div>
          )}
          {/* <GotQuestions />
          <FooterBar /> */}
        </div>
      </div>
    </div>
  );
};

export default TestimonialInfo;
