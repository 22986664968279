import Toastiy from "../components/common/Toastiy";

const downloadVideo = async (url) => {
  try {
    const response = await fetch(url);
    const videoData = await response.arrayBuffer();
    console.log("videoData", videoData);
    return videoData;
  } catch (error) {
    console.error("Error downloading video:", error);
    throw error;
  }
};

const fetchM3U8 = async (url) => {
  try {
    const response = await fetch(url);
    const m3u8Content = await response.text();
    return m3u8Content;
  } catch (error) {
    console.error("Error fetching M3U8:", error);
    throw error;
  }
};

const parseM3U8 = (m3u8Content) => {
  const lines = m3u8Content.split("\n");
  const videoUrls = [];

  lines.forEach((line) => {
    if (line.startsWith("#EXT-X-STREAM-INF")) {
      // This line contains the URL of the video playlist
      const url = lines[lines.indexOf(line) + 1];
      videoUrls.push(url);
    } else if (!line.startsWith("#")) {
      // This line contains a direct URL to a video segment
      videoUrls.push(line);
    }
  });

  return videoUrls;
};

const processM3U8 = async (m3u8Url) => {
  try {
    const m3u8Content = await fetchM3U8(m3u8Url);
    const videoUrls = parseM3U8(m3u8Content);
    console.log("Video URLs:", videoUrls);
    const videoData = await downloadVideo(videoUrls[0]);
    return videoData;
  } catch (error) {
    console.error("Error processing M3U8:", error);
  }
};

export const getDataFromIndexedDB = async (id, category) => {
  console.log("id, category", id, category);
  return new Promise((resolve, reject) => {
    try {
      const idb =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;

      if (!idb) {
        console.log("This browser doesn't support IndexedDB");
        return;
      }

      const request = idb.open("TWG", 1);

      console.log("request", request);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onupgradeneeded = function (event) {
        console.log("onupgradeneeded");
        const db = event.target.result;

        db.createObjectStore("videos", { keyPath: "_id", autoIncrement: false });
        db.createObjectStore("curriculum", { keyPath: "_id", autoIncrement: false });
      };

      request.onsuccess = function (event) {
        console.log("Database opened successfully");

        const db = event.target.result;

        console.log("db", db);

        var transaction = db.transaction([category], "readonly");

        var videos = transaction.objectStore(category);

        console.log("videos objectStore", videos);

        videos.get(id).onsuccess = (event) => {
          console.log("Video stored in IndexedDB:", event.target.result);
          resolve(event.target.result);
        };
      };
    } catch (error) {
      console.error("Error reading video in IndexedDB:", error);
      reject(error);
    }
  });
};

const storeVideoInIndexedDB = async (record) => {
  try {
    const idb =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;

    if (!idb) {
      console.log("This browser doesn't support IndexedDB");
      return;
    }

    const request = idb.open("TWG", 1);

    console.log("request", request);
    console.log("record", record);

    request.onerror = function (event) {
      console.error("An error occurred with IndexedDB");
      console.error(event);
    };

    request.onupgradeneeded = function (event) {
      console.log("onupgradeneeded");
      const db = event.target.result;

      db.createObjectStore("videos", { keyPath: "_id", autoIncrement: false });
      db.createObjectStore("curriculum", { keyPath: "_id", autoIncrement: false });
    };

    request.onsuccess = function (event) {
      console.log("Database opened successfully");

      const db = event.target.result;

      console.log("db", db);

      var transaction = db.transaction([record?.category], "readwrite");

      var videos = transaction.objectStore(record?.category);

      videos.add(record);
      console.log("Video stored in IndexedDB:", record?.url);

      return transaction.complete;
    };
  } catch (error) {
    console.error("Error storing video in IndexedDB:", error);
  }
};

export const processVideoDownload = async (record) => {
  console.log("processVideo runned");
  try {
    console.log("processVideo");
    const videoFormat = record?.url?.split(".")?.at(-1);
    let videoData;
    if (videoFormat === "m3u8") {
      videoData = await processM3U8(record?.url);
    } else {
      videoData = await downloadVideo(record?.url);
      Toastiy("Video Downloaded Successfully!", 1);
    }
    record["data"] = videoData;
    await storeVideoInIndexedDB(record);
  } catch (error) {
    console.error("Error processing video:", error);
  }
};
