import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import ReactModal from "react-modal";

import { readVideoAction } from "../../store/Home/HomeAction";

import { IoMdPlay } from "react-icons/io";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { BsArrowLeft, BsPlayCircleFill } from "react-icons/bs";
import Hls from "hls.js";

const CategoryVideoModelV2 = ({
  isOpen,
  onRequestClose,
  data,
  relatedVideos = [],
  currentVideoIndex = null,
  setCurrentVideoDetails = null,
  setCurrentVideoIndex = null,
  currentCategoryData = null,
  saveUserProgress = () => {},
  handleFavourite = () => {},
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector((state) => state.common);

  const customStyles = {
    overlay: {
      zIndex: 1000, // Adjust this value as needed
    },
    content: {
      zIndex: 1001, // Adjust this value as needed
    },
  };

  const isHLS = data?.video?.includes(".m3u8");

  console.log("videomodal data", data);
  console.log("videomodal currentVideoIndex", currentVideoIndex);
  console.log("videomodal relatedVideos", relatedVideos);

  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoFinished, setVideoFinished] = useState(false);
  const [containerFocus, setContainerFocus] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [playedVideoProgress, setPlayedVideoProgress] = useState(0);
  const [videoDetailsLoading, setVideoDetailsLoading] = useState(false);

  const togglePlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (playing) {
      // videoRef.current.pause();
      setPlaying((prev) => !prev);
      setVideoFinished(false);
    } else {
      // videoRef.current.play();
      setPlaying((prev) => !prev);
      setVideoFinished(false);
      setVideoPlayed(true);
    }
  };

  const handleLoadedData = () => {
    setLoading(false);
    setPlayedVideoProgress(0);
  };

  useEffect(() => {
    if (data?._id) {
      setVideoDetailsLoading(true);
      dispatch(
        readVideoAction({
          apiPayloadRequest: {
            _id: data?._id,
          },
          callback: (res) => {
            console.log("progress saved");
            setCurrentVideoDetails((prev) => ({
              ...prev,
              isLikedByCurrentUser: res?.data?.[0]?.isLikedByCurrentUser ?? false,
            }));
            setVideoDetailsLoading(false);
          },
        })
      );
    }
  }, [data?._id]);

  return (
    <ReactModal
      style={customStyles}
      className={
        "flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <button
        className="absolute left-4 top-6 z-50"
        onClick={() => {
          onRequestClose();
          setPlaying(false);
          saveUserProgress({
            videoObjId: data?._id,
            percentageWatched: playedVideoProgress,
          });
        }}
      >
        <BsArrowLeft size={30} color="white" />
      </button>
      <div className="w-screen h-screen overflow-y-auto flex flex-col md:flex-row gap-6 bg-[#10100F] px-8 md:px-16 pt-24 pb-10 md:py-8">
        <div className="w-full md:w-9/12 md:max-h-full md:overflow-y-auto no-scrollbar flex flex-col gap-5">
          <div
            className="w-full aspect-video bg-black flex justify-center items-center cursor-pointer relative"
            onClick={togglePlay}
            onMouseOver={() => setContainerFocus(true)}
            onTouchStart={() => setContainerFocus(false)}
            onMouseOut={() => setContainerFocus(false)}
            onTouchEnd={(e) => {
              togglePlay(e);
            }}
          >
            {!loading && !playing && data?.thumbnail && !videoPlayed && (
              <div
                className="absolute w-full h-full bg-cover text-white"
                style={{ backgroundImage: `url(${data?.thumbnail})` }}
              ></div>
            )}
            <div
              className={`absolute top-0 left-0 w-full h-full bg-black/40 rounded-xl ${
                containerFocus || !playing ? "flex" : "hidden"
              }`}
            ></div>
            <button
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4 md:-translate-y-1/2 drop-shadow-xl z-50"
              onClick={(e) => {
                togglePlay(e);
                setContainerFocus(false);
              }}
            >
              {!loading && !playing && (
                <BsPlayCircleFill className="text-[50px] md:text-[100px] opacity-80 text-yellowish" />
              )}
            </button>

            <button
              className={`absolute right-4 z-50 bottom-16 bg-yellowish text-blackish transition-all duration-700 drop-shadow-xl font-medium text-sm md:text-base px-4 md:px-6 py-0.5 md:py-1 ${
                currentVideoIndex < relatedVideos?.length - 1 &&
                !playing &&
                playedVideoProgress < 95
                  ? "flex"
                  : "hidden"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentVideoIndex(currentVideoIndex + 1);
              }}
            >
              Next
            </button>
            <div
              className={`absolute right-4 bottom-16 transition-all duration-700 drop-shadow-xl z-50 w-4/12 md:w-3/12 aspect-video bg-cover bg-top ${
                currentVideoIndex < relatedVideos?.length - 1 && playedVideoProgress > 95
                  ? "flex"
                  : "hidden"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentVideoIndex(currentVideoIndex + 1);
              }}
              style={{ backgroundImage: `url(${relatedVideos[currentVideoIndex + 1]?.thumbnail})` }}
            >
              <button className="absolute top-0 md:top-2 right-0 md:right-2 bg-yellowish text-blackish font-medium text-[0.5rem] md:text-xs px-3 py-0.5">
                Upcoming
              </button>
              <p className="bg-gradient-to-t from-black/90 from-[10%] to-[90%] px-2 py-2 text-wrap text-white text-[0.6rem] md:text-xs absolute bottom-0 right-0 w-full">
                {isMobile
                  ? relatedVideos[currentVideoIndex + 1]?.title?.substring(0, 15)
                  : relatedVideos[currentVideoIndex + 1]?.title}
              </p>
            </div>

            {((!videoDetailsLoading && containerFocus) || !playing) && (
              <div className="absolute top-4 right-4 z-50">
                {data?.isLikedByCurrentUser ? (
                  <FaHeart
                    className="text-2xl md:text-3xl text-yellowish cursor-pointer z-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavourite("1", data);
                    }}
                  />
                ) : (
                  <FaRegHeart
                    className="text-2xl md:text-3xl text-yellowish cursor-pointer z-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavourite("1", data);
                    }}
                  />
                )}
              </div>
            )}
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
                <div className="text-white">Loading...</div>
              </div>
            )}

            <ReactPlayer
              ref={videoRef}
              key={data?.video}
              controls={true}
              className="!h-full !w-full custom-player aspect-video object-contain rounded-xl"
              url={data?.video}
              onReady={handleLoadedData}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              playing={playing}
              onProgress={(time) => setPlayedVideoProgress(time?.played * 100)}
              onEnded={() => {
                setVideoFinished(true);
                setPlaying(false);
                saveUserProgress({
                  videoObjId: data?._id,
                  percentageWatched: 100,
                });
                if (document?.fullscreenElement !== null && document?.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document?.webkitIsFullScreen && document?.webkitExitFullscreen) {
                  document.webkitExitFullscreen();
                } else if (
                  document?.mozFullScreenElement !== null &&
                  document?.mozCancelFullScreen
                ) {
                  document.mozCancelFullScreen();
                }
              }}
              config={{
                file: {
                  forceHLS: isHLS,
                  hlsVersion: "latest",
                  hls: (url) => {
                    const hls = new Hls();
                    hls.loadSource(url);
                    return hls;
                  },
                },
              }}
            />
          </div>
          <h2 className="text-2xl text-white font-medium">{data?.title}</h2>
          <div className="bg-[#272727] text-white text-base text-[0.9rem] rounded-lg px-3 py-3">
            <p>
              {data?.description?.length < 135 || viewMore ? (
                data?.description
              ) : (
                <>
                  {data?.description?.substring(0, 135) + ".."}{" "}
                  <strong className="cursor-pointer" onClick={() => setViewMore(true)}>
                    more
                  </strong>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="flex w-full md:w-3/12 h-full z-10 border-yellowish/60 rounded-lg bg-blackishV2 overflow-hidden border-[0.8px] flex-col transition-all duration-1000">
          <div
            className="bg-[#272727] text-white text-xl md:text-2xl font-medium px-4 py-3 md:py-4 bg-cover bg-top bg-opacity-100"
            style={{ background: currentCategoryData?.thumbnailUrl ?? "" }}
          >
            {currentCategoryData?.title}
          </div>
          <ul className="flex flex-1 flex-col overflow-y-auto">
            {relatedVideos?.map((item, index) => {
              return (
                <li
                  className={`flex flex-col relative pl-1 pr-3 py-3 cursor-pointer ${
                    index === currentVideoIndex ? "bg-yellowish/15" : "bg-blackish"
                  }`}
                  key={item?._id}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (setCurrentVideoDetails) {
                      saveUserProgress({
                        videoObjId: data?._id,
                        percentageWatched: playedVideoProgress,
                      });
                      setCurrentVideoDetails(relatedVideos[index]);
                    }
                    if (setCurrentVideoIndex) {
                      setCurrentVideoIndex(index);
                    }
                    setVideoFinished(false);
                    setViewMore(false);
                    setVideoPlayed(false);
                    setTimeout(() => {
                      setPlaying(true);
                    }, 600);
                  }}
                >
                  <div className="flex items-center gap-1">
                    <span className="text-white w-4 text-center">
                      {index === currentVideoIndex ? <IoMdPlay className="text-sm" /> : index + 1}
                    </span>
                    <div className="flex-1 relative flex flex-col overflow-hidden">
                      <img
                        src={item?.thumbnail}
                        alt="video-item-1"
                        className="rounded-lg w-full h-full object-cover object-top aspect-[16/11]"
                      />
                      <div className="absolute p-3 bg-gradient-to-t bottom-0 rounded-b-lg from-black from-[2%] to-[25%] h-full w-full">
                        <div className="flex flex-col h-full w-full justify-end">
                          <p className="text-white text-xl font-semibold mb-1 text-start text-wrap w-[95%]">
                            {item?.title}
                          </p>
                          <p className="text-white text-xs w-full text-opacity-60">
                            {item?.description?.length < 50
                              ? item?.description
                              : item?.description?.substring(0, 50) + ".."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </ReactModal>
  );
};

export default CategoryVideoModelV2;
