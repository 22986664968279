import { useState } from "react";
import NeedPrayerModal from "./NeedPrayerModal";
import { useSelector } from "react-redux";

const HomeNeedPrayer = () => {
  const [showModal, setShowModal] = useState(false);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  return (
    <>
      <div className="">
        <p className="text-lg font-semibold text-white">
          {" "}
          {currentLanguageTitles?.[0]?.home_meta?.need_prayer_lbl ?? "Need Prayer?"}
        </p>
        <div className="need-prayer-bg my-5 rounded-2xl flex justify-center items-center h-[30vh] md:h-[50vh] w-full">
          <button
            className="bg-black opacity-70 font-semibold text-yellow-500 text-opacity-60 py-5 px-10 md:px-20 rounded-xl uppercase text-2xl"
            onClick={() => setShowModal(true)}
          >
            {currentLanguageTitles?.[0]?.home_meta?.need_prayer_lbl ?? "Need Prayer?"}
          </button>
        </div>
      </div>
      <NeedPrayerModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default HomeNeedPrayer;
